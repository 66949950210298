import { Button, Drawer, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import replaceImageUrl from '../helpers/replaceImageUrl';
import { signout } from '../redux/actions';
import Header from './Header';
import Sidebar, { useStyles } from './Sidebar';

const Layout = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(signout());
  };

  const renderLoggedInLinks = () => {
    return (
      <Button color='inherit' onClick={logout}>
        Sign Out
      </Button>
    );
  };

  const renderNonLoggedInLinks = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Button color='inherit' component={Link} to='/signin'>
          Sign In
        </Button>
        {/* <Button color="inherit" component={Link} to='/signup'>Sign Up</Button> */}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {props.sidebar ? (
        <>
          {/* Drawer for small devices */}
          <Hidden smUp implementation='css'>
            <Drawer open={open} onClose={() => setOpen(false)}>
              <Sidebar />
            </Drawer>
          </Hidden>
          {/* Drawer for large devices */}
          <Hidden xsDown implementation='css'>
            <Drawer open={open} variant='permanent' onClose={() => setOpen(false)}>
              <Sidebar />
            </Drawer>
          </Hidden>
        </>
      ) : (
        <>
          <Hidden smUp implementation='css'>
            <Menu open={open} onClose={() => setOpen(false)} style={{ top: '-230px' }}>
              {auth.authenticate ? (
                <MenuItem>{renderLoggedInLinks()}</MenuItem>
              ) : (
                <MenuItem>{renderNonLoggedInLinks()}</MenuItem>
              )}
            </Menu>
          </Hidden>
          <Hidden xsDown implementation='css'>
            <Drawer open={open} variant='permanent' onClose={() => setOpen(false)}>
              <img
                src={replaceImageUrl(process.env.REACT_APP_MODEL_IMAGE)}
                alt='Model Missing'
                height='600px'
                width='260px'
                style={{ marginTop: '100px' }}
              />
            </Drawer>
          </Hidden>
        </>
      )}

      <Header className={classes.appBar}>
        <IconButton
          edge='start'
          className={classes.menuButton}
          color='inherit'
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Header>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
      <Toaster
        toastOptions={{
          position: 'top-center',
          success: {
            style: {
              background: '#2da341',
              color: '#ffffff',
            },
          },
          error: {
            style: {
              background: '#ac1d1f',
              color: '#ffffff',
            },
          },
        }}
        autoClose={2000}
      />
    </div>
  );
};

export default Layout;
