import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  // textField: {
  //   width: "50ch",
  // },
}));

const CustomInput = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth={props.fullWidth ? true : false}
      className={clsx(classes.margin, classes.textField)}
      variant='outlined'
      {...props}
    >
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <OutlinedInput
        id={props.id}
        type={props.showInputValue ? 'text' : 'password'}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        endAdornment={
          props.eyeIcon === true ? (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={props.handleClickShowInputValue}
                onMouseDown={props.handleMouseDownInputValue}
                edge='end'
              >
                {props.showInputValue ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        labelWidth={props.placeholder.toString().length * 9}
      />
    </FormControl>
  );
};

export default CustomInput;
