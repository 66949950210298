import { Button, ButtonGroup, Grid, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import Layout from '../../../layouts/Layout';
import { addBuyerSeller } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import UpdateBuyerSellerDialogbox from './UpdateBuyerSellerDialogbox';

const BuyerSellerMaster = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const buyerSeller = useSelector((state) => state.buyerSeller);

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [gstNum, setGstNum] = useState('');
  const [bank, setBank] = useState('');
  const [bankAccountNum, setBankAccountNum] = useState('');
  const [ifsc, setIfsc] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [address, setAddress] = useState('');

  const handleBuyerSeller = () => {
    if (name === '') return toast.error('Party name is required !');
    if (mobile === '') return toast.error('Mobile number is required !');
    if (address === '') return toast.error('Address is required !');
    const payload = {
      name: name,
      mobile: mobile,
      email: email,
      address: address,
      gstNum: gstNum,
      bank: bank,
      bankAccountNum: bankAccountNum,
      bankBranch: bankBranch,
      ifsc: ifsc,
    };
    dispatch(addBuyerSeller(payload));
    setName('');
    setMobile('');
    setEmail('');
    setAddress('');
    setGstNum('');
    setBank('');
    setBankBranch('');
    setIfsc('');
    setBankAccountNum('');
  };

  const columns = [
    { field: 'id', width: 100 },
    {
      field: 'Edit',
      renderCell: (params) => (
        <ButtonGroup>
          <Button
            style={{ backgroundColor: mainColour2 }}
            size='small'
            onClick={() => {
              showUpdateBuyerSellerDialog(params.row.item);
            }}
          >
            <EditIcon style={{ color: 'white' }} />
          </Button>
        </ButtonGroup>
      ),
      width: 120,
      align: 'center',
    },
    { field: 'Party_Name', width: 200, align: 'center' },
    { field: 'Mobile', width: 200, align: 'center' },
    { field: 'Email', width: 175, align: 'center' },
    { field: 'GST_Num', width: 175, align: 'center' },
    { field: 'Bank', width: 175, align: 'center' },
    { field: 'Bank_Account_Num', width: 200, align: 'center' },
    { field: 'Bank_Branch', width: 200, align: 'center' },
    { field: 'IFSC_Code', width: 200, align: 'center' },
    { field: 'Address', width: 400, align: 'center' },
  ];

  let rowss = [];
  buyerSeller.buyerSellers.map((item, idx) => {
    return rowss.push({
      id: idx + 1,
      item,
      Party_Name: item.name,
      Mobile: item.mobile,
      Email: item.email,
      GST_Num: item.gstNum,
      Bank: item.bank,
      Bank_Account_Num: item.bankAccountNum,
      Bank_Branch: item.bankBranch,
      IFSC_Code: item.ifsc,
      Address: item.address,
    });
  });

  const [party, setParty] = useState(null);
  const [updateBuyerSellerDialog, setUpdateBuyerSellerDialog] = useState(false);

  const showUpdateBuyerSellerDialog = (item) => {
    setUpdateBuyerSellerDialog(true);
    const partyToBeUpdated = buyerSeller.buyerSellers.find(
      (buyerSeller) => buyerSeller._id === item._id,
    );
    setParty(partyToBeUpdated);
  };

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            PARTY MASTER
          </Typography>
        </Grid>
        {/* <Grid item>
                    <Typography variant="h4" className={classes.heading}>BASE DATE</Typography>
                </Grid> */}
      </Grid>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <TextField
            required
            variant='outlined'
            value={name}
            label='Party Name'
            placeholder='Party Name'
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            required
            variant='outlined'
            value={mobile}
            label='Mobile'
            placeholder='Mobile'
            onChange={(e) => setMobile(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant='outlined'
            value={email}
            label='Email'
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            variant='outlined'
            value={address}
            label='Address'
            placeholder='Address'
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <TextField
            variant='outlined'
            value={gstNum}
            label='GST No.'
            placeholder='GST No.'
            onChange={(e) => setGstNum(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant='outlined'
            value={bank}
            label='Bank'
            placeholder='Bank'
            onChange={(e) => setBank(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant='outlined'
            value={bankAccountNum}
            label='Account No.'
            placeholder='Account No.'
            onChange={(e) => setBankAccountNum(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant='outlined'
            value={ifsc}
            label='IFSC Code'
            placeholder='IFSC Code'
            onChange={(e) => setIfsc(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant='outlined'
            value={bankBranch}
            label='Branch'
            placeholder='Branch'
            onChange={(e) => setBankBranch(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            fullWidth
            onClick={handleBuyerSeller}
            variant='contained'
            className={classes.externalLinks}
            style={{ height: '56px' }}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {!buyerSeller.loading ? (
            <MyTable rows={rowss} columns={columns} rowHeight={60} />
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>
      {updateBuyerSellerDialog && (
        <UpdateBuyerSellerDialogbox
          party={party}
          updateBuyerSellerDialog={updateBuyerSellerDialog}
          setUpdateBuyerSellerDialog={setUpdateBuyerSellerDialog}
        />
      )}
    </Layout>
  );
};

export default BuyerSellerMaster;
