import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '../../../components/Dialog';
// import CancelIcon from '@material-ui/icons/Cancel';

import CustomInput from '../../../components/CustomInput';
const AddCoupon = (props) => {
  const { name, setName, percent, setPercent, open, setOpen, handleClose } = props;

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={() => setOpen(false)}>
        Add Coupon
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <CustomInput
            fullWidth
            label='Name'
            placeholder='Name'
            value={name}
            type='text'
            onChange={(e) => setName(e.target.value)}
            eyeIcon={false}
            showInputValue={true}
          />
          {name === '' && (
            <Typography
              variant='body2'
              style={{
                color: 'red',
                fontWeight: 600,
                fontSize: '12px',
                marginTop: '-10px',
                marginBottom: '5px',
              }}
            >
              * Coupon name is required
            </Typography>
          )}

          <CustomInput
            fullWidth
            label='Percent'
            placeholder='Percent'
            value={percent}
            type='text'
            onChange={(e) => setPercent(e.target.value)}
            eyeIcon={false}
            showInputValue={true}
          />
          {percent === '' && (
            <Typography
              variant='body2'
              style={{
                color: 'red',
                fontWeight: 600,
                fontSize: '12px',
                marginTop: '-10px',
                marginBottom: '5px',
              }}
            >
              * Percentage is required
            </Typography>
          )}
        </div>
        <Grid container direction='row' justifyContent='center'>
          <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
            Create
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddCoupon;
