import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import Layout from '../../../layouts/Layout';
/**
 * @author
 * @function Carousels
 **/

const Addresses = () => {
  const classes = useStyles();
  const users = useSelector((state) => state.users);
  const columns = [
    { field: 'id', width: 100 },
    { field: 'Name', width: 200 },
    { field: 'Address', width: 500 },
    { field: 'Landmark', width: 200 },
    { field: 'City', width: 200 },
    { field: 'PIN', width: 200 },
    { field: 'State', width: 200 },
    { field: 'Phone', width: 200 },
    { field: 'AltPhone', width: 200 },
    { field: 'GSTIN', width: 200 },
  ];
  const rows = users.addresses.map((item, idx) => {
    return {
      id: idx + 1,
      Name: item.name,
      Address: item.address,
      Landmark: item.landmark,
      City: item.cityDistrictTown,
      PIN: item.pinCode,
      State: item.state,
      Phone: item.mobileNumber,
      AltPhone: item.alternatePhone,
      GSTIN: item.gstin,
    };
  });

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            ALL ADDRESSES ({users.addresses.length})
          </Typography>
        </Grid>
        {users.addresses.length > 0 ? (
          <MyTable rows={rows} columns={columns} showSearch={false} />
        ) : (
          <MyTableSkeleton />
        )}
      </Grid>
    </Layout>
  );
};

export default Addresses;
