import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import CustomInput from '../../../../components/CustomInput';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../../components/Dialog';
import { mainColour2 } from '../../../../utils';

const UpdateProductsByCategoryDialog = (props) => {
  const { onClose, open, onClick, checkedArray, handleCategoryInput, offer, handleOffer } = props;

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Discount A Category</DialogTitle>
      <DialogContent dividers>
        <Grid>
          <Typography variant='h5'>Checked</Typography>
          {checkedArray.length > 0 &&
            checkedArray.map((item, idx) => {
              return (
                <Grid
                  item
                  key={idx}
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
                >
                  <CustomInput
                    fullWidth
                    label='Category Name'
                    placeholder='Category Name'
                    value={item.name}
                    type='text'
                    onChange={(e) => handleCategoryInput('name', e.target.value, idx, 'checked')}
                    eyeIcon={false}
                    showInputValue={true}
                  />
                  <CustomInput
                    fullWidth
                    label='Offer'
                    placeholder='Offer'
                    value={offer[idx]}
                    type='text'
                    onChange={(e) => handleOffer(e, idx)}
                    eyeIcon={false}
                    showInputValue={true}
                  />
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={onClick}
          variant='contained'
          style={{ backgroundColor: mainColour2, color: 'white' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateProductsByCategoryDialog;
