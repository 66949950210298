import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import formatDate from '../../../helpers/formatDate';
import replaceImageUrl, { getSmallImageUrl } from '../../../helpers/replaceImageUrl';
import Layout from '../../../layouts/Layout';
import { mainColour1, mainColour3, userApp } from '../../../utils';

export const CartTable = ({ items }) => {
  const classes = useStyles();

  return (
    <TableBody>
      {items.map((item) => {
        let photo = replaceImageUrl(item.product.productPictures[0].img);
        return (
          <TableRow key={item.photo}>
            <TableCell>
              <a href={photo} target='_blank' rel='noreferrer'>
                <img
                  src={getSmallImageUrl(photo)}
                  alt={`${item.name} ${item.prodCode}`}
                  className={classes.tableImage}
                />
              </a>
            </TableCell>
            <TableCell>
              <a href={`${userApp}/product/${item.product._id}`} target='_blank' rel='noreferrer'>
                <Button
                  style={{
                    backgroundColor: mainColour3,
                    color: mainColour1,
                  }}
                  size='small'
                >
                  <LinkIcon />
                </Button>
              </a>
            </TableCell>
            <TableCell>{item.product.prodCode}</TableCell>
            <TableCell>{item.hsnCode}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>{item.product.gPrice}</TableCell>
            <TableCell>{item.product.offer}</TableCell>
            <TableCell>{item.product.discAmt}</TableCell>
            <TableCell>{item.product.gstAmt}</TableCell>
            <TableCell>{item.product.priceWithGST}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const Carts = () => {
  const classes = useStyles();
  const carts = useSelector((state) => state.cart.carts);

  const columns = [
    { field: 'Product', width: 120 },
    {
      field: 'Link',
      renderCell: (params) => (
        <a
          href={`${userApp}/product/${params.row.item.product._id}`}
          target='_blank'
          rel='noreferrer'
        >
          <Button style={{ backgroundColor: mainColour3, color: mainColour1 }} size='small'>
            <LinkIcon />
          </Button>
        </a>
      ),
      width: 120,
      align: 'center',
    },
    { field: 'ProdCode', width: 150 },
    { field: 'HSN_Code', width: 150 },
    { field: 'Qty', width: 100 },
    { field: 'GP', width: 100 },
    { field: 'Offer', width: 110 },
    { field: 'DiscAmt', width: 135 },
    { field: 'GstAmt', width: 135 },
    { field: 'NP_GST', width: 135 },
  ];

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            CART ITEMS
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {carts.length > 0 ? (
            <Paper style={{ border: '1px solid gray' }}>
              {carts.map((cart) => {
                const items = cart.cartItems;
                if (items.length > 0)
                  return (
                    <>
                      <div style={{ marginLeft: '15px', marginTop: '15px' }}>
                        <Typography variant='h6'>
                          {`${cart.user.name} - ${cart.user.contactNumber} - ${
                            cart.user.email
                          } - ${formatDate(cart.updatedAt)}`}
                        </Typography>
                      </div>
                      <TableContainer style={{ display: 'flex', borderRadius: '0' }}>
                        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
                          <TableHead>
                            <TableRow style={{ borderBottom: '1px solid gray' }}>
                              {columns.map((col) => {
                                return (
                                  <TableCell
                                    key={col.field}
                                    style={{
                                      fontSize: '16px',
                                    }}
                                  >
                                    {col.field}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <CartTable items={items} />
                        </Table>
                      </TableContainer>
                    </>
                  );
              })}
            </Paper>
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Carts;
