import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { userConstants, usersConstants } from './constants';

export const signup = (user) => {
  return async (dispatch) => {
    //dispatch a user register request
    dispatch({ type: userConstants.USER_REGISTER_REQUEST });

    axios
      .post('/admin/signup', {
        ...user,
      })
      .then((response) => {
        const { message } = response.data;

        dispatch({
          type: userConstants.USER_REGISTER_SUCCESS,
          payload: { message },
        });
        toast.success('Sign up successful!');
      })
      .catch((error) => {
        dispatch({
          type: userConstants.USER_REGISTER_FAILURE,
          payload: { error: 'Admin already registered!' },
        });
        toast.error('Admin already registered!');
      });
  };
};

export const approveAsReseller = (payload) => {
  return async (dispatch) => {
    //dispatch a login request
    dispatch({ type: usersConstants.APPROVE_AS_RESELLER_REQUEST });

    axios
      .put('/admin/approveasreseller', payload)
      .then((response) => {
        const { user } = response.data;
        dispatch({
          type: usersConstants.APPROVE_AS_RESELLER_SUCCESS,
          payload: { user },
        });

        toast.success(`${user.reseller === true ? 'Approved as' : 'Removed from'} reseller.`);
      })
      .catch((error) => {
        dispatch({
          type: usersConstants.APPROVE_AS_RESELLER_FAILURE,
          payload: { error: error },
        });

        toast.error('Something went wrong!');
      });
  };
};

export const updateResellerOffer = (payload) => {
  return async (dispatch) => {
    //dispatch a login request
    dispatch({ type: usersConstants.UPDATE_RESELLER_OFFER_REQUEST });

    axios
      .put('/admin/updatereselleroffer', payload)
      .then((response) => {
        const { user } = response.data;
        dispatch({
          type: usersConstants.UPDATE_RESELLER_OFFER_SUCCESS,
          payload: { user },
        });

        toast.success(`Offer updated for reseller ${user.name}.`);
      })
      .catch((error) => {
        dispatch({
          type: usersConstants.UPDATE_RESELLER_OFFER_FAILURE,
          payload: { error: error },
        });

        toast.error('Something went wrong!');
      });
  };
};
