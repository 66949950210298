import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { accountNameConstants } from './constants';

const getAllAccountName = () => {
  return async (dispatch) => {
    //dispatch a get product request
    dispatch({ type: accountNameConstants.GET_ALL_ACCOUNT_NAMES_REQUEST });

    axios
      .get('/accountnames')
      .then((response) => {
        const { accountNames } = response.data;
        dispatch({
          type: accountNameConstants.GET_ALL_ACCOUNT_NAMES_SUCCESS,
          payload: { accountNames: accountNames },
        });
      })
      .catch((error) => {
        dispatch({
          type: accountNameConstants.GET_ALL_ACCOUNT_NAMES_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addAccountName = (payload) => {
  return async (dispatch) => {
    dispatch({ type: accountNameConstants.ADD_NEW_ACCOUNT_NAME_REQUEST });

    axios
      .post('/accountnames', payload)
      .then((response) => {
        dispatch({
          type: accountNameConstants.ADD_NEW_ACCOUNT_NAME_SUCCESS,
          payload: { accountName: response.data.accountName },
        });
        dispatch(getAllAccountName());
        toast.success('New account name created!');
      })
      .catch((error) => {
        dispatch({
          type: accountNameConstants.ADD_NEW_ACCOUNT_NAME_FAILURE,
          payload: { error: error },
        });
        toast.error('Account creation failed! Please try later');
      });
  };
};

export const updateAccount = (payload) => {
  return async (dispatch) => {
    dispatch({ type: accountNameConstants.UPDATE_ACCOUNT_NAME_REQUEST });
    axios
      .put(`/accountnames/${payload._id}`, payload)
      .then(() => {
        dispatch({ type: accountNameConstants.UPDATE_ACCOUNT_NAME_SUCCESS });
        dispatch(getAllAccountName());
        toast.success('Account name updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: accountNameConstants.UPDATE_ACCOUNT_NAME_FAILURE,
          payload: { error },
        });
        toast.error('Account name update failed! Please try later');
      });
  };
};

export { getAllAccountName };
