import { Button, ButtonGroup, Grid, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../assets/css/useStyles';
import ButtonsGroupAccounts from '../../../../components/ButtonsGroupAccounts/index';
import Layout from '../../../../layouts/Layout';
import { addEntry, deleteEntry } from '../../../../redux/actions';
import { mainColour2 } from '../../../../utils';
import EditUnfinishedJournalEntryDialogbox from './EditUnfinishedJournalEntryDialogbox';
import JournalsTable from './JournalsTable';
import UnfinishedJournalsTable from './UnfinishedJournalsTable';

const Journal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const journal = useSelector((state) => state.journal);
  const year = useSelector((state) => state.global.year);
  const accountNameRedux = useSelector((state) => state.accountName);
  const accountNameList = accountNameRedux.accountNames
    .map((accountName) => accountName.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  const [accountSubnameList, setAccountSubnameList] = useState([]);

  const [date, setDate] = useState(journal.date ? journal.date : null);
  const [accountName, setAccountName] = useState('');
  const [accountSubname, setAccountSubname] = useState('');
  const [crAmount, setCrAmount] = useState(0);
  const [dbAmount, setDbAmount] = useState(0);
  const [narration, setNarration] = useState('');

  useEffect(() => {
    const list = accountNameRedux.accountNames.filter((item) => item.name === accountName);
    const subnameList = list.map((item) => item.subname);
    setAccountSubnameList(subnameList);
  }, [accountName]);

  const handleJournal = () => {
    if (date === '' || date === null) return toast.error('Date is required !');
    const present = new Date(date);
    const from = new Date(`04/01/${year}`);
    const to = new Date(`03/31/${year + 1}`);
    if (present < from || present > to) {
      return toast.error('Invalid Date !');
    }
    if (accountName === '') return toast.error('Account name is required !');
    if (accountSubnameList.length > 0 && accountSubnameList[0] !== '' && accountSubname === '') {
      // '' is always inserted to account subname list when account has no subaccount
      return toast.error('Account subname is required !');
    }
    if (crAmount === 0 && dbAmount === 0) return toast.error('Credit/Debit amount is required !');
    if (crAmount && dbAmount) return toast.error('Either debit / credit amount is required !');
    let accountNameId;
    if (accountSubname === '') {
      accountNameId = accountNameRedux.accountNames.find((item) => item.name === accountName)._id;
    } else {
      accountNameId = accountNameRedux.accountNames.find(
        (item) => item.subname === accountSubname && item.name === accountName,
      )._id;
    }
    const payload = {
      accountName: accountNameId,
      accountNameReal: accountName,
      accountSubnameReal: accountSubname,
      crAmount: Number(crAmount),
      dbAmount: Number(dbAmount * -1),
      narration: narration,
    };
    dispatch(addEntry(payload, date));
    // setDate(null)
    setAccountName('');
    setAccountSubname('');
    setCrAmount(0);
    setDbAmount(0);
    // setNarration("");
  };

  const columns = [
    { field: 'id', width: 100 },
    { field: 'Account_Name', width: 200, align: 'left' },
    { field: 'Account_Subname', width: 200, align: 'left' },
    { field: 'Debit', width: 120, align: 'right' },
    { field: 'Credit', width: 120, align: 'right' },
    { field: 'Narration', width: 400, align: 'left' },
    {
      field: 'Actions',
      renderCell: (params) => (
        <ButtonGroup>
          <Button size='small' variant='outlined' onClick={() => showEditEntryDialog(params.row)}>
            <EditIcon style={{ color: mainColour2 }} />
          </Button>
          <Button size='small' onClick={() => dispatch(deleteEntry(params.row.id - 1, year))}>
            <DeleteIcon style={{ color: 'red' }} />
          </Button>
        </ButtonGroup>
      ),
      width: 135,
      align: 'center',
    },
  ];

  const [myEntry, setMyEntry] = useState(null);
  const [editEntryDialog, setEditEntryDialog] = useState(false);

  const showEditEntryDialog = (item) => {
    setEditEntryDialog(true);
    let entryToBeUpdated = journal.journals.find((journal, _idx) => item.id - 1 === _idx);
    entryToBeUpdated.id = item.id - 1;
    setMyEntry(entryToBeUpdated);
  };

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            JOURNAL ENTRY
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='Date'
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <ButtonsGroupAccounts
            btn1='Account Master'
            btn2='Cash'
            btn3='Ledger'
            link1='/accounts-accountmaster'
            link2='/accounts-transactions-cash'
            link3='/accounts-transactions-ledger'
          />
        </Grid>
      </Grid>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='accountName_select'
            options={accountNameList}
            getOptionLabel={(option) => option}
            value={accountName}
            onChange={(e, value) => setAccountName(value)}
            disabled={date === null}
            renderInput={(params) => (
              <TextField {...params} required label='Account Name' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='accountSubame_select'
            options={accountSubnameList}
            getOptionLabel={(option) => option}
            value={accountSubname}
            onChange={(e, value) => setAccountSubname(value)}
            disabled={accountName === ''}
            renderInput={(params) => (
              <TextField {...params} label='Account Subname' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            type='number'
            disabled={accountName === ''}
            variant='outlined'
            value={dbAmount}
            label='Dr. Amount'
            placeholder='Dr. Amount'
            onChange={(e) => setDbAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            type='number'
            disabled={accountName === ''}
            variant='outlined'
            value={crAmount}
            label='Cr. Amount'
            placeholder='Cr. Amount'
            onChange={(e) => setCrAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            variant='outlined'
            value={narration}
            label='Narration'
            placeholder='Narration'
            onChange={(e) => setNarration(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            onClick={handleJournal}
            variant='contained'
            className={classes.externalLinks}
            style={{ height: '56px' }}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      {/* Links */}
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Typography variant='h4' className={classes.heading}>
          VOUCHER NUM = {journal.voucherNum}
        </Typography>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12}>
          <UnfinishedJournalsTable
            columns={columns}
            setDate={setDate}
            setNarration={setNarration}
          />
        </Grid>

        {editEntryDialog && (
          <EditUnfinishedJournalEntryDialogbox
            journal={myEntry}
            columns={columns}
            editEntryDialog={editEntryDialog}
            setEditEntryDialog={setEditEntryDialog}
          />
        )}
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <JournalsTable />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Journal;
