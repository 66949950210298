import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { carouselConstants } from './constants';

export const getCarousels = () => {
  return async (dispatch) => {
    dispatch({ type: carouselConstants.GET_ALL_CAROUSELS_REQUEST });

    axios
      .get('/admin/carousels')
      .then((response) => {
        const { carousels } = response.data;
        dispatch({
          type: carouselConstants.GET_ALL_CAROUSELS_SUCCESS,
          payload: { carousels: carousels },
        });
      })
      .catch((error) => {
        dispatch({
          type: carouselConstants.GET_ALL_CAROUSELS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const getTrueCarousels = () => {
  return async (dispatch) => {
    dispatch({ type: carouselConstants.GET_TRUE_CAROUSELS_REQUEST });

    axios
      .get('/carousels/true')
      .then((response) => {
        const { carousels } = response.data;
        dispatch({
          type: carouselConstants.GET_TRUE_CAROUSELS_SUCCESS,
          payload: { carousels: carousels },
        });
      })
      .catch((error) => {
        dispatch({
          type: carouselConstants.GET_TRUE_CAROUSELS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addCarousel = (form) => {
  return async (dispatch) => {
    dispatch({ type: carouselConstants.ADD_CAROUSELS_REQUEST });

    axios
      .post('/admin/carousels', form)
      .then((response) => {
        dispatch({
          type: carouselConstants.ADD_CAROUSELS_SUCCESS,
          payload: { carousels: response.data.carousel },
        });

        toast.success('Carousels for the occasion created!');
      })
      .catch((error) => {
        dispatch({
          type: carouselConstants.ADD_CAROUSELS_FAILURE,
          payload: { error: error },
        });

        toast.error('Ocassion name already present in database!');
      });
  };
};
