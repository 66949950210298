import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
//middleware "thunk" is used for async actions
/*
Redux itself is synchronous, so how the async operations like network request work with Redux? Here middlewares come handy. As discussed earlier,
reducers are the place where all the execution logic is written. Reducer has nothing to do with who performs it, how much time it is taking or 
logging the state of the app before and after the action is dispatched.

In this case, Redux middleware function provides a medium to interact with dispatched action before they reach the reducer. Customized middleware
functions can be created by writing high order functions (a function that returns another function), which wraps around some logic. Multiple 
middlewares can be combined together to add new functionality, and each middleware requires no knowledge of what came before and after. You can 
imagine middlewares somewhere between action dispatched and reducer.

Commonly, middlewares are used to deal with asynchronous actions in your app. Redux provides with API called applyMiddleware which allows us to 
use custom middleware as well as Redux middlewares like redux-thunk and redux-promise. It applies middlewares to store. 
*/

export default store;
