import { carouselConstants } from '../actions/constants';

const initialState = {
  carousels: [],
  loading: false,
  error: null,
};

const carouselReducers = (state = initialState, action) => {
  switch (action.type) {
    case carouselConstants.GET_TRUE_CAROUSELS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case carouselConstants.GET_TRUE_CAROUSELS_SUCCESS:
      state = {
        ...state,
        carousels: action.payload.carousels,
        loading: false,
      };
      break;
    case carouselConstants.GET_TRUE_CAROUSELS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case carouselConstants.GET_ALL_CAROUSELS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case carouselConstants.GET_ALL_CAROUSELS_SUCCESS:
      state = {
        ...state,
        carousels: action.payload.carousels.reverse(),
        loading: false,
      };
      break;
    case carouselConstants.GET_ALL_CAROUSELS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case carouselConstants.ADD_CAROUSELS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case carouselConstants.ADD_CAROUSELS_SUCCESS: {
      const carousels = action.payload.carousels;
      state.carousels.unshift(carousels);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case carouselConstants.ADD_CAROUSELS_FAILURE:
      state = {
        ...initialState,
      };
      break;
    default:
      break;
  }
  return state;
};

export default carouselReducers;
