import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CategoryIcon from '@material-ui/icons/Category';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhotoIcon from '@material-ui/icons/Photo';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { mainColour1, mainColour2, mainColour3, redColour, secColour2 } from '../utils';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    // height:'100%',
    [theme.breakpoints.up('sm')]: { paddingLeft: drawerWidth, backgroundColor: secColour2 },
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    marginRight: 'auto',
  },
  drawer: {
    width: drawerWidth,
  },

  content: {
    padding: theme.spacing(0, 3),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  appBar: {
    boxShadow: '0px 0px 0px 0px',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  active: {
    backgroundColor: mainColour3,
  },
  activeTextAndIcon: {
    color: mainColour1,
  },
  activeTextAndIconSmall: {
    color: mainColour1,
    textAlign: 'right',
    transform: 'scale(0.9)',
  },
  nonActiveTextAndIcon: {
    color: 'white',
  },
  nonActiveTextAndIconSmall: {
    color: 'white',
    textAlign: 'right',
    transform: 'scale(0.9)',
  },
  list: {
    '&:hover': {
      backgroundColor: mainColour3,
    },
  },
}));

const menuItems = [
  {
    text: 'Dashboard',
    icon: <HomeIcon />,
    path: '/',
  },
  {
    text: 'Categories',
    icon: <CategoryIcon />,
    path: '/categories',
  },
  {
    text: 'Products',
    icon: <StoreIcon />,
    path: '/products',
  },
  {
    text: 'Available Stock',
    icon: <StoreIcon />,
    path: '/quantityavailable',
  },
  {
    text: 'Carts',
    icon: <ShoppingCartIcon />,
    path: '/carts',
  },
  {
    text: 'Orders',
    icon: <ShoppingBasketIcon />,
    path: '/orders',
  },
  {
    text: 'Report',
    icon: <AssessmentIcon />,
    path: '/report',
  },
  {
    text: 'Coupons',
    icon: <LocalOfferIcon />,
    path: '/coupons',
  },
  {
    text: 'Carousels',
    icon: <ViewCarouselIcon />,
    path: '/carousels',
  },
  {
    text: 'Cards',
    icon: <PhotoIcon />,
    path: '/cards',
  },
  {
    text: 'Users',
    icon: <GroupIcon />,
    path: '/allusers',
  },
  {
    text: 'Addresses',
    icon: <LocationOnIcon />,
    path: '/addresses',
  },
  {
    text: 'Subscribers',
    icon: <EmailIcon />,
    path: '/subscribers',
  },
  {
    text: 'External Links',
    icon: <LinkIcon />,
    path: '/externallinks',
  },
  // {
  //     text: 'Pages',
  //     icon: <PagesIcon />,
  //     path: '/pages'
  // },
  // {
  //     text: 'Dashboard',
  //     icon: <HomeIcon />,
  //     path: '/'
  // },
];

const menuItems2 = [
  {
    text: 'Dashboard',
    icon: <HomeIcon />,
    path: '/',
  },
  {
    text: 'Account Master',
    icon: <HomeIcon />,
    path: '/accounts-accountmaster',
  },
  {
    text: 'Party Master',
    icon: <GroupIcon />,
    path: '/accounts-buyersellermaster',
  },
  {
    text: 'Transactions',
    icon: <CurrencyRupeeIcon />,
    path: '/transactions',
  },
  {
    text: 'Cash',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-cash',
    size: 'small',
  },
  {
    text: 'Journal',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-journal',
    size: 'small',
  },
  {
    text: 'Ledger',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-ledger',
    size: 'small',
  },
  {
    text: 'Trial Balance',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-trialbalance',
    size: 'small',
  },
  {
    text: 'Trading Account',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-tradingaccount',
    size: 'small',
  },
  {
    text: 'P&L Account',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-pnlaccount',
    size: 'small',
  },
  {
    text: 'Balance Sheet',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-balancesheet',
    size: 'small',
  },
  {
    text: 'Next Year Prep.',
    icon: <AccountBalanceWalletIcon />,
    path: '/accounts-transactions-trialbalance2',
    size: 'small',
  },
  {
    text: 'External Links',
    icon: <LinkIcon />,
    path: '/externallinks',
  },
];

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.global.app);
  const sidebarItems = app === 'Admin' ? menuItems : app === 'Accounts' ? menuItems2 : [];
  return (
    <>
      <List disablePadding className={classes.toolbar} style={{ zIndex: '-100' }}>
        <MenuItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div>
            <Avatar style={{ backgroundColor: redColour }}>{auth.user.name[0]}</Avatar>
          </div>
          <div>
            <ListItemText
              primary={
                <Typography
                  variant='body2'
                  style={{ marginLeft: '5px', fontWeight: 600, color: mainColour1 }}
                >
                  {auth.user.name}
                </Typography>
              }
              secondary={
                <Typography style={{ marginLeft: '5px', fontSize: '12px', color: mainColour1 }}>
                  {auth.user.email}
                </Typography>
              }
            />
          </div>
        </MenuItem>
      </List>

      <Divider />
      <List
        disablePadding
        className={classes.drawer}
        style={{ height: '100%', backgroundColor: mainColour2 }}
      >
        {sidebarItems.map((item, index) => {
          return (
            <NavLink to={item.path} key={index} style={{ textDecoration: 'none' }}>
              <ListItem
                button
                onClick={() => history.push(item.path)}
                className={clsx(
                  classes.list,
                  location.pathname === item.path ? classes.active : null,
                )}
              >
                <ListItemIcon
                  className={
                    location.pathname === item.path
                      ? item.size === 'small'
                        ? classes.activeTextAndIconSmall
                        : classes.activeTextAndIcon
                      : item.size === 'small'
                      ? classes.nonActiveTextAndIconSmall
                      : classes.nonActiveTextAndIcon
                  }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant='h6'
                      className={
                        location.pathname === item.path
                          ? item.size === 'small'
                            ? classes.activeTextAndIconSmall
                            : classes.activeTextAndIcon
                          : item.size === 'small'
                          ? classes.nonActiveTextAndIconSmall
                          : classes.nonActiveTextAndIcon
                      }
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    </>
  );
};

export default Sidebar;
export { useStyles };
