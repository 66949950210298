import { TableCell as MUITableCell } from '@material-ui/core';
import React from 'react';

const TableCell = (props) => {
  return (
    <MUITableCell
      {...props}
      style={{ border: 0, fontSize: '20px', fontWeight: props.bold ? 600 : 0 }}
    >
      {props.children}
    </MUITableCell>
  );
};

export default TableCell;
