import { TableRow as MUITableRow } from '@material-ui/core';
import React from 'react';

const TableRow = (props) => {
  return (
    <MUITableRow
      {...props}
      style={{
        height: '55px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid gray',
      }}
    >
      {props.children}
    </MUITableRow>
  );
};

export default TableRow;
