import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React from 'react';
import CustomInput from '../../../../components/CustomInput';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../../components/Dialog';
import { mainColour2, secColour } from '../../../../utils';
const UpdateCategoriesDialog = (props) => {
  const { onClose, open, onClick, categoryList, checkedArray, handleCategoryInput } = props;

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Edit category</DialogTitle>
      <DialogContent dividers>
        <Grid>
          <Typography variant='h5'>Checked</Typography>
          {checkedArray.length > 0 &&
            checkedArray.map((item, idx) => {
              return (
                <Grid
                  item
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
                  key={idx}
                >
                  <CustomInput
                    fullWidth
                    label='Category Name'
                    placeholder='Category Name'
                    value={item.name}
                    type='text'
                    onChange={(e) => handleCategoryInput('name', e.target.value, idx, 'checked')}
                    eyeIcon={false}
                    showInputValue={true}
                  />
                  <FormControl variant='outlined' className='form-control'>
                    <InputLabel id='update_category_checked_select_category'>
                      Select Category
                    </InputLabel>
                    <Select
                      labelId='update_category_checked_select_category'
                      value={item.parentId}
                      onChange={(e) =>
                        handleCategoryInput('parentId', e.target.value, idx, 'checked')
                      }
                    >
                      {categoryList.map((item, index) =>
                        item.parentId ? (
                          <MenuItem
                            key={index}
                            value={item.value}
                            style={{
                              backgroundColor: secColour,
                              color: 'black',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            key={index}
                            value={item.value}
                            style={{
                              backgroundColor: mainColour2,
                              color: 'white',
                              dispaly: 'flex',
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>

                  <FormControl variant='outlined' className='form-control'>
                    <InputLabel id='update_category_checked_select_type'>Select Type</InputLabel>
                    <Select
                      labelId='update_category_checked_select_type'
                      value={item.type}
                      onChange={(e) => handleCategoryInput('type', e.target.value, idx, 'checked')}
                    >
                      <MenuItem key={1} value='Store'>
                        Store
                      </MenuItem>
                      <MenuItem key={2} value='Product'>
                        Product
                      </MenuItem>
                      <MenuItem key={3} value='Page'>
                        Page
                      </MenuItem>
                      <MenuItem key={4} disabled value='null'>
                        null
                      </MenuItem>
                      <MenuItem key={5} disabled value='undefined'>
                        undefined
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={onClick}
          variant='contained'
          style={{ backgroundColor: mainColour2, color: 'white' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCategoriesDialog;
