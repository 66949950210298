import axios from '../../helpers/axios';
import {
  accountGroupHeadConstants,
  accountHeadConstants,
  accountNameConstants,
  buyerSellerConstants,
  cardConstants,
  carouselConstants,
  cartConstants,
  cashConstants,
  categoryConstants,
  couponConstants,
  initialDataConstants,
  journalConstants,
  orderConstants,
  pageConstants,
  pnlHeadConstants,
  productConstants,
  subscribersConstants,
  usersConstants,
} from './constants';

export const getInitialDataAdmin = () => {
  return async (dispatch) => {
    dispatch({ type: productConstants.GET_ALL_PRODUCTS_REQUEST });
    dispatch({ type: categoryConstants.GET_ALL_CATEGORIES_REQUEST });
    dispatch({ type: cardConstants.GET_ALL_CARDS_REQUEST });
    dispatch({ type: carouselConstants.GET_ALL_CAROUSELS_REQUEST });
    dispatch({ type: usersConstants.GET_ALL_USERS_REQUEST });
    dispatch({ type: subscribersConstants.GET_ALL_SUBSCRIBERS_REQUEST });
    dispatch({ type: orderConstants.GET_CUSTOMER_ORDER_REQUEST });
    dispatch({ type: couponConstants.GET_ALL_COUPONS_REQUEST });
    axios
      .get('/admin/initialdata')
      .then((response) => {
        const {
          categories,
          products,
          orders,
          pages,
          carousels,
          cards,
          users,
          addresses,
          subscribers,
          sizes,
          colours,
          shades,
          coupons,
          carts,
        } = response.data;
        dispatch({
          type: categoryConstants.GET_ALL_CATEGORIES_SUCCESS,
          payload: { categories: categories },
        });
        dispatch({
          type: productConstants.GET_ALL_PRODUCTS_SUCCESS,
          payload: { products: products },
        });
        dispatch({
          type: orderConstants.GET_CUSTOMER_ORDER_SUCCESS,
          payload: { orders: orders },
        });
        dispatch({
          type: carouselConstants.GET_ALL_CAROUSELS_SUCCESS,
          payload: { carousels: carousels },
        });
        dispatch({
          type: cardConstants.GET_ALL_CARDS_SUCCESS,
          payload: { cards: cards },
        });
        dispatch({
          type: productConstants.GET_ALL_SIZES_SUCCESS,
          payload: { sizes: sizes },
        });
        dispatch({
          type: productConstants.GET_ALL_COLOURS_SUCCESS,
          payload: { colours: colours },
        });
        dispatch({
          type: productConstants.GET_ALL_SHADES_SUCCESS,
          payload: { shades: shades },
        });
        dispatch({
          type: usersConstants.GET_ALL_USERS_SUCCESS,
          payload: { users: users },
        });
        dispatch({
          type: usersConstants.GET_ALL_USERS_ADDRESSES_SUCCESS,
          payload: { addresses: addresses },
        });
        dispatch({
          type: subscribersConstants.GET_ALL_SUBSCRIBERS_SUCCESS,
          payload: { subscribers: subscribers },
        });
        dispatch({
          type: couponConstants.GET_ALL_COUPONS_SUCCESS,
          payload: { coupons: coupons },
        });
        dispatch({
          type: pageConstants.GET_ALL_PAGES_SUCCESS,
          payload: { pages: pages },
        });
        dispatch({
          type: cartConstants.GET_ALL_CARTS_SUCCESS,
          payload: { carts: carts },
        });
      })
      .catch((error) => {
        dispatch({
          type: initialDataConstants.GET_ALL_INITIAL_DATA_FAILURE,
          payload: { error: error },
        });
      });
  };
};
export const getInitialDataAccounts = (payload) => {
  return async (dispatch) => {
    dispatch({ type: accountNameConstants.GET_ALL_ACCOUNT_NAMES_REQUEST });
    dispatch({ type: accountGroupHeadConstants.GET_ALL_ACCOUNT_GROUP_HEADS_REQUEST });
    dispatch({ type: accountHeadConstants.GET_ALL_ACCOUNT_HEADS_REQUEST });
    dispatch({ type: pnlHeadConstants.GET_ALL_PNL_HEADS_REQUEST });
    dispatch({ type: buyerSellerConstants.GET_ALL_BUYER_SELLERS_REQUEST });
    dispatch({ type: cashConstants.GET_ALL_CASHES_REQUEST });
    dispatch({ type: journalConstants.GET_ALL_JOURNALS_REQUEST });
    axios
      .get(`/accounts/initialdata/${payload.year}`)
      .then((response) => {
        const {
          accountNames,
          accountGroupHeads,
          accountHeads,
          pnlHeads,
          buyerSellers,
          cashes,
          journals,
          newJournalVoucherNum,
        } = response.data;
        dispatch({
          type: accountNameConstants.GET_ALL_ACCOUNT_NAMES_SUCCESS,
          payload: { accountNames: accountNames },
        });
        dispatch({
          type: accountGroupHeadConstants.GET_ALL_ACCOUNT_GROUP_HEADS_SUCCESS,
          payload: { accountGroupHeads: accountGroupHeads },
        });
        dispatch({
          type: accountHeadConstants.GET_ALL_ACCOUNT_HEADS_SUCCESS,
          payload: { accountHeads: accountHeads },
        });
        dispatch({
          type: pnlHeadConstants.GET_ALL_PNL_HEADS_SUCCESS,
          payload: { pnlHeads: pnlHeads },
        });
        dispatch({
          type: buyerSellerConstants.GET_ALL_BUYER_SELLERS_SUCCESS,
          payload: { buyerSellers: buyerSellers },
        });
        dispatch({
          type: cashConstants.GET_ALL_CASHES_SUCCESS,
          payload: { cashes: cashes },
        });
        dispatch({
          type: journalConstants.GET_ALL_JOURNALS_SUCCESS,
          payload: { journals: journals },
        });
        dispatch({
          type: journalConstants.GET_NEW_VOUCHER_NUM_SUCCESS,
          payload: { voucherNum: newJournalVoucherNum },
        });
      })
      .catch((error) => {
        dispatch({
          type: initialDataConstants.GET_ALL_INITIAL_DATA_FAILURE,
          payload: { error: error },
        });
      });
  };
};
