import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { authConstants } from './constants';

//these(here login, isUserLoggedIn, signout) are the action creators which create actions

export const login = (user) => {
  return async (dispatch) => {
    //dispatch a login request
    dispatch({ type: authConstants.LOGIN_REQUEST });

    axios
      .post('/admin/signin', {
        ...user,
      })
      .then((response) => {
        const { token, user } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: { token, user },
        });

        toast.success('Log in successful');
      })
      .catch((error) => {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: error },
        });

        toast.error('Invalid credentials!');
      });
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = JSON.parse(localStorage.getItem('user'));
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: { token, user },
      });
    } else {
      dispatch({ type: authConstants.LOGOUT_SUCCESS });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST });

    axios
      .post('/admin/signout')
      .then(() => {
        localStorage.clear();
        dispatch({ type: authConstants.LOGOUT_SUCCESS });
        toast.success('Log out successful');
      })
      .catch((error) => {
        dispatch({
          type: authConstants.LOGOUT_FAILURE,
          payload: { error: error },
        });
        toast.error('You must be logged in to logout!');
      });
  };
};
