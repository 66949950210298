import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { cardConstants } from './constants';

export const getCards = () => {
  return async (dispatch) => {
    dispatch({ type: cardConstants.GET_ALL_CARDS_REQUEST });

    axios
      .get('/admin/cards')
      .then((response) => {
        const { cards } = response.data;
        dispatch({
          type: cardConstants.GET_ALL_CARDS_SUCCESS,
          payload: { cards: cards },
        });
      })
      .catch((error) => {
        dispatch({
          type: cardConstants.GET_ALL_CARDS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const getTrueCards = () => {
  return async (dispatch) => {
    dispatch({ type: cardConstants.GET_TRUE_CARDS_REQUEST });

    axios
      .get('/cards/true')
      .then((response) => {
        const { cards } = response.data;
        dispatch({
          type: cardConstants.GET_TRUE_CARDS_SUCCESS,
          payload: { cards: cards },
        });
      })
      .catch((error) => {
        dispatch({
          type: cardConstants.GET_TRUE_CARDS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addCard = (form) => {
  return async (dispatch) => {
    dispatch({ type: cardConstants.ADD_CARD_REQUEST });

    axios
      .post('/admin/cards', form)
      .then((response) => {
        dispatch({
          type: cardConstants.ADD_CARD_SUCCESS,
          payload: { card: response.data.card },
        });

        toast.success('Card created!');
      })
      .catch((error) => {
        dispatch({
          type: cardConstants.ADD_CARD_FAILURE,
          payload: { error: error },
        });

        toast.error('Card name already present in database!');
      });
  };
};
