import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../../helpers/axios';
import { mainColour2 } from '../../../utils';

const ShiprocketDialog = (props) => {
  const { open, setOpen, orderId } = props;
  const [length, setLength] = useState(26);
  const [breadth, setBreadth] = useState(24);
  const [height, setHeight] = useState(3);
  const [weight, setWeight] = useState(0.5);
  const createShiprocketOrder = async (orderId) => {
    const payload = {
      orderId: orderId,
      package: {
        length: length,
        breadth: breadth,
        height: height,
        weight: weight,
      },
    };
    try {
      const response = await axios.post('/admin/orders/shiprocket', payload);
      if (response.status === 201) {
        toast.success(response.data.message);
      }
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };
  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle
        id='customized-dialog-title'
        onClose={() => setOpen(false)}
        style={{ borderBottom: `4px solid ${mainColour2}` }}
      >
        Shiprocket Package
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Grid container>
          <Grid item sm={2}>
            <TextField
              size='small'
              variant='outlined'
              label='Length (cm)'
              id='length'
              value={length}
              type='number'
              onChange={(e) => setLength(e.target.value)}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size='small'
              variant='outlined'
              label='Breadth (cm)'
              id='breadth'
              value={breadth}
              type='number'
              onChange={(e) => setBreadth(e.target.value)}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size='small'
              variant='outlined'
              label='Height (cm)'
              id='height'
              value={height}
              type='number'
              onChange={(e) => setHeight(e.target.value)}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size='small'
              variant='outlined'
              label='Weight (kg)'
              id='weight'
              value={weight}
              type='text'
              onChange={(e) => setWeight(e.target.value)}
            />
          </Grid>
          <Grid item sm={4}>
            <Button
              style={{ backgroundColor: mainColour2, color: 'white' }}
              // size='small'
              variant='contained'
              onClick={() => createShiprocketOrder(orderId)}
            >
              Create Order
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShiprocketDialog;
