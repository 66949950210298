import { Button, Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useState } from 'react';
import CustomInput from '../../../components/CustomInput';
import { Dialog, DialogContent, DialogTitle } from '../../../components/Dialog';

const AddCarousel = (props) => {
  const {
    occasion,
    setOccasion,
    // show,setShow,
    open,
    setOpen,
    handleClose,
    productPictures,
    setProductPictures,
    handleProductPictures,
  } = props;
  const [file, setFile] = useState([]);

  function uploadSingleFile(e) {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  const deleteLastSelectedPicture = () => {
    productPictures.pop();
    setProductPictures(productPictures);
  };

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={() => setOpen(false)}>
        Add Carousels for an Occasion
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <div className='form-group preview' style={{ display: 'flex', marginTop: '30px' }}>
            {file.length > 0 &&
              file.map((item, index) => {
                return (
                  <div
                    key={item}
                    style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}
                  >
                    <img src={item} alt='Missing' height='150px' width='100%' />
                    <CancelIcon
                      onClick={() => {
                        deleteFile(index);
                        deleteLastSelectedPicture();
                      }}
                      style={{
                        color: '#fff',
                        marginTop: '15px',
                        position: 'relative',
                        top: '-87.5%',
                        left: '90%',
                      }}
                    />
                  </div>
                );
              })}
          </div>

          <label htmlFor='upload-photo' style={{ width: '100%' }}>
            <input
              style={{ display: 'none' }}
              id='upload-photo'
              name='upload-photo'
              type='file'
              disabled={file.length === 4}
              className='form-control'
              onChange={(e) => {
                uploadSingleFile(e);
                handleProductPictures(e);
              }}
            />

            {productPictures.length < 1 && (
              <Typography
                variant='body2'
                style={{
                  color: 'red',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginTop: '-10px',
                  marginBottom: '5px',
                }}
              >
                * Atleast one carousel is required
              </Typography>
            )}
            <Button color='primary' variant='contained' component='span' style={{ width: '100%' }}>
              Upload Carousels
            </Button>
          </label>
        </div>

        <CustomInput
          fullWidth
          label='Occasion'
          placeholder='Occasion'
          value={occasion}
          type='text'
          onChange={(e) => setOccasion(e.target.value)}
          eyeIcon={false}
          showInputValue={true}
        />
        {occasion === '' && (
          <Typography
            variant='body2'
            style={{
              color: 'red',
              fontWeight: 600,
              fontSize: '12px',
              marginTop: '-10px',
              marginBottom: '5px',
            }}
          >
            * Occation name is required
          </Typography>
        )}

        <Grid container direction='row' justifyContent='center'>
          <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
            Create
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddCarousel;
