import {
  AppBar,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeYear, signout } from '../redux/actions';
import { mainColour1, mainColour2 } from '../utils';
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      fontWeight: 'normal',
    },
  },
  button: {
    // backgroundColor:secColour,
    // color:'black',
    // padding:"2px"
    marginLeft: '5px',
    '&:hover': {
      // zoom: '110%',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      padding: '4px',
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.global.app);
  const yearInit = useSelector((state) => state.global.year);
  const dispatch = useDispatch();

  const [year, setYear] = useState(yearInit);

  const handleYear = (selected_year) => {
    setYear(selected_year);
    dispatch(changeYear(selected_year));
  };

  const logout = () => {
    dispatch(signout());
  };

  const renderLoggedInLinks = () => {
    return (
      <Button className={classes.button1} variant='outlined' color='inherit' onClick={logout}>
        {!auth.authenticating ? (
          'Logout'
        ) : (
          <CircularProgress size={25} style={{ color: 'white' }} />
        )}
      </Button>
    );
  };

  const years = [
    {
      year: 2021,
      text: '2021-22',
    },
    {
      year: 2022,
      text: '2022-23',
    },
    {
      year: 2023,
      text: '2023-24',
    },
    {
      year: 2024,
      text: '2024-25',
    },
    {
      year: 2025,
      text: '2025-26',
    },
    {
      year: 2026,
      text: '2026-27',
    },
    {
      year: 2027,
      text: '2027-2028',
    },
  ];

  return (
    <>
      <AppBar
        position='fixed'
        className={props.className}
        style={{ backgroundImage: `linear-gradient(to right, ${mainColour1},${mainColour2})` }}
      >
        <Toolbar>
          {props.children}
          <Typography variant='h4' className={classes.title}>
            {app === 'Admin'
              ? 'ADMIN SAMBALPURI FAB'
              : app === 'Accounts'
              ? 'ACCOUNTS SAMBALPURI FAB'
              : ''}
          </Typography>
          {app == 'Accounts' && (
            <>
              <Typography
                variant='h5'
                id='select_year'
                style={{ color: 'white', margin: 10, fontWeight: 600 }}
              >
                SELECT YEAR
              </Typography>
              <FormControl
                size='small'
                sx={{ m: 1, minWidth: 120 }}
                style={{ backgroundColor: 'white' }}
              >
                <Select
                  labelId='select_year'
                  id='select_year'
                  value={year}
                  onChange={(e) => handleYear(e.target.value)}
                  style={{ padding: '0 10px', margin: '-5px 0' }}
                >
                  <MenuItem value='' disabled>
                    <em>YEAR</em>
                  </MenuItem>
                  {years.map((item) => (
                    <MenuItem
                      key={item.year}
                      value={item.year}
                      disabled={Number(process.env.REACT_APP_DEFAULT_YEAR) < item.year}
                    >
                      <Typography variant='h6'>{item.text}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          <div>{auth.authenticate ? renderLoggedInLinks() : null}</div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
