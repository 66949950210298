import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BackdropLoading from '../../../components/BackdropLoading';
import Layout from '../../../layouts/Layout';
import useStyles from './OrderCard/useStyles';
import OrderTemplate from './OrderTemplate';

const Orders = () => {
  const classes = useStyles();
  const order = useSelector((state) => state.order);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Layout sidebar>
      <BackdropLoading loading={order.loading} />

      <>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: '20px' }}
        >
          <Grid item>
            <Typography variant='h4' className={classes.heading}>
              ALL ORDERS ({order.orders.length})
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          centered
          // variant="scrollable"
        >
          <Tab label='NEW ORDERS' />
          <Tab label='PACKED' />
          <Tab label='DISPATCH' />
          <Tab label='DELIVERED' />
        </Tabs>
        {order.orders.length !== 0 && (
          <Grid container direction='column'>
            <Grid item>
              {order.orders.map((orderItem) => {
                const steps = orderItem.orderStatus.map((status) => {
                  return {
                    type: status.type,
                    isCompleted: status.isCompleted,
                    date: status.date && status.date,
                  };
                });
                let activeStatus = 0;
                for (let i = 0; i < orderItem.orderStatus.length; i++) {
                  if (orderItem.orderStatus[i].isCompleted === false) {
                    activeStatus = i;
                    break;
                  }
                }
                if (activeStatus === 0) {
                  activeStatus = 4;
                }
                if (tab === 0 && activeStatus === 1) {
                  // ordered
                  return (
                    <OrderTemplate
                      key={orderItem._id}
                      orderItem={orderItem}
                      steps={steps}
                      activeStatus={activeStatus}
                    />
                  );
                } else if (tab === 1 && activeStatus === 2) {
                  // packed
                  return (
                    <OrderTemplate
                      key={orderItem._id}
                      orderItem={orderItem}
                      steps={steps}
                      activeStatus={activeStatus}
                    />
                  );
                } else if (tab === 2 && activeStatus === 3) {
                  //shipped
                  return (
                    <OrderTemplate
                      key={orderItem._id}
                      orderItem={orderItem}
                      steps={steps}
                      activeStatus={activeStatus}
                    />
                  );
                } else if (tab === 3 && activeStatus === 4) {
                  //delivered
                  return (
                    <OrderTemplate
                      key={orderItem._id}
                      orderItem={orderItem}
                      steps={steps}
                      activeStatus={activeStatus}
                    />
                  );
                }
              })}
            </Grid>
          </Grid>
        )}
      </>
    </Layout>
  );
};

export default Orders;
