import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { categoryConstants } from './constants';
import { getAllProduct } from './product.actions';

//these are the action creators which creates actions
const getAllCategory = () => {
  return async (dispatch) => {
    //dispatch a get categpry request
    dispatch({ type: categoryConstants.GET_ALL_CATEGORIES_REQUEST });

    axios
      .get('/admin/categories')
      .then((response) => {
        const { categoryList } = response.data;
        dispatch({
          type: categoryConstants.GET_ALL_CATEGORIES_SUCCESS,
          payload: { categories: categoryList },
        });
      })
      .catch((error) => {
        dispatch({
          type: categoryConstants.GET_ALL_CATEGORIES_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addCategory = (form) => {
  return async (dispatch) => {
    dispatch({ type: categoryConstants.ADD_NEW_CATEGORY_REQUEST });

    axios
      .post(`/admin/categories`, form)
      .then((response) => {
        dispatch({
          type: categoryConstants.ADD_NEW_CATEGORY_SUCCESS,
          payload: { category: response.data.category },
        });

        toast.success('New category created!');
      })
      .catch((error) => {
        dispatch({
          type: categoryConstants.ADD_NEW_CATEGORY_FAILURE,
          payload: error,
        });
        toast.error('Category creation failed! Please try later');
      });
  };
};

export const updateCategories = (form) => {
  return async (dispatch) => {
    dispatch({ type: categoryConstants.UPDATE_CATEGORIES_REQUEST });

    axios
      .put(`/admin/categories`, form)
      .then((response) => {
        dispatch({ type: categoryConstants.UPDATE_CATEGORIES_SUCCESS });
        dispatch(getAllCategory());
        toast.success('Categories updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: categoryConstants.UPDATE_CATEGORIES_FAILURE,
          payload: { error },
        });
        toast.error('Categories update failed! Please try later');
      });
  };
};

export const deleteCategories = (ids) => {
  return async (dispatch) => {
    dispatch({ type: categoryConstants.DELETE_CATEGORIES_REQUEST });

    axios
      .delete(`/admin/categories`, { payload: { ids } })
      .then((response) => {
        dispatch({ type: categoryConstants.DELETE_CATEGORIES_SUCCESS });
        dispatch(getAllCategory());
        toast.success('Categories deleted successfully!');
      })
      .catch((error) => {
        dispatch({
          type: categoryConstants.DELETE_CATEGORIES_FAILURE,
          payload: { error },
        });
        toast.error('Categories deletion failed! Please try later');
      });
  };
};

export { getAllCategory };

export const updateProductsByCategory = (form) => {
  return async (dispatch) => {
    dispatch({ type: categoryConstants.UPDATE_PRODUCTS_BY_CATEGORY_REQUEST });

    axios
      .put(`/admin/categories/products`, form)
      .then((response) => {
        dispatch({ type: categoryConstants.UPDATE_PRODUCTS_BY_CATEGORY_SUCCESS });
        dispatch(getAllProduct());
        toast.success('All products updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: categoryConstants.UPDATE_PRODUCTS_BY_CATEGORY_FAILURE,
          payload: { error },
        });
        toast.error('Products update failed! Please try later');
      });
  };
};
