import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../../assets/css/useStyles';
import MyTable from '../../../../../components/MyTable';
import MyTableSkeleton from '../../../../../components/MyTableSkeleton';
import { deleteJournal, updateJournal } from '../../../../../redux/actions/journal.actions';
import { mainColour2, mainColour3 } from '../../../../../utils';
import EditEntryFromUpdateJournalDialogbox from './EditEntryFromUpdateJournalDialogbox';

const UpdateJournalDialogbox = (props) => {
  const classes = useStyles();
  const { journal, voucherNum, updateJournalDialog, setUpdateJournalDialog } = props;
  const dispatch = useDispatch();
  const year = useSelector((state) => state.global.year);
  const [rows, setRows] = useState(journal.entries);
  const [dbTotal, setDbTotal] = useState(0);
  const [crTotal, setCrTotal] = useState(0);
  const [date, setDate] = useState(journal.date ? journal.date : null);

  const [loading, setLoading] = useState(null);

  const [myEntry, setMyEntry] = useState(null);
  const [editEntryDialog, setEditEntryDialog] = useState(false);
  const showEditEntryDialog = (item) => {
    setEditEntryDialog(true);
    setMyEntry(item);
  };

  const columns = [
    { field: 'id', width: 100, hide: true },
    { field: 'Account_Name', width: 200, align: 'left' },
    { field: 'Account_Subname', width: 200, align: 'left' },
    { field: 'Debit', width: 120, align: 'right' },
    { field: 'Credit', width: 120, align: 'right' },
    { field: 'Narration', width: 400, align: 'left' },
    {
      field: 'Actions',
      renderCell: (params) => (
        <ButtonGroup>
          <Button size='small' variant='outlined' onClick={() => showEditEntryDialog(params.row)}>
            <EditIcon style={{ color: mainColour2 }} />
          </Button>
          <Button size='small' onClick={() => deleteEntry(params.row)}>
            <DeleteIcon style={{ color: 'red' }} />
          </Button>
        </ButtonGroup>
      ),
      width: 135,
      align: 'center',
    },
  ];

  const deleteEntry = (row) => {
    setLoading(true);
    setRows(rows.filter((item) => item._id !== row._id));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    const dbTotal = Math.abs(
      Number(
        rows.reduce((a, b) => ({ dbAmount: Number(a.dbAmount) + Number(b.dbAmount) })).dbAmount,
      ),
    ).toFixed(2);
    const crTotal = Number(
      rows.reduce((a, b) => ({ crAmount: Number(a.crAmount) + Number(b.crAmount) })).crAmount,
    ).toFixed(2);
    setDbTotal(dbTotal);
    setCrTotal(crTotal);
  }, [JSON.stringify(rows)]);

  const updateJournalForm = () => {
    if (dbTotal != crTotal) {
      return toast.error('Debit credit mismatch!');
    }
    setUpdateJournalDialog(false);
    const payload = {
      _id: journal._id,
      voucherNum: journal.voucherNum,
      date: date,
      entries: rows.map((row) => {
        delete row['id'];
        return { ...row, accountName: row.accountName._id };
      }),
    };
    dispatch(updateJournal(payload, year));
  };

  /************************Add Entry ******************************/
  const [accountName, setAccountName] = useState('');
  const [accountSubname, setAccountSubname] = useState('');
  const [crAmount, setCrAmount] = useState(0);
  const [dbAmount, setDbAmount] = useState(0);
  const [narration, setNarration] = useState('');

  const accountNameRedux = useSelector((state) => state.accountName);
  const [accountSubnameList, setAccountSubnameList] = useState([]);
  const accountNameList = accountNameRedux.accountNames
    .map((accountName) => accountName.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  useEffect(() => {
    const list = accountNameRedux.accountNames.filter((item) => item.name === accountName);
    const subnameList = list.map((item) => item.subname);
    setAccountSubnameList(subnameList);
  }, [accountName]);

  const handleJournal = () => {
    if (date === '' || date === null) return toast.error('Date is required !');
    const present = new Date(date);
    const from = new Date(`04/01/${year}`);
    const to = new Date(`03/31/${year + 1}`);
    if (present < from || present > to) {
      return toast.error('Invalid Date !');
    }
    if (accountName === '') return toast.error('Account name is required !');

    if (crAmount === 0 && dbAmount === 0) return toast.error('Credit/Debit amount is required !');
    if (crAmount && dbAmount) return toast.error('Either debit / credit amount is required !');
    let accountNameId;
    if (accountSubname === '') {
      accountNameId = accountNameRedux.accountNames.find((item) => item.name === accountName)._id;
    } else {
      accountNameId = accountNameRedux.accountNames.find(
        (item) => item.subname === accountSubname && item.name === accountName,
      )._id;
    }
    const payload = {
      accountName: {
        name: accountName,
        subname: accountSubname,
        _id: accountNameId,
      },
      crAmount: Number(crAmount),
      dbAmount: Number(dbAmount * -1),
      narration: narration,
    };
    setLoading(true);
    setRows((prev) => [...prev, payload]);
    setTimeout(() => {
      setLoading(false);
    }, 500);

    setAccountName('');
    setAccountSubname('');
    setCrAmount(0);
    setDbAmount(0);
  };
  /*************************************************************** */

  const handleDeleteJounal = () => {
    dispatch(deleteJournal({ voucherNum: voucherNum }, year));
    setUpdateJournalDialog(false);
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xl'
        onClose={() => setUpdateJournalDialog(false)}
        aria-labelledby='updateJournalDialogBox'
        open={updateJournalDialog}
      >
        <DialogTitle id='updateAccountDialogBox' onClose={() => setUpdateJournalDialog(false)}>
          Update Journal Details {voucherNum}
          <Button
            onClick={handleDeleteJounal}
            variant='contained'
            style={{ color: 'white', backgroundColor: 'red' }}
          >
            Delete Journal
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='Date'
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            <Grid item xs={12} sm={2}>
              <Autocomplete
                autoHighlight
                id='accountName_select'
                options={accountNameList}
                getOptionLabel={(option) => option}
                value={accountName}
                onChange={(e, value) => setAccountName(value)}
                renderInput={(params) => (
                  <TextField {...params} required label='Account Name' variant='outlined' />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                autoHighlight
                id='accountSubame_select'
                options={accountSubnameList}
                getOptionLabel={(option) => option}
                value={accountSubname}
                onChange={(e, value) => setAccountSubname(value)}
                disabled={accountName === ''}
                renderInput={(params) => (
                  <TextField {...params} label='Account Subname' variant='outlined' />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                type='number'
                disabled={accountName === ''}
                variant='outlined'
                value={dbAmount}
                label='Dr. Amount'
                placeholder='Dr. Amount'
                onChange={(e) => setDbAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                type='number'
                disabled={accountName === ''}
                variant='outlined'
                value={crAmount}
                label='Cr. Amount'
                placeholder='Cr. Amount'
                onChange={(e) => setCrAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                variant='outlined'
                value={narration}
                label='Narration'
                placeholder='Narration'
                onChange={(e) => setNarration(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button
                onClick={handleJournal}
                variant='contained'
                className={classes.externalLinks}
                style={{ height: '56px' }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          {rows.length > 0 && !loading ? (
            <div>
              <MyTable
                rows={rows.map((row) => ({
                  ...row,
                  id: row._id ? row._id : Math.random() * 1000000000,
                  Account_Name: row.accountName.name,
                  Account_Subname: row.accountName.subname,
                  dbAmount: Math.abs(row.dbAmount),
                  Debit: Math.abs(row.dbAmount),
                  Credit: row.crAmount,
                  Narration: row.narration,
                }))}
                columns={columns}
                rowHeight={60}
                hideFooter={true}
                showSearch={false}
              />
            </div>
          ) : (
            <MyTableSkeleton />
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ fontWeight: 600, color: 'red' }}>{dbTotal}</Typography>
            <Button
              onClick={updateJournalForm}
              variant='contained'
              style={{ margin: '0 10px', backgroundColor: mainColour3, color: 'white' }}
            >
              Save
            </Button>
            <Typography style={{ fontWeight: 600, color: 'green' }}>{crTotal}</Typography>
          </div>
          <Typography
            style={{
              fontWeight: 600,
              color: Number(Number(crTotal) - Number(dbTotal)) >= 0 ? 'green' : 'red',
            }}
          >
            {Number(Math.abs(Number(crTotal) - Number(dbTotal))).toFixed(2)}
          </Typography>
        </DialogActions>
      </Dialog>
      {editEntryDialog && (
        <EditEntryFromUpdateJournalDialogbox
          journal={myEntry}
          loading={loading}
          setLoading={setLoading}
          rows={rows}
          setRows={setRows}
          columns={columns}
          editEntryDialog={editEntryDialog}
          setEditEntryDialog={setEditEntryDialog}
        />
      )}
    </>
  );
};

export default UpdateJournalDialogbox;
