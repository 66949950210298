import { accountGroupHeadConstants } from '../actions/constants';

const initState = {
  accountGroupHeads: [],
  loading: false,
  error: null,
};

const accountGroupHeadReducers = (state = initState, action) => {
  switch (action.type) {
    case accountGroupHeadConstants.GET_ALL_ACCOUNT_GROUP_HEADS_SUCCESS:
      state = {
        ...state,
        accountGroupHeads: action.payload.accountGroupHeads,
      };
      break;
    case accountGroupHeadConstants.ADD_NEW_ACCOUNT_GROUP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountGroupHeadConstants.ADD_NEW_ACCOUNT_GROUP_SUCCESS: {
      const accountGroupHead = action.payload.accountGroupHead; //new category to be created
      state.accountGroupHeads.push(accountGroupHead);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case accountGroupHeadConstants.ADD_NEW_ACCOUNT_GROUP_FAILURE:
      state = {
        ...initState,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      break;
  }

  return state;
};

export default accountGroupHeadReducers;
