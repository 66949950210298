import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import CustomInput from '../../../components/CustomInput';
import { updateAccount } from '../../../redux/actions';

const UpdateAccount = (props) => {
  const classes = useStyles();
  const { myAccount, updateAccountDialog, setUpdateAccountDialog } = props;
  const dispatch = useDispatch();

  const accountNameRedux = useSelector((state) => state.accountName);
  const accountGroupHeadRedux = useSelector((state) => state.accountGroupHead);
  const accountHeadRedux = useSelector((state) => state.accountHead);
  const pnlHeadRedux = useSelector((state) => state.pnlHead);

  let accountNameList = accountNameRedux.accountNames.map((accountName) => accountName.name);
  accountNameList = accountNameList.filter(
    (item, index) => accountNameList.indexOf(item) === index,
  );

  const accountGroupHeadList = accountGroupHeadRedux.accountGroupHeads.map(
    (accountGroupHead) => accountGroupHead.name,
  );
  const accountHeadList = accountHeadRedux.accountHeads.map((accountHead) => accountHead.name);
  const pnlHeadList = pnlHeadRedux.pnlHeads.map((pnlHead) => pnlHead.name);

  const [name, setName] = useState(myAccount.name);
  const [subname, setSubname] = useState(myAccount.subname);
  const [accountHead, setAccountHead] = useState(myAccount.accountHead.name);
  const [accountGroupHead, setAccountGroupHead] = useState(myAccount.accountGroupHead.name);
  const [pnlHead, setPnlHead] = useState(myAccount.pnlHead.name);

  const updateAccountForm = () => {
    setUpdateAccountDialog(false);

    const accountGroupHeadId = accountGroupHeadRedux.accountGroupHeads.find(
      (item) => item.name === accountGroupHead,
    )._id;
    const accountHeadId = accountHeadRedux.accountHeads.find(
      (item) => item.name === accountHead,
    )._id;
    const pnlHeadId = pnlHeadRedux.pnlHeads.find((item) => item.name === pnlHead)._id;

    const payload = {
      _id: myAccount._id,
      name: name,
      subname: subname,
      accountHead: accountHeadId,
      accountGroupHead: accountGroupHeadId,
      pnlHead: pnlHeadId,
    };

    dispatch(updateAccount(payload));
  };
  return (
    <Dialog
      fullWidth
      onClose={() => setUpdateAccountDialog(false)}
      aria-labelledby='updateAccountDialogBox'
      open={updateAccountDialog}
    >
      <DialogTitle id='updateAccountDialogBox' onClose={() => setUpdateAccountDialog(false)}>
        Update Account
      </DialogTitle>
      <DialogContent dividers>
        {myAccount && (
          <>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={6} sm={6}>
                {/* <CustomInput
                  fullWidth
                  label="Account Name"
                  placeholder="Account Name"
                  value={name}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                /> */}
                <Autocomplete
                  autoHighlight
                  id='account_name_select'
                  options={accountNameList}
                  getOptionLabel={(option) => option}
                  value={name}
                  onChange={(e, value) => setName(value)}
                  style={{ marginBottom: 8 }}
                  renderInput={(params) => (
                    <TextField {...params} label='Account Name' variant='outlined' />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <CustomInput
                  fullWidth
                  label='Account Subname'
                  placeholder='Account Subname'
                  value={subname}
                  type='text'
                  onChange={(e) => setSubname(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    autoHighlight
                    id='account_group_head_select'
                    options={accountGroupHeadList}
                    getOptionLabel={(option) => option}
                    value={accountGroupHead}
                    onChange={(e, value) => setAccountGroupHead(value)}
                    renderInput={(params) => (
                      <TextField {...params} label='Account Group Head' variant='outlined' />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    autoHighlight
                    id='account_head__select'
                    options={accountHeadList}
                    getOptionLabel={(option) => option}
                    value={accountHead}
                    onChange={(e, value) => setAccountHead(value)}
                    renderInput={(params) => (
                      <TextField {...params} label='Acct Head' variant='outlined' />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    autoHighlight
                    id='pnl_head__select'
                    options={pnlHeadList}
                    getOptionLabel={(option) => option}
                    value={pnlHead}
                    onChange={(e, value) => setPnlHead(value)}
                    renderInput={(params) => (
                      <TextField {...params} label='PNL Head' variant='outlined' />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <Button autoFocus onClick={updateAccountForm} className={classes.button2}>
                Save changes
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAccount;
