import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const MyTableSkeleton = () => {
  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='30px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='40px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='50px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='120px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='120px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='120px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='120px' />
      </Grid>
      <Grid item>
        <Skeleton animation='wave' variant='rect' height='120px' />
      </Grid>
    </Grid>
  );
};

export default MyTableSkeleton;
