import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import useStyles from '../../../../assets/css/useStyles';
import ButtonsGroupAccounts from '../../../../components/ButtonsGroupAccounts/index';
import MyTable from '../../../../components/MyTable';
import MyTableSkeleton from '../../../../components/MyTableSkeleton';
import {
  ACCOUNT_GROUP_HEAD,
  ASSETS_ID,
  BALANCE_SHEET_ID,
  CAPITAL_ID,
  CASH,
  EXPENSES_ID,
  LIABILITIES_ID,
  OPENING_STOCK_TRD_ID,
  PAYMENT,
  RECEIVED,
  REVENUE_ID,
} from '../../../../helpers/constants';
import findOpeningBal from '../../../../helpers/findOpeningBal';
import formatDate2 from '../../../../helpers/formatDate2';
import { toIndianNumber } from '../../../../helpers/toIndianNumber';
import Layout from '../../../../layouts/Layout';
import { getTrialBalance, saveTrialBalance } from '../../../../redux/actions';
import { mainColour2 } from '../../../../utils';
import TrialBalanceLayout from './TrialBalanceLayout';

const TrialBalance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const year = useSelector((state) => state.global.year);
  const trialBalance = useSelector((state) => state.trialBalance);
  const accountNameRedux = useSelector((state) => state.accountName);
  const accountGroupHeadRedux = useSelector((state) => state.accountGroupHead);
  const accountHeadRedux = useSelector((state) => state.accountHead);
  const pnlHeadRedux = useSelector((state) => state.pnlHead);

  const [fromDate, setFromDate] = useState(trialBalance.fromDate);
  const [toDate, setToDate] = useState(trialBalance.toDate);

  const docRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => docRef.current,
    documentTitle: `TrialBalance-${formatDate2(trialBalance.fromDate)} - ${formatDate2(
      trialBalance.toDate,
    )}`,
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    // removeAfterPrint: true
  });

  const handleTrialBalance = () => {
    const start = new Date(`04/01/${year}`);
    const end = new Date(`03/31/${year + 1}`);

    if (!fromDate) return toast.error('FROM date is required !');
    if (!toDate) return toast.error('TO date is required !');

    if (fromDate < start || fromDate > end) {
      return toast.error('Invalid FROM Date !');
    }

    if (toDate < start || toDate > end) {
      return toast.error('Invalid TO date !');
    }
    if (fromDate > toDate) {
      return toast.error('Please check the dates !');
    }
    const payload = {
      fromDate: fromDate,
      toDate: toDate,
    };
    dispatch(getTrialBalance(payload));
  };

  const columnsGroupwise = useMemo(() => {
    return [
      { field: 'id', width: 100 },
      // { field: 'Date', type:'dateTime',valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(undefined, {timeZone: 'Asia/Kolkata'}),width: 175, align: 'left' },
      // { field: 'Date', type: 'dateTime', width: 200, align: 'left' },
      { field: 'Account_Name', width: 200, align: 'left' },
      { field: 'OB_Debit', type: 'number', width: 150, align: 'right' },
      { field: 'OB_Credit', type: 'number', width: 150, align: 'right' },
      { field: 'TRN_Debit', type: 'number', width: 150, align: 'right' },
      { field: 'TRN_Credit', type: 'number', width: 150, align: 'right' },
      { field: 'CB_Debit', type: 'number', width: 150, align: 'right' },
      { field: 'CB_Credit', type: 'number', width: 150, align: 'right' },
      // { field: 'Account_Head', width: 150, align: 'right' },
      // { field: 'Account_Group_Head', width: 150, align: 'right' },
      // { field: 'Pnl_Head', width: 150, align: 'right' }
    ];
  }, []);
  // Groupwise record-----------------------------------------------------------------------------------------
  let uid = 0;
  const rows = useMemo(() => {
    let rows = [];
    trialBalance?.cashes?.map((item) => {
      uid += 1;
      const accountHead = accountHeadRedux.accountHeads.find(
        (element) => element._id === item.accountName.accountHead,
      );
      const accountGroupHead = accountGroupHeadRedux.accountGroupHeads.find(
        (element) => element._id === item.accountName.accountGroupHead,
      );
      const pnlHead = pnlHeadRedux.pnlHeads.find(
        (element) => element._id === item.accountName.pnlHead,
      );

      return rows.push({
        id: uid,
        // item,
        DateFull: item.date,
        Voucher: item.voucherNum,
        Opening_Bal: findOpeningBal(item.accountName.openingBal, year),
        Account_Name: item.accountName.name,
        Account_Subname: item.accountName.subname,
        Debit: item.type === PAYMENT ? Math.abs(item.amount).toFixed(2) : Number(0).toFixed(2),
        Credit: item.type === RECEIVED ? Math.abs(item.amount).toFixed(2) : Number(0).toFixed(2),
        Narration: item.narration,
        Account_Head: accountHead,
        Account_Group_Head: accountGroupHead,
        Pnl_Head: pnlHead,
      });
    });

    trialBalance?.journals?.map((item) => {
      const entries = item.entries;
      return entries.map((entry) => {
        const accountHead = accountHeadRedux.accountHeads.find(
          (item) => item._id === entry.accountName.accountHead,
        );
        const accountGroupHead = accountGroupHeadRedux.accountGroupHeads.find(
          (item) => item._id === entry.accountName.accountGroupHead,
        );
        const pnlHead = pnlHeadRedux.pnlHeads.find(
          (item) => item._id === entry.accountName.pnlHead,
        );
        uid += 1;
        // console.log("entry.accountName.name => ",entry.accountName.name)
        // console.log("opening bal => ",findOpeningBal(entry.accountName.openingBal, year))
        rows.push({
          id: uid,
          DateFull: item.date,
          // Date: formatDate2(item.date),
          Voucher: item.voucherNum,
          Account_Name: entry.accountName.name,
          Account_Subname: entry.accountName.subname,
          Opening_Bal: findOpeningBal(entry.accountName.openingBal, year),
          Account_Head: accountHead,
          Account_Group_Head: accountGroupHead,
          Pnl_Head: pnlHead,
          Debit: Math.abs(entry.dbAmount).toFixed(2),
          Credit: entry.crAmount.toFixed(2),
          Narration: entry.narration,
        });
      });
    });
    return rows;
  }, [trialBalance.cashes, trialBalance.journals]);
  //--------------------------------------------------------------------------------------------------------------
  // Group by account name

  const groupByAccountName =
    rows.length > 0 &&
    rows.reduce((group, row) => {
      const { Account_Name } = row;
      group[Account_Name] = group[Account_Name] ?? [];
      group[Account_Name].push(row);
      return group;
    }, {});

  const rowsGroupwise = useMemo(() => {
    let rowsGroupwise = [];
    let uuid = 0;

    //add cash to the rowsGroupwise (special case)-----------------
    let cashDebitTotal = 0,
      cashCreditTotal = 0;
    let cashAccountHead = '',
      cashAccountGroupHead = '',
      cashPnlHead = '';
    trialBalance.cashes.length > 0 &&
      trialBalance.cashes.map((item) => {
        if (cashAccountHead === '' && cashAccountGroupHead === '' && cashPnlHead === '') {
          cashAccountHead = accountHeadRedux.accountHeads.find(
            (element) => element._id === ASSETS_ID,
          );
          cashAccountGroupHead = accountGroupHeadRedux.accountGroupHeads.find(
            (element) => element._id === ACCOUNT_GROUP_HEAD.currentAssets.id,
          );
          cashPnlHead = pnlHeadRedux.pnlHeads.find((element) => element._id === BALANCE_SHEET_ID);
        }
        if (item.type === RECEIVED) {
          cashDebitTotal += Math.abs(item.amount);
        } else {
          cashCreditTotal += Math.abs(item.amount);
        }
      });

    const cashAccount = accountNameRedux.accountNames.find((item) => item.name === 'Cash');
    let cashOpeningBal = cashAccount
      ? Number(findOpeningBal(cashAccount.openingBal, year)).toFixed(2)
      : Number(0).toFixed(2);

    trialBalance.cashes.length > 0 &&
      rowsGroupwise.push({
        id: ++uuid,
        Account_Name: CASH,
        OB_Debit: cashOpeningBal,
        OB_Credit: Number(0).toFixed(2),
        TRN_Debit: cashDebitTotal > 0 ? Math.abs(cashDebitTotal).toFixed(2) : Number(0).toFixed(2),
        TRN_Credit:
          cashCreditTotal > 0 ? Math.abs(cashCreditTotal).toFixed(2) : Number(0).toFixed(2),
        CB_Debit: Number(Number(cashOpeningBal) + cashDebitTotal - cashCreditTotal).toFixed(2),
        CB_Credit: Number(0).toFixed(2),
        Account_Head: cashAccountHead,
        Account_Group_Head: cashAccountGroupHead,
        Pnl_Head: cashPnlHead,
      });
    //-------------------------------------------------------------
    for (const [key] of Object.entries(groupByAccountName)) {
      // console.log(groupByAccountName[key])

      let AccountName = groupByAccountName[key][0].Account_Name;
      let AccountHead = groupByAccountName[key][0].Account_Head;
      let AccountGroupHead = groupByAccountName[key][0].Account_Group_Head;
      let PnlHead = groupByAccountName[key][0].Pnl_Head;

      /*opening balance*************************************************** */
      let openingBalDebit = 0;
      let openingBalCredit = 0;
      const filteredGroup = accountNameRedux.accountNames.filter(
        (element) => element.name === AccountName,
      );

      filteredGroup.map((element) => {
        if (element.pnlHead._id === BALANCE_SHEET_ID) {
          //balancesheet
          if (element.accountHead._id == ASSETS_ID || element.accountHead._id == EXPENSES_ID) {
            //assets,expenses
            openingBalDebit += Number(findOpeningBal(element.openingBal, year));
          } else if (
            element.accountHead._id == LIABILITIES_ID ||
            element.accountHead._id == CAPITAL_ID ||
            element.accountHead._id == REVENUE_ID
          ) {
            //liabilities,capital,revenue
            openingBalCredit += Number(findOpeningBal(element.openingBal, year));
          }
        } else {
          openingBalDebit += 0;
          openingBalCredit += 0;
        }
      });

      /********************************************************** */
      const debitTotalByGroup = Number(
        groupByAccountName[key].reduce((a, b) => ({ Debit: Number(a.Debit) + Number(b.Debit) }))
          .Debit,
      ).toFixed(2);
      const creditTotalByGroup = Number(
        groupByAccountName[key].reduce((a, b) => ({ Credit: Number(a.Credit) + Number(b.Credit) }))
          .Credit,
      ).toFixed(2);

      let cb_debit = 0,
        cb_credit = 0;
      if (AccountHead._id == ASSETS_ID || AccountHead._id == EXPENSES_ID) {
        //assets,expenses
        cb_debit = Number(
          Number(openingBalDebit) + Number(debitTotalByGroup) - Number(creditTotalByGroup),
        ).toFixed(2);
      } else if (
        AccountHead._id == LIABILITIES_ID ||
        AccountHead._id == CAPITAL_ID ||
        AccountHead._id == REVENUE_ID
      ) {
        //liabilities,capital,revenue
        cb_credit = Number(
          Number(openingBalCredit) + Number(creditTotalByGroup) - Number(debitTotalByGroup),
        ).toFixed(2);
      }
      uuid++;
      rowsGroupwise.push({
        id: uuid + 1,
        Account_Name: AccountName,
        // Account_Subname: key,
        OB_Debit: Number(openingBalDebit).toFixed(2),
        OB_Credit: Number(openingBalCredit).toFixed(2),
        TRN_Debit: debitTotalByGroup,
        TRN_Credit: creditTotalByGroup,
        CB_Debit: Number(cb_debit).toFixed(2),
        CB_Credit: Number(cb_credit).toFixed(2),
        Account_Head: AccountHead,
        Account_Group_Head: AccountGroupHead,
        Pnl_Head: PnlHead,
      });
    }

    const groupByNotPresentAccountName = accountNameRedux.accountNames.reduce((group, item) => {
      const present =
        rowsGroupwise.filter((ele) => ele.Account_Name === item.name).length !== 0 ? true : false;
      if (present === false) {
        const { name } = item;
        group[name] = group[name] ?? [];
        group[name].push(item);
      }
      return group;
    }, {});
    for (const [key] of Object.entries(groupByNotPresentAccountName)) {
      // console.log(groupByNotPresentAccountName[key])

      let AccountName = groupByNotPresentAccountName[key][0].name;
      let AccountHead = groupByNotPresentAccountName[key][0].accountHead;
      let AccountGroupHead = groupByNotPresentAccountName[key][0].accountGroupHead;
      let PnlHead = groupByNotPresentAccountName[key][0].pnlHead;

      /*opening balance*************************************************** */
      let openingBalDebit = 0;
      let openingBalCredit = 0;
      const filteredGroup = accountNameRedux.accountNames.filter(
        (element) => element.name === AccountName,
      );
      filteredGroup.map((element) => {
        if (element._id == OPENING_STOCK_TRD_ID) {
          //Opening Stock Trd (not present in balancesheet, taken from Closing Stock BS)
          openingBalDebit += Number(findOpeningBal(element.openingBal, year));
        }
        if (element.pnlHead._id === BALANCE_SHEET_ID) {
          //balancesheet
          if (element.accountHead._id == ASSETS_ID || element.accountHead._id == EXPENSES_ID) {
            //assets,expenses
            openingBalDebit += Number(findOpeningBal(element.openingBal, year));
          } else if (
            element.accountHead._id == LIABILITIES_ID ||
            element.accountHead._id == CAPITAL_ID ||
            element.accountHead._id == REVENUE_ID
          ) {
            //liabilities,capital,revenue
            openingBalCredit += Number(findOpeningBal(element.openingBal, year));
          }
        }
      });
      /********************************************************** */
      const debitTotalByGroup = Number(0).toFixed(2);
      const creditTotalByGroup = Number(0).toFixed(2);

      let cb_debit = 0,
        cb_credit = 0;
      if (AccountHead._id == ASSETS_ID || AccountHead._id == EXPENSES_ID) {
        //assets,expenses
        cb_debit = Number(
          Number(openingBalDebit) + Number(debitTotalByGroup) - Number(creditTotalByGroup),
        ).toFixed(2);
      } else if (
        AccountHead._id == LIABILITIES_ID ||
        AccountHead._id == CAPITAL_ID ||
        AccountHead._id == REVENUE_ID
      ) {
        //liabilities,capital,revenue
        cb_credit = Number(
          Number(openingBalCredit) + Number(creditTotalByGroup) - Number(debitTotalByGroup),
        ).toFixed(2);
      }
      uuid++;
      rowsGroupwise.push({
        id: uuid + 1,
        Account_Name: AccountName,
        // Account_Subname: key,
        OB_Debit: Number(openingBalDebit).toFixed(2),
        OB_Credit: Number(openingBalCredit).toFixed(2),
        TRN_Debit: debitTotalByGroup,
        TRN_Credit: creditTotalByGroup,
        CB_Debit: Number(cb_debit).toFixed(2),
        CB_Credit: Number(cb_credit).toFixed(2),
        Account_Head: AccountHead,
        Account_Group_Head: AccountGroupHead,
        Pnl_Head: PnlHead,
      });
    }
    const totalOpeningBalanceDebit =
      rowsGroupwise.length > 0
        ? rowsGroupwise
            .reduce((a, b) => ({ OB_Debit: Number(a.OB_Debit) + Number(b.OB_Debit) }))
            .OB_Debit.toFixed(2)
        : Number(0).toFixed(2);
    const totalOpeningBalanceCredit =
      rowsGroupwise.length > 0
        ? rowsGroupwise
            .reduce((a, b) => ({ OB_Credit: Number(a.OB_Credit) + Number(b.OB_Credit) }))
            .OB_Credit.toFixed(2)
        : Number(0).toFixed(2);
    const totalDebit =
      rowsGroupwise.length > 0
        ? rowsGroupwise.reduce((a, b) => ({ TRN_Debit: Number(a.TRN_Debit) + Number(b.TRN_Debit) }))
            .TRN_Debit
        : Number(0).toFixed(2);
    const totalCredit =
      rowsGroupwise.length > 0
        ? rowsGroupwise.reduce((a, b) => ({
            TRN_Credit: Number(a.TRN_Credit) + Number(b.TRN_Credit),
          })).TRN_Credit
        : Number(0).toFixed(2);
    const debitBalance =
      rowsGroupwise.length > 0
        ? rowsGroupwise.reduce((a, b) => ({ CB_Debit: Number(a.CB_Debit) + Number(b.CB_Debit) }))
            .CB_Debit
        : Number(0).toFixed(2);
    const creditBalance =
      rowsGroupwise.length > 0
        ? rowsGroupwise.reduce((a, b) => ({ CB_Credit: Number(a.CB_Credit) + Number(b.CB_Credit) }))
            .CB_Credit
        : Number(0).toFixed(2);

    // sort according to account names
    rowsGroupwise = rowsGroupwise.sort((a, b) =>
      a.Account_Name > b.Account_Name ? 1 : b.Account_Name > a.Account_Name ? -1 : 0,
    );

    //replace the ids of rows into a sorted numbers
    rowsGroupwise = rowsGroupwise.map((item, idx) => {
      return { ...item, id: idx + 1 };
    });

    rowsGroupwise.length > 0 &&
      rowsGroupwise.push({
        id: 'T',
        Account_Name: 'Total',
        OB_Debit: Number(totalOpeningBalanceDebit).toFixed(2),
        OB_Credit: Number(totalOpeningBalanceCredit).toFixed(2),
        TRN_Debit: Number(totalDebit).toFixed(2),
        TRN_Credit: Number(totalCredit).toFixed(2),
        CB_Debit: Number(debitBalance).toFixed(2),
        CB_Credit: Number(creditBalance).toFixed(2),
      });

    return rowsGroupwise;
  }, [trialBalance.cashes, trialBalance.journals, rows]);

  // End-------------------------------------------------------------------------------------------------------

  // Balance differnce between total debit amount and total credit amount----------------------------------------------------------
  const [openingBalDiff, setOpeningBalDiff] = useState(0);
  const [totallingDiff, setTotallingDiff] = useState(0);
  const [balancingDiff, setBalancingDiff] = useState(0);
  const [crdrOpeningBal, setCrdrOpeningBal] = useState('');
  const [crdrTotalling, setCrdrTotalling] = useState('');
  const [crdrBalancing, setCrdrBalancing] = useState('');

  useEffect(() => {
    if (
      trialBalance.loading ||
      (trialBalance.cashes.length === 0 && trialBalance.journals.length === 0)
    ) {
      return;
    }

    const crdrOpeningBal =
      rowsGroupwise.at(-1).OB_Debit - rowsGroupwise.at(-1).OB_Credit >= 0 ? 'Dr' : 'Cr';
    const crdrTotalling =
      rowsGroupwise.at(-1).TRN_Debit - rowsGroupwise.at(-1).TRN_Credit >= 0 ? 'Dr' : 'Cr';
    const crdrBalancing =
      rowsGroupwise.at(-1).CB_Debit - rowsGroupwise.at(-1).CB_Credit >= 0 ? 'Dr' : 'Cr';

    setOpeningBalDiff(
      Math.abs(Number(rowsGroupwise.at(-1).OB_Debit - rowsGroupwise.at(-1).OB_Credit).toFixed(2)),
    );
    setTotallingDiff(
      Math.abs(Number(rowsGroupwise.at(-1).TRN_Debit - rowsGroupwise.at(-1).TRN_Credit).toFixed(2)),
    );
    setBalancingDiff(
      Math.abs(Number(rowsGroupwise.at(-1).CB_Debit - rowsGroupwise.at(-1).CB_Credit).toFixed(2)),
    );

    setCrdrOpeningBal(crdrOpeningBal);
    setCrdrTotalling(crdrTotalling);
    setCrdrBalancing(crdrBalancing);
  }, [trialBalance.loading]);

  const handleSave = () => {
    const payload = {
      rowsGroupwise: rowsGroupwise,
      fromDate: fromDate,
      toDate: toDate,
    };
    dispatch(saveTrialBalance(payload));
  };

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid
          item
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant='h4' className={classes.heading}>
            TRIAL BALANCE
          </Typography>
          <Button variant='contained' className={classes.printButton} onClick={handlePrint}>
            <Print />
          </Button>
          {(trialBalance.cashes.length > 0 || trialBalance.journals.length > 0) && (
            <Button
              // className={classes.externalLinks}
              style={{ marginLeft: '20px', backgroundColor: 'orange', color: 'white' }}
              variant='contained'
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </Grid>

        <Grid item>
          <ButtonsGroupAccounts
            btn1='Account Master'
            btn2='Cash'
            btn3='Journal'
            link1='/accounts-accountmaster'
            link2='/accounts-transactions-cash'
            link3='/accounts-transactions-journal'
          />
        </Grid>
      </Grid>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='From Date'
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='To Date'
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            onClick={handleTrialBalance}
            variant='contained'
            style={{ height: '56px', backgroundColor: mainColour2, color: 'white' }}
          >
            Get Report
          </Button>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            {!trialBalance.loading ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' className={classes.heading}>
                  OB Diff = Rs.{toIndianNumber(openingBalDiff.toFixed(2))}{' '}
                  {openingBalDiff !== 0 && crdrOpeningBal}
                </Typography>
                <Typography variant='h5' className={classes.heading}>
                  TRN Diff = Rs.{toIndianNumber(totallingDiff.toFixed(2))}{' '}
                  {totallingDiff !== 0 && crdrTotalling}
                </Typography>
                <Typography variant='h5' className={classes.heading}>
                  CB Diff = Rs.{toIndianNumber(balancingDiff.toFixed(2))}{' '}
                  {balancingDiff !== 0 && crdrBalancing}
                </Typography>
              </div>
            ) : (
              <Typography variant='h4' className={classes.heading}>
                BALANCE = Rs.0.00
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <div style={{ display: 'none' }}>
        <TrialBalanceLayout ref={docRef} rows={rowsGroupwise} columns={columnsGroupwise} />
      </div>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {!trialBalance.loading ? (
            rowsGroupwise.length > 0 && (
              <MyTable
                rows={rowsGroupwise}
                columns={columnsGroupwise}
                rowHeight={60}
                showSearch={false}
              />
            )
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TrialBalance;
