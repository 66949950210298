import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import React, { useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import CatCard from '../../../components/CatCard';
import Layout from '../../../layouts/Layout';
import {
  addCategory,
  deleteCategories,
  updateCategories,
  updateProductsByCategory,
} from '../../../redux/actions';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from '../../../assets/css/useStyles';
import BackdropLoading from '../../../components/BackdropLoading';
import replaceImageUrl from '../../../helpers/replaceImageUrl';
import { mainColour1, mainColour2 } from '../../../utils';
import AddCategoryDialog from './components/AddCategoryDialog';
import DeleteCategoriesDialog from './components/DeleteCategoriesDialog';
import UpdateCategoriesDialog from './components/UpdateCategoriesDialog';
import UpdateProductsByCategoryDialog from './components/UpdateProductsByCategoryDialog';

const Categories = () => {
  const classes = useStyles();
  const category = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categoryHSN, setCategoryHSN] = useState('00000000');
  const [categoryGST, setCategoryGST] = useState(0);
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [categoryImage, setCategoryImage] = useState('');

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);
  const [expandedArray, setExpandedArray] = useState([]);
  const [updateCategoryDialog, setUpdateCategoryDialog] = useState(false);
  const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
  const [updateProductsByCategoryDialog, setUpdateProductsByCategoryDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    const form = new FormData();
    form.append('name', categoryName);
    form.append('hsnCode', categoryHSN);
    form.append('gst', categoryGST);
    form.append('parentId', parentCategoryId);
    form.append('categoryImage', categoryImage);
    if (categoryName === '') {
      toast.error('Name is required !');
      return;
    }
    if (categoryHSN === '') {
      toast.error('HSN is required !');
      return;
    }
    if (categoryGST === '') {
      toast.error('GST % is required !');
      return;
    }
    dispatch(addCategory(form));

    setOpen(false);
  };
  const handleCategoryImage = (e) => {
    setCategoryImage(e.target.files[0]);
  };

  const createCategoryList = (categories, options = []) => {
    for (let category of categories) {
      options.push({
        value: category._id,
        name: category.name,
        parentId: category.parentId,
        type: category.type,
        image: category.categoryImage,
      });
      if (category.children.length > 0) {
        createCategoryList(category.children, options);
      }
    }

    return options;
  };

  const categoryList = createCategoryList(category.categories);

  const renderCategories = (categories) => {
    let myCategories = [];
    for (let category of categories) {
      myCategories.push({
        label: category.name,
        value: category._id, //to be stored in checked state
        children: category.children.length > 0 && renderCategories(category.children),
      });
    }
    return myCategories;
  };

  const handleCategoryInput = (key, value, index, type) => {
    if (type === 'checked') {
      const updatedCheckedArray = checkedArray.map((item, _index) =>
        index === _index ? { ...item, [key]: value } : item,
      );
      setCheckedArray(updatedCheckedArray);
    } else if (type === 'expanded') {
      const updatedExpandedArray = expandedArray.map((item, _index) =>
        index === _index ? { ...item, [key]: value } : item,
      );
      setExpandedArray(updatedExpandedArray);
    }
  };

  const setCheckedAndExpandedArrays = () => {
    const categories = createCategoryList(category.categories);

    const checkedArray = [];
    checked.length > 0 &&
      checked.forEach((categoryId) => {
        const category = categories.find((category) => categoryId === category.value);

        category && checkedArray.push(category);
      });

    const expandedArray = [];
    expanded.length > 0 &&
      expanded.forEach((categoryId) => {
        const category = categories.find((category) => categoryId === category.value);
        category && expandedArray.push(category);
      });

    setCheckedArray(checkedArray);
    setExpandedArray(expandedArray);
  };

  const updateCategoryFun = () => {
    setCheckedAndExpandedArrays();
    setUpdateCategoryDialog(true);
  };

  const updateCategoriesForm = () => {
    setUpdateCategoryDialog(false);
    const form = new FormData();
    checkedArray.forEach((item) => {
      form.append('_id', item.value);
      form.append('name', item.name);
      form.append('parentId', item.parentId ? item.parentId : '');
      form.append('type', item.type);
    });
    dispatch(updateCategories(checkedArray));
  };

  /********************************************************************************************************* */
  const [offer, setOffer] = useState([]);
  const handleOffer = (e, idx) => {
    let newArr = [...offer];
    newArr[idx] = e.target.value;
    setOffer(newArr);
  };
  const updateProductsByCategoryForm = () => {
    setUpdateProductsByCategoryDialog(false);
    const form = new FormData();
    checkedArray.forEach((item, idx) => {
      form.append('_id', item.value);
      form.append('offer', offer[idx]);
      item.offer = offer[idx];
    });

    dispatch(updateProductsByCategory(checkedArray));
  };
  const updateCategoryFun2 = () => {
    setCheckedAndExpandedArrays();
    setUpdateProductsByCategoryDialog(true);
  };
  /********************************************************************************************************* */

  const deleteCategoryFun = () => {
    setCheckedAndExpandedArrays();
    setDeleteCategoryDialog(true);
  };

  const deleteCategoriesForm = () => {
    setDeleteCategoryDialog(false);
  };
  const deleteCategoriesYes = () => {
    const checkedIdsArray = checkedArray.map((item) => ({ _id: item.value }));

    if (checkedIdsArray.length > 0) {
      dispatch(deleteCategories(checkedIdsArray));
      setDeleteCategoryDialog(false);
    }
  };

  return (
    <Layout sidebar>
      {category.loading ? (
        <BackdropLoading loading={category.loading} />
      ) : (
        <>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            <Grid item>
              <Typography variant='h4' className={classes.heading}>
                CATEGORIES
              </Typography>
            </Grid>
            <Grid item>
              <ButtonGroup variant='contained' aria-label='outlined primary button group'>
                <Button
                  className={classes.button1}
                  style={{ color: mainColour2 }}
                  onClick={handleClickOpen}
                >
                  <AddCircleIcon style={{ color: mainColour2 }} /> Add
                </Button>
                <Button
                  className={classes.button1}
                  style={{ color: mainColour2 }}
                  onClick={updateCategoryFun}
                >
                  <EditIcon style={{ color: mainColour2 }} /> Update
                </Button>
                <Button
                  className={classes.button1}
                  style={{ color: mainColour2 }}
                  onClick={deleteCategoryFun}
                >
                  <DeleteIcon style={{ color: mainColour2 }} /> Delete
                </Button>
              </ButtonGroup>

              <Grid item>
                <ButtonGroup
                  fullWidth
                  variant='contained'
                  aria-label='outlined primary button group'
                >
                  <Button
                    className={classes.button1}
                    style={{ color: mainColour2 }}
                    onClick={updateCategoryFun2}
                  >
                    <EditIcon style={{ color: mainColour2 }} /> Offers
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            <Grid item md={12} xs={12}>
              <CheckboxTree
                nodes={renderCategories(category.categories)}
                checked={checked}
                expanded={expanded}
                onCheck={(checked) => setChecked(checked)}
                onExpand={(expanded) => setExpanded(expanded)}
                icons={{
                  check: <CheckBoxIcon style={{ color: mainColour2 }} />,
                  uncheck: <CheckBoxOutlinedIcon style={{ color: mainColour2 }} />,
                  halfCheck: <IndeterminateCheckBoxIcon style={{ color: mainColour1 }} />,
                  expandClose: <KeyboardArrowRightOutlinedIcon style={{ color: mainColour2 }} />,
                  expandOpen: <KeyboardArrowDownOutlinedIcon style={{ color: mainColour2 }} />,
                  showExpandAll: true,
                  showNodeIcon: true,
                  expandAll: <KeyboardArrowDownOutlinedIcon style={{ color: mainColour2 }} />,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction='row' style={{ marginTop: '20px' }}>
            {categoryList.map((category) => {
              if (category.image && category.image !== '') {
                let slug = category.name.replaceAll(' ', '-');
                const image = replaceImageUrl(category.image);
                return (
                  <Grid item key={slug} md={3}>
                    <CatCard name={category.name} image={image} />
                  </Grid>
                );
              }
            })}
          </Grid>

          <AddCategoryDialog
            open={open}
            onClose={() => setOpen(false)}
            onClick={handleClose}
            categoryName={categoryName}
            setCategoryName={setCategoryName}
            categoryHSN={categoryHSN}
            setCategoryHSN={setCategoryHSN}
            categoryGST={categoryGST}
            setCategoryGST={setCategoryGST}
            parentCategoryId={parentCategoryId}
            setParentCategoryId={setParentCategoryId}
            categoryList={categoryList}
            handleCategoryImage={handleCategoryImage}
          />
          <UpdateCategoriesDialog
            open={updateCategoryDialog}
            onClose={() => setUpdateCategoryDialog(false)}
            onClick={updateCategoriesForm}
            expandedArray={expandedArray}
            checkedArray={checkedArray}
            handleCategoryInput={handleCategoryInput}
            categoryList={categoryList}
          />

          <DeleteCategoriesDialog
            open={deleteCategoryDialog}
            onClose={deleteCategoriesForm}
            onClickNo={deleteCategoriesForm}
            onClickYes={deleteCategoriesYes}
            checkedArray={checkedArray}
            categoryList={categoryList}
          />
          <UpdateProductsByCategoryDialog
            open={updateProductsByCategoryDialog}
            onClose={() => setUpdateProductsByCategoryDialog(false)}
            onClick={updateProductsByCategoryForm}
            expandedArray={expandedArray}
            checkedArray={checkedArray}
            handleCategoryInput={handleCategoryInput}
            offer={offer}
            setOffer={setOffer}
            handleOffer={handleOffer}
          />
        </>
      )}
    </Layout>
  );
};

export default Categories;
