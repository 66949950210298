import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import PrintPaperHeading from '../../../../../components/Accounts/PrintPaperHeading';
import formatDate2 from '../../../../../helpers/formatDate2';
import { toIndianNumber } from '../../../../../helpers/toIndianNumber';

const useStyles = makeStyles({
  tableRow: {
    height: '60px',
  },
  tableCell: {
    padding: '0px 16px',
  },
});

const TrialBalanceLayout = React.forwardRef((props, ref) => {
  const { rows, columns } = props;
  const trialBalance = useSelector((state) => state.trialBalance);
  const classes = useStyles();
  const isAccountSubnameColumnPresent = columns.find((col) => col.field === 'Account_Subname');
  return (
    <Paper ref={ref} style={{ margin: 50, border: '2px solid gray' }}>
      <PrintPaperHeading
        heading={`Trial balance for the year ended ${formatDate2(trialBalance.toDate)}`}
      />
      <TableContainer style={{ display: 'flex', borderRadius: '0' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid gray' }}>
              {columns.map((col) => {
                if (col.field === 'Account_Subname') {
                  return (
                    <TableCell
                      key={col.field}
                      align='left'
                      style={{ fontWeight: 600, fontSize: '22px' }}
                    >
                      Subaccount
                    </TableCell>
                  );
                }
                if (col.field === 'Account_Name') {
                  return (
                    <TableCell
                      key={col.field}
                      align='left'
                      style={{ fontWeight: 600, fontSize: '22px' }}
                    >
                      Account
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={col.field}
                    align={col.field === 'id' ? 'left' : 'right'}
                    style={{ fontWeight: 600, fontSize: '22px' }}
                  >
                    {col.field === 'id' ? 'Sl No.' : col.field}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              return (
                <TableRow key={idx} className={classes.tableRow}>
                  <TableCell align='left' style={{ fontSize: '20px' }}>
                    {idx}
                  </TableCell>
                  <TableCell align='left' style={{ fontSize: '20px' }}>
                    {row.Account_Name}
                  </TableCell>
                  {isAccountSubnameColumnPresent && (
                    <TableCell align='left' style={{ fontSize: '20px' }}>
                      {row.Account_Subname}
                    </TableCell>
                  )}
                  <TableCell align='right' style={{ fontSize: '20px' }}>
                    {toIndianNumber(row.OB_Debit)}
                  </TableCell>
                  <TableCell align='right' style={{ fontSize: '20px' }}>
                    {toIndianNumber(row.OB_Credit)}
                  </TableCell>
                  <TableCell align='right' style={{ fontSize: '20px' }}>
                    {toIndianNumber(row.TRN_Debit)}
                  </TableCell>
                  <TableCell align='right' style={{ fontSize: '20px' }}>
                    {toIndianNumber(row.TRN_Credit)}
                  </TableCell>
                  <TableCell align='right' style={{ fontSize: '20px' }}>
                    {toIndianNumber(row.CB_Debit)}
                  </TableCell>
                  <TableCell align='right' style={{ fontSize: '20px' }}>
                    {toIndianNumber(row.CB_Credit)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

TrialBalanceLayout.displayName = 'TrialBalanceLayout';
export default TrialBalanceLayout;
