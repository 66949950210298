import { Button, Grid } from '@material-ui/core';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '../../../../components/Dialog';
import { mainColour1, mainColour2 } from '../../../../utils';
const DeleteCategoriesDialog = (props) => {
  const { open, onClose, onClickNo, onClickYes, checkedArray } = props;
  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Delete Category</DialogTitle>
      <DialogContent dividers>
        <Grid>
          <Typography variant='h5'>Checked</Typography>
          {checkedArray.length > 0 &&
            checkedArray.map((item, idx) => {
              return (
                <Grid
                  item
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
                  key={idx}
                >
                  <Typography variant='h6'>{item.name}</Typography>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          style={{ backgroundColor: mainColour2, color: 'white' }}
          onClick={onClickNo}
        >
          No
        </Button>
        <Button
          variant='contained'
          style={{ backgroundColor: mainColour1, color: 'white' }}
          onClick={onClickYes}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategoriesDialog;
