import { combineReducers } from 'redux';
import { authConstants } from '../actions/constants';
import accountGroupHeadReducers from './accountGroupHead.reducers';
import accountHeadReducers from './accountHead.reducers';
import accountNameReducers from './accountName.reducers';
import authReducers from './auth.reducers';
import buyerSellerReducers from './buyerSeller.reducers';
import cardReducers from './card.reducers';
import carouselReducers from './carousel.reducers';
import cartReducers from './cart.reducers';
import cashReducers from './cash.reducers';
import categoryReducers from './category.reducers';
import couponReducers from './coupon.reducers';
import globalReducers from './global.reducers';
import journalReducers from './journal.reducers';
import ledgerReducers from './ledger.reducers';
import orderReducers from './order.reducers';
import pageReducers from './page.reducers';
import pnlHeadReducers from './pnlHead.reducers';
import productReducers from './product.reducers';
import subscriberReducers from './subscriber.reducers';
import trialBalanceReducers from './trialBalance.reducers';
import usersReducers from './users.reducers';

const allreducers = combineReducers({
  auth: authReducers,
  users: usersReducers,
  subscriber: subscriberReducers,
  category: categoryReducers,
  product: productReducers,
  page: pageReducers,
  order: orderReducers,
  carousel: carouselReducers,
  card: cardReducers,
  coupon: couponReducers,
  cart: cartReducers,
  accountHead: accountHeadReducers,
  pnlHead: pnlHeadReducers,
  accountGroupHead: accountGroupHeadReducers,
  accountName: accountNameReducers,
  buyerSeller: buyerSellerReducers,
  cash: cashReducers,
  journal: journalReducers,
  ledger: ledgerReducers,
  trialBalance: trialBalanceReducers,
  global: globalReducers,
});

const rootReducer = (state, action) => {
  if (action.type === authConstants.LOGOUT_SUCCESS) {
    state = undefined;
  }
  return allreducers(state, action);
};

export default rootReducer;
