import { Button, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import Layout from '../../../layouts/Layout';
import {
  addColour,
  addProduct,
  addShade,
  addSize,
  deleteProduct,
  updateProduct,
} from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import AddProductDialogbox from './components/AddProductDialogbox';
import DeleteProductDialogbox from './components/DeleteProductDialogbox';
import ProductDetailsDialogbox from './components/ProductDetailsDialogbox';
import ProductTable from './components/ProductTable';
import UpdateProductDialogbox from './components/UpdateProductDialogbox';

const initNewDesign = {
  newSize: '',
  newColour: '',
  newShade: '',
};
const initNewProductDetails = {
  name: '',
  prodCode: '',
  description: 'Default description of all products',
  quantity: 1,
  cPrice: 0,
  price: 0,
  gPrice: 0,
  GST: 0,
  gstAmt: 0,
  discAmt: 0,
  priceWithGST: 0,
  size: '',
  colour: '',
  shade: '',
  fabric: '',
  fCount: '',
  offer: 0,
  blouse: 0,
  categoryId: '',
  productPictures: [],
};

const Products = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const product = useSelector((state) => state.product);

  const [newDesign, setNewDesign] = useState(initNewDesign);
  const [newProductDetails, setNewProductDetails] = useState(initNewProductDetails);

  const [open, setOpen] = useState(false);
  const [itemQuantity, setItemQuantity] = useState();
  const [itemOffer, setItemOffer] = useState();
  const [itemCPrice, setItemCPrice] = useState();
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [updateProductDialog, setUpdateProductDialog] = useState(false);
  const [myProduct, setMyProduct] = useState([]);
  const [productDetailsDialogbox, setProductDetailsDialogbox] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    setNewProductDetails((prev) => {
      const gPrice = (
        Math.ceil((prev.cPrice * Number(process.env.REACT_APP_PRODUCT_PRICE_TIMES)) / 10) * 10
      ).toFixed(2);
      const discountedPrice = ((gPrice * (100 - prev.offer)) / 100).toFixed(2);
      const discAmt = ((gPrice * prev.offer) / 100).toFixed(2);
      const gstAmt = ((discountedPrice * prev.GST) / 100).toFixed(2);

      return {
        ...prev,
        gPrice,
        price: discountedPrice,
        discAmt,
        gstAmt,
      };
    });
  }, [newProductDetails.offer, newProductDetails.cPrice, newProductDetails.GST]);

  useEffect(() => {
    for (let i = 0; i < category.categories.length; i++) {
      for (let j = 0; j < category.categories[i].children.length; j++) {
        if (category.categories[i].children[j]._id === newProductDetails.categoryId) {
          setNewProductDetails((prev) => ({
            ...prev,
            GST: category.categories[i].children[j].gst,
          }));
          break;
        }
      }
    }

    setNewProductDetails((prev) => ({
      ...prev,
      gstAmt: (prev.price * (Number(newProductDetails.GST) / 100)).toFixed(2),
      priceWithGST: (prev.price * ((100 + Number(newProductDetails.GST)) / 100)).toFixed(2),
    }));
  }, [
    newProductDetails.cPrice,
    newProductDetails.categoryId,
    newProductDetails.offer,
    category.categories,
  ]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    const form = new FormData();
    const {
      name,
      prodCode,
      description,
      quantity,
      cPrice,
      price,
      gPrice,
      gstAmt,
      discAmt,
      priceWithGST,
      size,
      colour,
      shade,
      fabric,
      fCount,
      offer,
      blouse,
      categoryId,
      productPictures,
    } = newProductDetails;
    form.append('category', categoryId);
    form.append('name', name);
    form.append('prodCode', prodCode);
    form.append('cPrice', cPrice);
    form.append('offer', offer);
    form.append('gPrice', gPrice);
    form.append('price', price);
    form.append('gstAmt', gstAmt);
    form.append('discAmt', discAmt);
    form.append('priceWithGST', priceWithGST);
    form.append('quantity', quantity);
    form.append('blouse', blouse);
    form.append('fabricCount', fCount);
    form.append('fabric', fabric);
    form.append('description', description);
    form.append('colour', colour);
    form.append('size', size);
    form.append('shade', shade);

    for (let pic of productPictures) {
      form.append('productPicture', pic);
    }

    if (categoryId === '') {
      toast.error('Please select a category !');
      return;
    }
    if (prodCode === '') {
      toast.error('Product code is required !');
      return;
    }
    if (name === '') {
      toast.error('Product name is required !');
      return;
    }
    if (productPictures.length < 2) {
      toast.error('Atleast two pictures are required !');
      return;
    }
    if (cPrice === 0 || cPrice === '') {
      toast.error('Cost price is required !');
      return;
    }
    if (size === '' || !size) {
      toast.error('Size is required !');
      return;
    }
    if (colour === '' || !colour) {
      toast.error('Colour is required !');
      return;
    }
    if (shade === '' || !shade) {
      toast.error('Shade is required !');
      return;
    }
    if (fabric === '' || !fabric) {
      toast.error('Fabric type is required !');
      return;
    }
    if (fCount === '' || !fCount) {
      toast.error('Fabric count is required !');
      return;
    }

    dispatch(addProduct(form));

    // only remove the product pictures
    setNewProductDetails((prev) => ({ ...prev, productPictures: [] }));
  };

  const handleCloseProductDetailsDialogbox = () => {
    setProductDetailsDialogbox(false);
  };

  const showProductDetailsDialogbox = useCallback((item) => {
    setProductDetails(item);
    setProductDetailsDialogbox(true);
  }, []);

  const showUpdateProductDialog = (item) => {
    setUpdateProductDialog(true);
    const productToBeUpdated = product.products.find((product) => item._id === product._id);
    setMyProduct(productToBeUpdated);
    setItemQuantity(productToBeUpdated.quantity);
    setItemCPrice(productToBeUpdated.cPrice);
    setItemOffer(productToBeUpdated.offer);
  };

  const updateProductForm = () => {
    let form = {};
    let GST = myProduct.category.gst;
    form._id = myProduct._id;
    form.quantity = itemQuantity;
    form.cPrice = itemCPrice;
    form.gPrice = (
      Math.ceil((itemCPrice * Number(process.env.REACT_APP_PRODUCT_PRICE_TIMES)) / 10) * 10
    ).toFixed(2);
    form.offer = itemOffer;
    form.price = (form.gPrice * ((100 - itemOffer) / 100)).toFixed(2);
    form.discAmt = (form.gPrice * (itemOffer / 100)).toFixed(2);
    form.gstAmt = (form.price * (+GST / 100)).toFixed(2);
    form.priceWithGST = (form.price * ((100 + +GST) / 100)).toFixed(2);
    dispatch(updateProduct(form));
  };

  const showDeleteProductDialog = (item) => {
    setDeleteProductDialog(true);
    const productToBeDeleted = product.products.find((product) => item._id === product._id);
    setMyProduct(productToBeDeleted);
  };

  const deleteProductYes = () => {
    if (myProduct.length > 0) {
      dispatch(deleteProduct(myProduct[0]));
      setDeleteProductDialog(false);
    }
  };

  const createCategoryList = (categories, options = []) => {
    for (let category of categories) {
      options.push({ value: category._id, name: category.name, parentId: category.parentId });
      if (category.children.length > 0) {
        createCategoryList(category.children, options);
      }
    }

    return options;
  };

  const onChangeNewProductDetails = (e) => {
    const { name, value } = e.target;
    setNewProductDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleProductPictures = (files) => {
    setNewProductDetails((prev) => ({ ...prev, productPictures: [...files] }));
  };

  const onChangeNewDesign = (e) => {
    const { name, value } = e.target;
    setNewDesign((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewDesignSubmit = (type, value) => {
    const form = {};
    form[type] = value;

    if (type === 'size') {
      dispatch(addSize(form));
    }
    if (type === 'colour') {
      dispatch(addColour(form));
    }
    if (type === 'shade') {
      dispatch(addShade(form));
    }
    setNewDesign(initNewDesign);
  };

  return (
    <Layout sidebar>
      <div>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: '20px' }}
        >
          <Grid item>
            <Typography variant='h4' className={classes.heading}>
              ALL PRODUCTS ({product.products.length})
            </Typography>
          </Grid>
          <Grid item>
            <Button style={{ color: mainColour2 }} variant='contained' onClick={handleClickOpen}>
              <AddCircleIcon color='secondary' style={{ color: mainColour2 }} /> Add
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: '20px' }}
        >
          <Grid item md={12} xs={12}>
            {!product.loading ? (
              <ProductTable
                product={product}
                showProductDetailsDialogbox={showProductDetailsDialogbox}
                showUpdateProductDialog={showUpdateProductDialog}
                showDeleteProductDialog={showDeleteProductDialog}
              />
            ) : (
              <MyTableSkeleton />
            )}
          </Grid>
        </Grid>
      </div>
      <AddProductDialogbox
        category={category}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        newDesign={newDesign}
        onChangeNewDesign={onChangeNewDesign}
        handleNewDesignSubmit={handleNewDesignSubmit}
        newProductDetails={newProductDetails}
        setNewProductDetails={setNewProductDetails}
        onChangeNewProductDetails={onChangeNewProductDetails}
        createCategoryList={createCategoryList}
        handleProductPictures={handleProductPictures}
      />
      <ProductDetailsDialogbox
        productDetails={productDetails}
        productDetailsDialogbox={productDetailsDialogbox}
        handleCloseProductDetailsDialogbox={handleCloseProductDetailsDialogbox}
      />
      {updateProductDialog && (
        <UpdateProductDialogbox
          myProduct={myProduct}
          updateProductDialog={updateProductDialog}
          setUpdateProductDialog={setUpdateProductDialog}
          updateProductForm={updateProductForm}
          itemQuantity={itemQuantity}
          setItemQuantity={setItemQuantity}
          itemOffer={itemOffer}
          setItemOffer={setItemOffer}
          itemCPrice={itemCPrice}
          setItemCPrice={setItemCPrice}
        />
      )}
      <DeleteProductDialogbox
        myProduct={myProduct}
        setDeleteProductDialog={setDeleteProductDialog}
        deleteProductDialog={deleteProductDialog}
        deleteProductYes={deleteProductYes}
      />
    </Layout>
  );
};

export default Products;
