import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CustomInput from '../../../components/CustomInput';
import { Dialog, DialogContent, DialogTitle } from '../../../components/Dialog';

const AddCard = (props) => {
  const { name, setName, open, setOpen, handleClose, handleCardPicture } = props;
  const [file, setFile] = useState('');

  function uploadSingleFile(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={() => setOpen(false)}>
        Add a card
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <div className='form-group preview' style={{ display: 'flex', marginTop: '30px' }}>
            {file && <img src={file} alt='Missing' height='150px' />}
          </div>

          <label htmlFor='upload-photo' style={{ width: '100%' }}>
            <input
              style={{ display: 'none' }}
              id='upload-photo'
              name='upload-photo'
              type='file'
              disabled={file !== ''}
              className='form-control'
              onChange={(e) => {
                uploadSingleFile(e);
                handleCardPicture(e);
              }}
            />

            <Typography
              variant='body2'
              style={{
                color: 'red',
                fontWeight: 600,
                fontSize: '12px',
                marginTop: '-10px',
                marginBottom: '5px',
              }}
            >
              * Card is required
            </Typography>
            <Button color='primary' variant='contained' component='span' style={{ width: '100%' }}>
              Upload Card
            </Button>
          </label>
        </div>

        <CustomInput
          fullWidth
          label='Name'
          placeholder='Name'
          value={name}
          type='text'
          onChange={(e) => setName(e.target.value)}
          eyeIcon={false}
          showInputValue={true}
        />
        {name === '' && (
          <Typography
            variant='body2'
            style={{
              color: 'red',
              fontWeight: 600,
              fontSize: '12px',
              marginTop: '-10px',
              marginBottom: '5px',
            }}
          >
            * Card name is required
          </Typography>
        )}

        <Grid container direction='row' justifyContent='center'>
          <Button
            autoFocus
            variant='contained'
            color='primary'
            onClick={() => {
              handleClose();
              setFile('');
            }}
          >
            Create
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddCard;
