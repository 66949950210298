import { pnlHeadConstants } from '../actions/constants';

const initialState = {
  pnlHeads: [],
  loading: false,
  error: null,
};

const pnlHeadReducers = (state = initialState, action) => {
  switch (action.type) {
    case pnlHeadConstants.GET_ALL_PNL_HEADS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pnlHeadConstants.GET_ALL_PNL_HEADS_SUCCESS:
      state = {
        ...state,
        pnlHeads: action.payload.pnlHeads,
        loading: false,
      };
      break;
    case pnlHeadConstants.ADD_NEW_PNL_HEAD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pnlHeadConstants.ADD_NEW_PNL_HEAD_SUCCESS: {
      const pnlHead = action.payload.pnlHead;
      state.pnlHeads.push(pnlHead);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case pnlHeadConstants.ADD_NEW_PNL_HEAD_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case pnlHeadConstants.UPDATE_PNL_HEAD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case pnlHeadConstants.UPDATE_PNL_HEAD_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case pnlHeadConstants.UPDATE_PNL_HEAD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default pnlHeadReducers;
