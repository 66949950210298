import { Button, Grid } from '@material-ui/core';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '../../../../components/Dialog';
import { mainColour2 } from '../../../../utils';
import ProductDetailField from './ProductDetailField';

const ProductDetailsDialogbox = (props) => {
  const { productDetails, productDetailsDialogbox, handleCloseProductDetailsDialogbox } = props;
  if (!productDetails) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      onClose={handleCloseProductDetailsDialogbox}
      aria-labelledby='customized-dialog-title'
      open={productDetailsDialogbox}
    >
      <DialogTitle id='customized-dialog-title' onClose={handleCloseProductDetailsDialogbox}>
        Product Details
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant='h6'>Product Pictures</Typography>

        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <div style={{ display: 'flex' }}>
            {productDetails.productPictures.map((picture, index) => (
              <div className='productImgContainer' key={index}>
                <img src={picture.img} alt='Product Missing' height='100' width='100' />
              </div>
            ))}
          </div>
        </Grid>
        <ProductDetailField
          firstKey='Name'
          firstValue={productDetails.name}
          secondKey='Category'
          secondValue={productDetails.category.name}
        />
        <ProductDetailField
          firstKey='Quantity Available'
          firstValue={productDetails.quantity}
          secondKey='HSN Code'
          secondValue={productDetails.category.hsnCode}
        />
        <ProductDetailField
          firstKey='Colour'
          firstValue={productDetails.colour}
          secondKey='Size'
          secondValue={productDetails.size}
        />
        <ProductDetailField
          firstKey='Cost Price'
          firstValue={productDetails.cPrice}
          secondKey='Gross Price'
          secondValue={productDetails.gPrice}
        />
        <ProductDetailField
          firstKey='Discount Amount'
          firstValue={productDetails.discAmt}
          secondKey='GST Amount'
          secondValue={productDetails.gstAmt}
        />
        <ProductDetailField
          firstKey='GST %'
          firstValue={productDetails.category.gst}
          secondKey='Price with GST'
          secondValue={productDetails.priceWithGST}
        />
        <ProductDetailField
          firstKey='Fabric'
          firstValue={productDetails.fabric}
          secondKey='Fabric Count'
          secondValue={productDetails.fabricCount}
        />
        <ProductDetailField firstKey='Description' firstValue={productDetails.description} />
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={handleCloseProductDetailsDialogbox}
          style={{ backgroundColor: mainColour2, color: 'white' }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDetailsDialogbox;
