import { cardConstants } from '../actions/constants';

const initialState = {
  cards: [],
  loading: false,
  error: null,
};

const cardReducers = (state = initialState, action) => {
  switch (action.type) {
    case cardConstants.GET_TRUE_CARDS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cardConstants.GET_TRUE_CARDS_SUCCESS:
      state = {
        ...state,
        cards: action.payload.cards,
        loading: false,
      };
      break;
    case cardConstants.GET_TRUE_CARDS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case cardConstants.GET_ALL_CARDS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cardConstants.GET_ALL_CARDS_SUCCESS:
      state = {
        ...state,
        cards: action.payload.cards,
        loading: false,
      };
      break;
    case cardConstants.GET_ALL_CARDS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case cardConstants.ADD_CARD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cardConstants.ADD_CARD_SUCCESS: {
      const card = action.payload.card;
      state.cards.push(card);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case cardConstants.ADD_CARD_FAILURE:
      state = {
        ...initialState,
      };
      break;
    default:
      break;
  }
  return state;
};

export default cardReducers;
