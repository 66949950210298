import { Typography } from '@material-ui/core';

const PrintPaperHeading = ({ heading }) => {
  return (
    <div style={{ padding: 20, borderBottom: '2px solid gray', borderRadius: 0 }}>
      <Typography variant='h3' style={{ textAlign: 'center', fontFamily: 'Fjalla One' }}>
        SAMBALPURI FAB
      </Typography>
      <Typography variant='h4' style={{ textAlign: 'center', fontWeight: 600 }}>
        {heading}
      </Typography>
    </div>
  );
};

export default PrintPaperHeading;
