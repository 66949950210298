import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import MyTable from '../../../../components/MyTable';
import MyTableSkeleton from '../../../../components/MyTableSkeleton';
import { addJournals } from '../../../../redux/actions';
import { mainColour3 } from '../../../../utils';

const UnfinishedJournalsTable = (props) => {
  const { columns, setNarration, setDate } = props;
  const dispatch = useDispatch();
  const journal = useSelector((state) => state.journal);
  const year = useSelector((state) => state.global.year);

  const [totals, setTotals] = useState({
    debit: 0,
    credit: 0,
  });

  const rows = journal.journals.map((item, idx) => ({
    id: idx + 1,
    Account_Name: item.accountNameReal,
    Account_Subname: item.accountSubnameReal,
    Debit: Math.abs(item.dbAmount).toFixed(2),
    Credit: item.crAmount.toFixed(2),
    Narration: item.narration,
  }));

  useEffect(() => {
    const debitTotal =
      rows.length !== 0
        ? Number(
            rows.reduce((a, b) => ({ Debit: Number(a.Debit) + Number(b.Debit) })).Debit,
          ).toFixed(2)
        : 0;

    const creditTotal =
      rows.length !== 0
        ? Number(
            rows.reduce((a, b) => ({ Credit: Number(a.Credit) + Number(b.Credit) })).Credit,
          ).toFixed(2)
        : 0;

    setTotals({
      debit: debitTotal,
      credit: creditTotal,
    });
  }, [JSON.stringify(rows)]);

  const AddJournalsToDatabase = () => {
    const payload = {
      voucherNum: journal.voucherNum,
      date: journal.date,
      entries: journal.journals,
      year: year,
    };

    if (totals.debit === totals.credit) {
      dispatch(addJournals(payload, setNarration, setDate, year));
    } else {
      return toast.error('Credit Debit amount mismatch!');
    }
  };

  return (
    <>
      {!journal.loading ? (
        rows.length > 0 && (
          <div>
            <MyTable rows={rows} columns={columns} rowHeight={60} hideFooter={true} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography style={{ fontWeight: 600, color: 'red' }}>{totals.debit}</Typography>
              <Button
                onClick={AddJournalsToDatabase}
                variant='contained'
                style={{ margin: '0 10px', backgroundColor: mainColour3, color: 'white' }}
              >
                Save
              </Button>
              <Typography style={{ fontWeight: 600, color: 'green' }}>{totals.credit}</Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography style={{ fontWeight: 600 }}>
                {Number(totals.credit - totals.debit).toFixed(2)}
              </Typography>
            </div>
          </div>
        )
      ) : (
        <MyTableSkeleton />
      )}
    </>
  );
};

export default React.memo(UnfinishedJournalsTable);
