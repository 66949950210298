import { Grid, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { JsonToExcel } from 'react-json-to-excel';
import { useSelector } from 'react-redux';
import { mainColour3 } from '../../utils';

const MyTable = (props) => {
  const app = useSelector((state) => state.global.app);
  const columns = props.columns;
  const rowss = props.rows;
  const [searchValue, setSearchValue] = useState('');
  const [filteredRows, setFilteredRows] = useState(rowss);
  const handleSeachInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };

  useEffect(() => {
    if (props.showUpdateJournalDialog && filteredRows.length > 0 && searchValue !== '') {
      props.showUpdateJournalDialog(filteredRows[0].Voucher);
    }
  }, [filteredRows]);

  const handleSearch = (e) => {
    // setSearchValue(e.target.value)
    if (e.target.value === '') {
      setFilteredRows(rowss);
    } else {
      if (app === 'Admin') {
        setFilteredRows(
          rowss.filter((row) => {
            return row.ProdCode && row.ProdCode.includes(searchValue);
          }),
        );
      } else if (app === 'Accounts') {
        setFilteredRows(
          rowss.filter((row) => {
            return row.Voucher && row.Voucher.includes(searchValue);
          }),
        );
      }
    }
  };
  return (
    <>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid
          item
          style={{ marginBottom: '5px', display: props.showSearch === false ? 'none' : 'flex' }}
        >
          <TextField
            fullWidth
            variant='filled'
            value={searchValue}
            label={app === 'Admin' ? 'Product Code' : 'Voucher No.'}
            placeholder={app === 'Admin' ? 'Product Code' : 'Voucher No.'}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={handleSeachInputKeyPress}
          />
        </Grid>
        <Grid item style={{ marginBottom: '5px', marginTop: '5px' }}>
          <JsonToExcel
            title='Download as Excel'
            data={rowss}
            fileName='Sambalpuri Fab Document'
            btnColor={mainColour3}
          />
        </Grid>
      </Grid>
      <DataGrid
        columns={columns}
        rows={filteredRows}
        components={{
          Toolbar: GridToolbar,
        }}
        density='compact'
        autoHeight
        rowHeight={props.rowHeight}
        loading={props.loading}
        hideFooter={props.hideFooter ? props.hideFooter : false}
        // pageSize={30}
        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
        // checkboxSelection
        showColumnRightBorder
        showCellRightBorder
      />
    </>
  );
};

export default React.memo(MyTable);
