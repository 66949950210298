const formatDate = (date) => {
  if (date) {
    const d = new Date(date);
    return `${d.getDate()}-${
      d.getMonth() + 1
    }-${d.getFullYear()} | ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  }
  return '';
};

export default formatDate;
