import axios from 'axios';
import toast from 'react-hot-toast';
import { authConstants } from '../redux/actions/constants';
import store from '../redux/store';
import { api } from '../utils';

const token = window.localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: api,
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
  },
});

axiosInstance.interceptors.request.use((req) => {
  const { auth } = store.getState();
  if (auth.token) {
    req.headers.Authorization = `Bearer ${auth.token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const status = error.response ? error.response.status : 500;
    if (status && status === 500) {
      toast.error('Application error. Contact Ashish', { duration: 10000 });
      store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
    }
    if (status && status === 401) {
      toast.error(error.response?.data?.message);
      store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
