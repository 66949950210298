import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { accountGroupHeadConstants } from './constants';

//these are the action creators which creates actions
const getAllAccountGroupHead = () => {
  return async (dispatch) => {
    dispatch({ type: accountGroupHeadConstants.GET_ALL_ACCOUNT_GROUP_HEADS_REQUEST });

    axios
      .get('/accountgroupheads')
      .then((response) => {
        const { accountGroupHeads } = response.data;
        dispatch({
          type: accountGroupHeadConstants.GET_ALL_ACCOUNT_GROUP_HEADS_SUCCESS,
          payload: { accountGroupHeads: accountGroupHeads },
        });
      })
      .catch((error) => {
        dispatch({
          type: accountGroupHeadConstants.GET_ALL_ACCOUNT_GROUP_HEADS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addNewAccountGroupHead = (form) => {
  return async (dispatch) => {
    dispatch({ type: accountGroupHeadConstants.ADD_NEW_ACCOUNT_GROUP_HEAD_REQUEST });

    axios
      .post(`/accountgroupheads`, form)
      .then((response) => {
        dispatch({
          type: accountGroupHeadConstants.ADD_NEW_ACCOUNT_GROUP_HEAD_SUCCESS,
          payload: { accountGroupHead: response.data.accountGroupHead },
        });
        dispatch(getAllAccountGroupHead());
        toast.success('New account group head created!');
      })
      .catch((error) => {
        dispatch({
          type: accountGroupHeadConstants.ADD_NEW_ACCOUNT_GROUP_HEAD_FAILURE,
          payload: error,
        });
        toast.error('Category creation failed! Please try later');
      });
  };
};

export { getAllAccountGroupHead };
