import { Button, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import BackdropLoading from '../../../components/BackdropLoading';
import replaceImageUrl from '../../../helpers/replaceImageUrl';
import Layout from '../../../layouts/Layout';
import { addCard } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import AddCard from './AddCard';
/**
 * @author
 * @function Cards
 **/

const Cards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const card = useSelector((state) => state.card);
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  const [cardPicture, setCardPicture] = useState('');

  const handleClose = () => {
    const form = new FormData();
    form.append('name', name);
    form.append('show', true);

    form.append('cardPicture', cardPicture);

    if (name === '') {
      toast.error('Card name is required !');
      return;
    }
    if (cardPicture === '') {
      toast.error('Card is required !');
      return;
    }

    dispatch(addCard(form));
    setOpen(false);
    setCardPicture('');
  };

  const handleCardPicture = (e) => {
    setCardPicture(e.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            CARDS ({card.cards.length})
          </Typography>
        </Grid>
        <Grid item>
          <Button style={{ color: mainColour2 }} variant='contained' onClick={handleClickOpen}>
            <AddCircleIcon color='secondary' style={{ color: mainColour2 }} /> Add
          </Button>
        </Grid>
      </Grid>

      {card.loading ? (
        <BackdropLoading loading={card.loading} />
      ) : (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: '20px' }}
        >
          {card.cards.length > 0 &&
            card.cards.map((item, idx) => {
              return (
                <Grid
                  key={idx}
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item md={1}>
                    <Typography>{idx + 1}</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography>{item.name}</Typography>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        backgroundColor: item.show ? 'green' : 'red',
                      }}
                    ></div>
                  </Grid>
                  <Grid item md={5} sx={5}>
                    <img
                      src={replaceImageUrl(item.cardPicture.img)}
                      alt='Card'
                      height='150px'
                      width='200px'
                      style={{ marginLeft: '10px' }}
                    />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      )}
      <AddCard
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        name={name}
        setName={setName}
        cardPicture={cardPicture}
        handleCardPicture={handleCardPicture}
      />
    </Layout>
  );
};

export default Cards;
