import { productConstants } from '../actions/constants';

const initialState = {
  products: [],
  loading: false,
  error: null,
  sizes: [],
  colours: [],
  shades: [],
};

const productReducers = (state = initialState, action) => {
  switch (action.type) {
    case productConstants.GET_ALL_PRODUCTS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_ALL_PRODUCTS_SUCCESS:
      state = {
        ...state,
        products: action.payload.products.reverse(),
        loading: false,
      };
      break;

    case productConstants.ADD_NEW_PRODUCT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.ADD_NEW_PRODUCT_SUCCESS: {
      const product = action.payload.product;
      state.products.unshift(product);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case productConstants.ADD_NEW_PRODUCT_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case productConstants.UPDATE_PRODUCT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.UPDATE_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case productConstants.UPDATE_PRODUCT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case productConstants.DELETE_PRODUCT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.DELETE_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case productConstants.DELETE_PRODUCT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    case productConstants.GET_ALL_SIZES_SUCCESS:
      state = {
        ...state,
        loading: false,
        sizes: action.payload.sizes,
      };
      break;
    case productConstants.GET_ALL_COLOURS_SUCCESS:
      state = {
        ...state,
        loading: false,
        colours: action.payload.colours,
      };
      break;
    case productConstants.GET_ALL_SHADES_SUCCESS:
      state = {
        ...state,
        loading: false,
        shades: action.payload.shades,
      };
      break;
    case productConstants.ADD_NEW_SIZE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.ADD_NEW_SIZE_SUCCESS: {
      const size = action.payload.size;
      state.sizes.push(size);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case productConstants.ADD_NEW_SIZE_FAILURE:
      state = {
        ...initialState,
      };
      break;

    case productConstants.ADD_NEW_COLOUR_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.ADD_NEW_COLOUR_SUCCESS: {
      const colour = action.payload.colour;
      state.colours.push(colour);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case productConstants.ADD_NEW_COLOUR_FAILURE:
      state = {
        ...initialState,
      };
      break;

    case productConstants.ADD_NEW_SHADE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.ADD_NEW_SHADE_SUCCESS: {
      const shade = action.payload.shade;
      state.shades.push(shade);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case productConstants.ADD_NEW_SHADE_FAILURE:
      state = {
        ...initialState,
      };
      break;

    default:
      break;
  }

  return state;
};

export default productReducers;
