import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { productConstants } from './constants';

const getAllProduct = () => {
  return async (dispatch) => {
    //dispatch a get product request
    dispatch({ type: productConstants.GET_ALL_PRODUCTS_REQUEST });

    axios
      .get('/admin/products')
      .then((response) => {
        const { products } = response.data;
        dispatch({
          type: productConstants.GET_ALL_PRODUCTS_SUCCESS,
          payload: { products: products },
        });
      })
      .catch((error) => {
        dispatch({
          type: productConstants.GET_ALL_PRODUCTS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addProduct = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_NEW_PRODUCT_REQUEST });

    axios
      .post('products', form)
      .then((response) => {
        dispatch({
          type: productConstants.ADD_NEW_PRODUCT_SUCCESS,
          payload: { product: response.data.product },
        });

        toast.success('New product created!');
      })
      .catch((error) => {
        dispatch({
          type: productConstants.ADD_NEW_PRODUCT_FAILURE,
          payload: { error: error },
        });
        toast.error('Product creation failed! Please try later');
      });
  };
};

export const updateProduct = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form._id}`, form)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: productConstants.UPDATE_PRODUCT_FAILURE,
          payload: { error },
        });
        toast.error('Product update failed! Please try later');
      });
  };
};

export const deleteProduct = (product) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.DELETE_PRODUCT_REQUEST });

    axios
      .delete(`/admin/products/${product._id}`)
      .then(() => {
        dispatch({ type: productConstants.DELETE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product deleted successfully!');
      })
      .catch((error) => {
        dispatch({
          type: productConstants.DELETE_PRODUCT_FAILURE,
          payload: { error },
        });
        toast.error('Product deletion failed! Please try later');
      });
  };
};

export const updateName = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/name/${form.name}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product name updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product name update failed! Please try later');
      });
  };
};
export const updateFabric = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/fabric/${form.fabric}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product fabric updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product fabric update failed! Please try later');
      });
  };
};
export const updateFCount = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/fcount/${form.fcount}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product fabric count updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product fabric count update failed! Please try later');
      });
  };
};
export const updateBlouse = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/blouse/${form.blouse}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product blouse updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product blouse update failed! Please try later');
      });
  };
};

export const updatePictures = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/pictures`, form)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product images updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product images update failed! Please try later');
      });
  };
};

export const updateSize = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/size/${form.size}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product size updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product size update failed! Please try later');
      });
  };
};
export const updateColour = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/colour/${form.colour}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product colour updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product colour update failed! Please try later');
      });
  };
};
export const updateShade = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_PRODUCT_REQUEST });
    axios
      .put(`/admin/products/${form.prodCode}/shade/${form.shade}`)
      .then(() => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_SUCCESS });
        dispatch(getAllProduct());
        toast.success('Product shade updated successfully!');
      })
      .catch((error) => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_FAILURE });
        toast.error('Product shade update failed! Please try later');
      });
  };
};

/*-------------------------During product creation-----------------------------------*/

export const addSize = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_NEW_SIZE_REQUEST });

    axios
      .post('/sizes', form)
      .then((response) => {
        dispatch({
          type: productConstants.ADD_NEW_SIZE_SUCCESS,
          payload: { size: response.data.size },
        });

        toast.success('New size created!');
        // dispatch(getAllSizes());
      })
      .catch((error) => {
        dispatch({
          type: productConstants.ADD_NEW_SIZE_FAILURE,
          payload: { error: error },
        });
        toast.error('Size creation failed! Please try later');
      });
  };
};

export const addColour = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_NEW_COLOUR_REQUEST });

    axios
      .post('/colours', form)
      .then((response) => {
        dispatch({
          type: productConstants.ADD_NEW_COLOUR_SUCCESS,
          payload: { colour: response.data.colour },
        });

        toast.success('New colour created!');
        // dispatch(getAllColours());
      })
      .catch((error) => {
        dispatch({
          type: productConstants.ADD_NEW_COLOUR_FAILURE,
          payload: { error: error },
        });
        toast.error('Colour creation failed! Please try later');
      });
  };
};

export const addShade = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_NEW_SHADE_REQUEST });

    axios
      .post('/shades', form)
      .then((response) => {
        dispatch({
          type: productConstants.ADD_NEW_SHADE_SUCCESS,
          payload: { shade: response.data.shade },
        });

        toast.success('New shade created!');
      })
      .catch((error) => {
        dispatch({
          type: productConstants.ADD_NEW_SHADE_FAILURE,
          payload: { error: error },
        });
        toast.error('Shade creation failed! Please try later');
      });
  };
};

const getAllSizes = () => {
  return async (dispatch) => {
    axios
      .get('/sizes')
      .then((response) => {
        const { sizes } = response.data;
        dispatch({
          type: productConstants.GET_ALL_SIZES_SUCCESS,
          payload: { sizes: sizes },
        });
      })
      .catch((error) => {});
  };
};

const getAllColours = () => {
  return async (dispatch) => {
    axios
      .get('/colours')
      .then((response) => {
        const { colours } = response.data;
        dispatch({
          type: productConstants.GET_ALL_COLOURS_SUCCESS,
          payload: { colours: colours },
        });
      })
      .catch((error) => {});
  };
};

const getAllShades = () => {
  return async (dispatch) => {
    axios
      .get('/shades')
      .then((response) => {
        const { shades } = response.data;
        dispatch({
          type: productConstants.GET_ALL_SHADES_SUCCESS,
          payload: { shades: shades },
        });
      })
      .catch((error) => {});
  };
};

export { getAllColours, getAllProduct, getAllShades, getAllSizes };
