import React from 'react';
import { Redirect, Route } from 'react-router-dom';
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => {
        const token = window.localStorage.getItem('token');

        //if the user is a logged in user, then take him to his admin dashboard
        if (token) {
          return <Component {...props} />;
        } else {
          //if the user is not a logged in user, then redirect him to signin page
          return <Redirect to={'/signin'} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
