import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import CustomInput from '../../../components/CustomInput';
import { updateBuyerSeller } from '../../../redux/actions';

const UpdateAccount = (props) => {
  const classes = useStyles();
  const { party, updateBuyerSellerDialog, setUpdateBuyerSellerDialog } = props;
  const dispatch = useDispatch();

  const [name, setName] = useState(party.name);
  const [mobile, setMobile] = useState(party.mobile);
  const [email, setEmail] = useState(party.email);
  const [address, setAddress] = useState(party.address);
  const [gstNum, setGstNum] = useState(party.gstNum);
  const [bank, setBank] = useState(party.bank);
  const [bankAccountNum, setBankAccountNum] = useState(party.bankAccountNum);
  const [bankBranch, setBankBranch] = useState(party.bankBranch);
  const [ifsc, setIfsc] = useState(party.ifsc);

  const updateAccountForm = () => {
    setUpdateBuyerSellerDialog(false);
    const payload = {
      _id: party._id,
      name: name,
      mobile: mobile,
      email: email,
      address: address,
      gstNum: gstNum,
      bank: bank,
      bankAccountNum: bankAccountNum,
      bankBranch: bankBranch,
      ifsc: ifsc,
    };

    dispatch(updateBuyerSeller(payload));
  };
  return (
    <Dialog
      fullWidth
      onClose={() => setUpdateBuyerSellerDialog(false)}
      aria-labelledby='updateBuyerSellerDialogBox'
      open={updateBuyerSellerDialog}
    >
      <DialogTitle id='updateAccountDialogBox' onClose={() => setUpdateBuyerSellerDialog(false)}>
        Update Party Details
      </DialogTitle>
      <DialogContent dividers>
        {party && (
          <>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={6} sm={6}>
                <CustomInput
                  fullWidth
                  label='Name'
                  placeholder='Name'
                  value={name}
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <CustomInput
                  fullWidth
                  label='Mobile'
                  placeholder='Mobile'
                  value={mobile}
                  type='text'
                  onChange={(e) => setMobile(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  label='Email'
                  placeholder='Email'
                  value={email}
                  type='text'
                  onChange={(e) => setEmail(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  label='Gst Num'
                  placeholder='Gst Num'
                  value={gstNum}
                  type='text'
                  onChange={(e) => setGstNum(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
            </Grid>
            <CustomInput
              fullWidth
              label='Address'
              placeholder='Address'
              value={address}
              type='text'
              onChange={(e) => setAddress(e.target.value)}
              eyeIcon={false}
              showInputValue={true}
            />

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  label='Bank'
                  placeholder='Bank'
                  value={bank}
                  type='text'
                  onChange={(e) => setBank(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  label='Account No.'
                  placeholder='Account No.'
                  value={bankAccountNum}
                  type='text'
                  onChange={(e) => setBankAccountNum(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  label='IFSC'
                  placeholder='IFSC'
                  value={ifsc}
                  type='text'
                  onChange={(e) => setIfsc(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  label='Branch'
                  placeholder='Branch'
                  value={bankBranch}
                  type='text'
                  onChange={(e) => setBankBranch(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <Button autoFocus onClick={updateAccountForm} className={classes.button2}>
                Save changes
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAccount;
