import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { ledgerConstants } from './constants';

const getLedger = (payload) => {
  return async (dispatch) => {
    dispatch({ type: ledgerConstants.GET_LEDGER_DETAILS_REQUEST });
    axios
      .get(
        `/ledgers?accountName=${payload.accountName}&accountSubname=${payload.accountSubname}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      )
      .then((response) => {
        const { accountId, cashes, journals } = response.data;
        dispatch({
          type: ledgerConstants.GET_LEDGER_DETAILS_SUCCESS,
          payload: { accountId: accountId, cashes: cashes, journals: journals },
        });
        toast.success('Ledger created successfully!');
      })
      .catch((error) => {
        dispatch({
          type: ledgerConstants.GET_LEDGER_DETAILS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const getCashLedger = (payload) => {
  return async (dispatch) => {
    //dispatch a get product request
    dispatch({ type: ledgerConstants.GET_LEDGER_DETAILS_REQUEST });

    axios
      .get(`/ledgers/cashes?fromDate=${payload.fromDate}&toDate=${payload.toDate}`)
      .then((response) => {
        const { cashes } = response.data;
        dispatch({
          type: ledgerConstants.GET_LEDGER_DETAILS_SUCCESS,
          payload: { cashes: cashes, journals: [] },
        });
      })
      .catch((error) => {
        dispatch({
          type: ledgerConstants.GET_LEDGER_DETAILS_FAILURE,
          payload: { error: error },
        });
      });
  };
};
export const viewAllRecords = () => {
  return async (dispatch) => {
    dispatch({ type: ledgerConstants.VIEW_ALL_RECORDS_REQUEST });
    setTimeout(() => {
      dispatch({ type: ledgerConstants.VIEW_ALL_RECORDS_SUCCESS });
    }, 500);
  };
};

export const hideAllRecords = () => {
  return async (dispatch) => {
    dispatch({ type: ledgerConstants.HIDE_ALL_RECORDS_REQUEST });
    setTimeout(() => {
      dispatch({ type: ledgerConstants.HIDE_ALL_RECORDS_SUCCESS });
    }, 500);
  };
};

export { getLedger };
