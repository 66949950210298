import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import formatDate from '../../../helpers/formatDate';
import Layout from '../../../layouts/Layout';

const Subscribers = () => {
  const classes = useStyles();
  const subscriber = useSelector((state) => state.subscriber);
  const columns = [
    { field: 'id', width: 100 },
    { field: 'Joined On', width: 175 },
    { field: 'Email', width: 250 },
  ];
  const rows = subscriber.subscribers.map((user, idx) => {
    return {
      id: idx + 1,
      'Joined On': formatDate(user.createdAt),
      Email: user.email,
    };
  });

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            ALL SUBSCRIBERS ({subscriber.subscribers.length})
          </Typography>
        </Grid>
        {subscriber.subscribers.length > 0 ? (
          <MyTable rows={rows} columns={columns} showSearch={false} />
        ) : (
          <MyTableSkeleton />
        )}
      </Grid>
    </Layout>
  );
};

export default Subscribers;
