import { makeStyles } from '@material-ui/core';
import { mainColour1, mainColour2 } from '../../utils';
const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    color: mainColour1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  button1: {
    [theme.breakpoints.down('sm')]: {
      padding: '6px',
    },
  },
  button2: {
    backgroundColor: mainColour2,
    color: 'white',
    '&:hover': {
      backgroundColor: mainColour1,
      color: 'white',
    },
  },
  externalLinks: {
    color: 'white',
    backgroundColor: mainColour2,
    height: '50px',
    '&:hover': {
      backgroundColor: mainColour1,
      color: 'white',
    },
  },
  grid: {
    margin: '5px 0',
    borderRadius: '10px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      height: '60px',
    },
  },

  top: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  middle: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  bottom: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  tableImage: {
    height: '100px',
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      height: '75px',
      width: '75px',
    },
  },

  printButton: {
    marginLeft: '20px',
    backgroundColor: mainColour2,
    color: 'white',
    '&:hover': {
      backgroundColor: mainColour2,
      color: 'white',
    },
  },
}));

export default useStyles;
