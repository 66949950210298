import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { orderConstants } from './constants';
import { getInitialDataAdmin } from './initialData.actions';

export const getCustomerOrders = () => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_CUSTOMER_ORDER_REQUEST });
    try {
      const res = await axios.get('/admin/orders');
      if (res.status === 200) {
        const { orders } = res.data;
        dispatch({
          type: orderConstants.GET_CUSTOMER_ORDER_SUCCESS,
          payload: { orders },
        });
      } else {
        const { error } = res.data;
        dispatch({
          type: orderConstants.GET_CUSTOMER_ORDER_FAILURE,
          payload: { error },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateOrder = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_CUSTOMER_ORDER_REQUEST });
    try {
      const res = await axios.put(`/admin/orders/${payload.orderId}`, payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_CUSTOMER_ORDER_SUCCESS });
        toast.success('Order status updated!');
        // dispatch(getCustomerOrders());
        dispatch(getInitialDataAdmin());
      } else {
        const { error } = res.data;
        toast.error('Status update Failed!');
        dispatch({
          type: orderConstants.UPDATE_CUSTOMER_ORDER_FAILURE,
          payload: { error },
        });
      }
    } catch (error) {
      toast.error('Status update Failed!');
    }
  };
};

export const updateTrackingId = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_TRACKING_ID_REQUEST });
    try {
      const res = await axios.put(`/admin/orders/${payload.orderId}/trackingid`, payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_TRACKING_ID_SUCCESS });
        // dispatch(getCustomerOrders());
        dispatch(getInitialDataAdmin());
        toast.success('Tracking ID updated!');
      } else {
        const { error } = res.data;
        dispatch({
          type: orderConstants.UPDATE_TRACKING_ID_FAILURE,
          payload: { error },
        });
        toast.success('Tracking ID update failed!');
      }
    } catch (error) {
      toast.success('Tracking ID update failed!');
    }
  };
};

export const getTrackingDetails = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_TRACKING_DETAILS_REQUEST });
    try {
      const res = await axios.get(`/orders/tracking/${payload.trackingId}`);
      if (res.status === 201) {
        dispatch({
          type: orderConstants.GET_TRACKING_DETAILS_SUCCESS,
          payload: res.data,
        });
        toast.success('Tracking details fetched!');
      } else {
        const { error } = res.data;
        dispatch({
          type: orderConstants.GET_TRACKING_DETAILS_FAILURE,
          payload: { error },
        });
        toast.success('Tracking details fetching failed!');
      }
    } catch (error) {
      toast.success('Tracking details fetching failed!');
    }
  };
};

export const getOrderReport = (fromDate, toDate) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_REPORT_REQUEST });
    try {
      setTimeout(() => {
        dispatch({
          type: orderConstants.GET_REPORT_SUCCESS,
          payload: { fromDate: fromDate, toDate: toDate },
        });
        toast.success(`Report fetched successfully.`);
      }, 500);
    } catch (error) {
      toast.error('Report fetching failed');
      dispatch({
        type: orderConstants.GET_REPORT_FAILURE,
        payload: { error: error },
      });
    }
  };
};
