import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../../components/CustomInput';
import { Dialog, DialogContent, DialogTitle, Typography } from '../../../../components/Dialog';
import {
  updateBlouse,
  updateColour,
  updateFCount,
  updateFabric,
  updateName,
  updatePictures,
  updateShade,
  updateSize,
} from '../../../../redux/actions';
import { mainColour2 } from '../../../../utils';
import {
  MultipleFileUploadField,
  img,
  thumb,
  thumbInner,
  thumbsContainer,
} from '../fileUpload/MultipleFileUploadField';

const UpdateProductDialogbox = (props) => {
  const dispatch = useDispatch();
  const fabricList = ['Cotton', 'Silk', 'Tussar', ''];
  const fabricCountList = [
    '4Ply x 3Ply',
    '3Ply x 2Ply',
    '120',
    '100',
    '80',
    '60',
    '40',
    'Tussar Silk',
    '',
  ];
  const product = useSelector((state) => state.product);
  const sizeList = [...useSelector((state) => state.product.sizes.map((item) => item.size)), ''];
  const colourList = [
    ...useSelector((state) => state.product.colours.map((item) => item.colour)),
    '',
  ];
  const shadeList = [...useSelector((state) => state.product.shades.map((item) => item.shade)), ''];

  const {
    updateProductDialog,
    setUpdateProductDialog,
    updateProductForm,
    myProduct,
    itemQuantity,
    setItemQuantity,
    itemOffer,
    setItemOffer,
    itemCPrice,
    setItemCPrice,
  } = props;
  const [newName, setNewName] = useState(myProduct.name);
  const handleNewNameSubmit = async () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.name = newName;
    dispatch(updateName(form));
  };
  const [newSize, setNewSize] = useState(myProduct.size);
  const handleNewSizeSubmit = () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.size = newSize;
    dispatch(updateSize(form));
  };
  const [newColour, setNewColour] = useState(myProduct.colour);
  const handleNewColourSubmit = () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.colour = newColour;
    dispatch(updateColour(form));
  };
  const [newShade, setNewShade] = useState(myProduct.shade);
  const handleNewShadeSubmit = () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.shade = newShade;
    dispatch(updateShade(form));
  };
  const [newBlouse, setNewBlouse] = useState(myProduct.blouse);
  const handleNewBlouseSubmit = () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.blouse = newBlouse;
    dispatch(updateBlouse(form));
  };
  const [newFabric, setNewFabric] = useState(myProduct.fabric);
  const handleNewFabricSubmit = () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.fabric = newFabric;
    dispatch(updateFabric(form));
  };
  const [newFCount, setNewFCount] = useState(myProduct.fabricCount);
  const handleNewFCountSubmit = () => {
    let form = {};
    form.prodCode = myProduct.prodCode;
    form.fcount = newFCount;
    dispatch(updateFCount(form));
  };

  const [productPictures, setProductPictures] = useState([]);
  const handleProductPictures = (files) => {
    setProductPictures([...files]);
  };

  const updateProductPictures = () => {
    let form = new FormData();
    form.append('prodCode', myProduct.prodCode);
    for (let pic of productPictures) {
      form.append('productPicture', pic);
    }
    dispatch(updatePictures(form));
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      onClose={() => setUpdateProductDialog(false)}
      aria-labelledby='updateProductDialogBox'
      open={updateProductDialog}
    >
      <DialogTitle id='updateProductDialogBox' onClose={() => setUpdateProductDialog(false)}>
        Update Product
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant='h6'>
          {myProduct.name} {myProduct.prodCode}
        </Typography>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '20px' }}
        >
          <Grid item xs={12} sm={8}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={12} sm={7}>
                {/* <img src={myProduct.productPictures.img} alt="Missing" width='80%' height='70%' /> */}
                <div style={thumbsContainer}>
                  {myProduct.productPictures.map((item) => (
                    <div style={thumb} key={item._id}>
                      <div style={thumbInner}>
                        <img src={item.img} style={img} />
                      </div>
                    </div>
                  ))}
                </div>
                <MultipleFileUploadField handleProductPictures={handleProductPictures} />
                <Button
                  autoFocus
                  onClick={updateProductPictures}
                  style={{ backgroundColor: mainColour2, color: 'white' }}
                >
                  {!product.loading ? (
                    'Update Pictures'
                  ) : (
                    <CircularProgress size={25} style={{ color: 'white' }} />
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomInput
                  fullWidth
                  label='Quantity'
                  placeholder='Quantity'
                  value={itemQuantity}
                  type='text'
                  onChange={(e) => setItemQuantity(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
                <CustomInput
                  fullWidth
                  label='Offer'
                  placeholder='Offer'
                  value={itemOffer}
                  type='text'
                  onChange={(e) => setItemOffer(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
                <CustomInput
                  fullWidth
                  label='Cost Price'
                  placeholder='Cost Price'
                  value={itemCPrice}
                  type='text'
                  onChange={(e) => setItemCPrice(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />
                <Button
                  autoFocus
                  onClick={updateProductForm}
                  style={{ backgroundColor: mainColour2, color: 'white' }}
                >
                  {!product.loading ? (
                    'Save changes'
                  ) : (
                    <CircularProgress size={25} style={{ color: 'white' }} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column' justifyContent='space-between' alignItems='center'>
              <FormControl variant='outlined' style={{ marginTop: '10px' }}>
                <InputLabel htmlFor='new-Name'>Update Name</InputLabel>
                <OutlinedInput
                  id='new-Name'
                  type='text'
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewNameSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </InputAdornment>
                  }
                  label='Update Name'
                />
              </FormControl>
              <FormControl variant='outlined' style={{ marginTop: '10px' }}>
                <InputLabel htmlFor='new-Blouse'>Update Blouse</InputLabel>
                <OutlinedInput
                  id='new-Blouse'
                  type='text'
                  value={newBlouse}
                  onChange={(e) => setNewBlouse(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewBlouseSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </InputAdornment>
                  }
                  label='Update Blouse'
                />
              </FormControl>
              <Autocomplete
                autoHighlight
                style={{ width: '58%', marginTop: '10px' }}
                id='size_select'
                options={sizeList}
                getOptionLabel={(option) => option}
                value={newSize}
                onChange={(e, value) => setNewSize(value)}
                renderInput={(params) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <TextField {...params} label='Update Size' variant='outlined' />
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewSizeSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </div>
                  );
                }}
              />
              <Autocomplete
                autoHighlight
                style={{ width: '58%', marginTop: '10px' }}
                id='colour_select'
                options={colourList}
                getOptionLabel={(option) => option}
                value={newColour}
                onChange={(e, value) => setNewColour(value)}
                renderInput={(params) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <TextField {...params} label='Update Colour' variant='outlined' />
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewColourSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </div>
                  );
                }}
              />
              <Autocomplete
                autoHighlight
                style={{ width: '58%', marginTop: '10px' }}
                id='shade_select'
                options={shadeList}
                getOptionLabel={(option) => option}
                value={newShade}
                onChange={(e, value) => setNewShade(value)}
                renderInput={(params) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <TextField {...params} label='Update Shade' variant='outlined' />
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewShadeSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </div>
                  );
                }}
              />
              <Autocomplete
                autoHighlight
                style={{ width: '58%', marginTop: '10px' }}
                id='fabric_select'
                options={fabricList}
                getOptionLabel={(option) => option}
                value={newFabric}
                onChange={(e, value) => setNewFabric(value)}
                renderInput={(params) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <TextField {...params} label='Update Fabric' variant='outlined' />
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewFabricSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </div>
                  );
                }}
              />
              <Autocomplete
                autoHighlight
                style={{ width: '58%', marginTop: '10px' }}
                id='fcount_select'
                options={fabricCountList}
                getOptionLabel={(option) => option}
                value={newFCount}
                onChange={(e, value) => setNewFCount(value)}
                renderInput={(params) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <TextField {...params} label='Update F count    ' variant='outlined' />
                      <Button
                        aria-label='publish'
                        component='span'
                        style={{ backgroundColor: mainColour2, color: 'white' }}
                        onClick={handleNewFCountSubmit}
                      >
                        {!product.loading ? (
                          <PublishIcon />
                        ) : (
                          <CircularProgress size={25} style={{ color: 'white' }} />
                        )}
                      </Button>
                    </div>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateProductDialogbox;
