const checkState = (state) => {
  return [
    'ODISHA',
    'ORISSA',
    'Odisha',
    'Odisha (OD)',
    'Odisha (OR)',
    'Orissa',
    'Orissa (OR)',
    'Orissa (OD)',
    'odisha',
    'orissa',
  ].includes(state.trim());
};

export default checkState;
