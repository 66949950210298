import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import CustomInput from '../../../../components/CustomInput';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../../components/Dialog';
import { mainColour2, secColour } from '../../../../utils';

const AddCategoryDialog = (props) => {
  const {
    onClose,
    open,
    onClick,
    categoryName,
    setCategoryName,
    categoryHSN,
    setCategoryHSN,
    categoryGST,
    setCategoryGST,
    categoryList,
    parentCategoryId,
    setParentCategoryId,
    handleCategoryImage,
  } = props;

  const gstArray = ['5', '12', '512', '0'];

  const [file, setFile] = useState([]);
  function uploadSingleFile(e) {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Create New Category</DialogTitle>
      <DialogContent dividers>
        <FormControl variant='outlined' className='form-control' style={{ marginBottom: '30px' }}>
          <InputLabel id='add_category_select'>Parent Category</InputLabel>
          <Select
            labelId='add_category_select'
            label='Parent Category'
            value={parentCategoryId}
            onChange={(e) => setParentCategoryId(e.target.value)}
          >
            {categoryList.map((item, index) =>
              item.parentId ? (
                <MenuItem
                  disabled
                  key={index}
                  value={item.value}
                  style={{
                    backgroundColor: secColour,
                    color: 'black',
                    justifyContent: 'flex-end',
                  }}
                >
                  {item.name}
                </MenuItem>
              ) : (
                <MenuItem
                  key={index}
                  value={item.value}
                  style={{ backgroundColor: mainColour2, color: 'white', dispaly: 'flex' }}
                >
                  {item.name}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <CustomInput
          fullWidth
          label='Category Name'
          placeholder='Category Name'
          value={categoryName}
          type='text'
          onChange={(e) => setCategoryName(e.target.value)}
          eyeIcon={false}
          showInputValue={true}
        />
        {categoryName === '' && (
          <Typography
            variant='body2'
            style={{
              color: 'red',
              fontWeight: 600,
              fontSize: '12px',
              marginTop: '-10px',
              marginBottom: '5px',
            }}
          >
            * Name is required
          </Typography>
        )}

        {parentCategoryId !== '' && (
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={6} md={6}>
              <CustomInput
                fullWidth
                label='HSN Code'
                placeholder='HSN Code'
                value={categoryHSN}
                type='text'
                onChange={(e) => setCategoryHSN(e.target.value)}
                eyeIcon={false}
                showInputValue={true}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl
                variant='outlined'
                className='form-control'
                style={{ height: '100%', marginBottom: '8px' }}
              >
                <InputLabel id='category_gst_select'>GST %</InputLabel>
                <Select
                  labelId='category_gst_select'
                  label='GST %'
                  value={categoryGST}
                  onChange={(e) => setCategoryGST(e.target.value)}
                >
                  {gstArray.map((item, idx) => (
                    <MenuItem key={idx} value={item}>
                      {item}%
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={6} md={6}>
            {parentCategoryId !== '' && !categoryHSN.match(/[0-9]/g) && (
              <Typography
                style={{
                  color: 'red',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginTop: '-10px',
                  marginBottom: '5px',
                }}
              >
                * Only digits[0-9] are allowed
              </Typography>
            )}
            {parentCategoryId !== '' && categoryHSN.length > 8 && (
              <Typography
                style={{
                  color: 'red',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginTop: '-10px',
                  marginBottom: '5px',
                }}
              >
                * Must not be greater than 8 digits long
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            {parentCategoryId !== '' && categoryGST === '' && (
              <Typography
                style={{
                  color: 'red',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginTop: '-10px',
                  marginBottom: '5px',
                }}
              >
                * GST% is required
              </Typography>
            )}
          </Grid>
        </Grid>

        <div>
          {file.length > 0 && (
            <div
              className='form-group preview'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {file.map((item, index) => {
                return <img src={item} key={index} alt='Missing' height='225px' width='195px' />;
              })}
            </div>
          )}
        </div>

        <label htmlFor='upload-photo' style={{ width: '100%' }}>
          <input
            style={{ display: 'none' }}
            id='upload-photo'
            name='upload-photo'
            type='file'
            onChange={(e) => {
              uploadSingleFile(e);
              handleCategoryImage(e);
            }}
          />

          <Button fullWidth color='primary' variant='contained' component='span'>
            Upload
          </Button>
        </label>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={onClick}
          variant='contained'
          style={{ backgroundColor: mainColour2, color: 'white' }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDialog;
