import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { journalConstants } from './constants';

export const getAllJournals = (payload) => {
  return async (dispatch) => {
    //dispatch a get product request
    dispatch({ type: journalConstants.GET_ALL_JOURNALS_REQUEST });

    axios
      .get(`/journals/${payload.year}`)
      .then((response) => {
        const { journals } = response.data;
        dispatch({
          type: journalConstants.GET_ALL_JOURNALS_SUCCESS,
          payload: { journals: journals },
        });
      })
      .catch((error) => {
        dispatch({
          type: journalConstants.GET_ALL_JOURNALS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addEntry = (payload, date) => {
  return async (dispatch) => {
    dispatch({ type: journalConstants.ADD_NEW_JOURNAL_REQUEST });
    try {
      setTimeout(() => {
        dispatch({
          type: journalConstants.ADD_NEW_JOURNAL_SUCCESS,
          payload: { journal: payload, date: date },
        });
      }, 500);
    } catch (error) {
      dispatch({
        type: journalConstants.ADD_NEW_JOURNAL_FAILURE,
        payload: { error: error },
      });
      toast.error('Journal record creation failed! Please try later');
    }
  };
};

export const addJournals = (payload, setNarration, setDate, year) => {
  return async (dispatch) => {
    dispatch({ type: journalConstants.ADD_NEW_GROUP_JOURNAL_REQUEST });
    axios
      .post(`/journals`, payload)
      .then((response) => {
        setNarration('');
        setDate(null);
        const { newVoucherNum } = response.data;
        dispatch({ type: journalConstants.ADD_NEW_GROUP_JOURNAL_SUCCESS });
        dispatch({
          type: journalConstants.GET_NEW_VOUCHER_NUM_SUCCESS,
          payload: { voucherNum: newVoucherNum },
        });

        dispatch(getAllJournals({ year: year }));
        toast.success('New journal added successfully!');
      })
      .catch((error) => {
        dispatch({
          type: journalConstants.ADD_NEW_GROUP_JOURNAL_FAILURE,
          payload: { error },
        });
        toast.error('Journals not added to database! Please try later');
      });
  };
};

export const deleteEntry = (index, year) => {
  return async (dispatch) => {
    dispatch({ type: journalConstants.DELETE_JOURNAL_REQUEST });
    try {
      setTimeout(() => {
        dispatch({
          type: journalConstants.DELETE_JOURNAL_SUCCESS,
          payload: { index: index },
        });
        toast.success('Journal deleted successfully!');
      }, 500);
      getAllJournals({ year: year });
    } catch (error) {
      dispatch({
        type: journalConstants.DELETE_JOURNAL_FAILURE,
        payload: { error: error },
      });
      toast.error('Journal deletion failed! Please try later');
    }
  };
};
export const editEntry = (payload, year) => {
  return async (dispatch) => {
    dispatch({ type: journalConstants.EDIT_ENTRY_REQUEST });
    try {
      setTimeout(() => {
        dispatch({
          type: journalConstants.EDIT_ENTRY_SUCCESS,
          payload: payload,
        });
        toast.success('Entry updated successfully!');
      }, 500);
      getAllJournals({ year: year });
    } catch (error) {
      dispatch({
        type: journalConstants.EDIT_ENTRY_FAILURE,
        payload: { error: error },
      });
      toast.error('Journal deletion failed! Please try later');
    }
  };
};

export const updateJournal = (payload, year) => {
  return async (dispatch) => {
    dispatch({ type: journalConstants.UPDATE_JOURNAL_REQUEST });
    axios
      .put(`/journals/${payload.voucherNum}`, payload)
      .then(() => {
        dispatch({ type: journalConstants.UPDATE_JOURNAL_SUCCESS });
        dispatch(getAllJournals({ year: year }));
        toast.success('Journal record updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: journalConstants.UPDATE_JOURNAL_FAILURE,
          payload: { error },
        });
        toast.error('Journal record update failed! Please try later');
      });
  };
};

export const deleteJournal = (payload, year) => {
  return async (dispatch) => {
    dispatch({ type: journalConstants.UPDATE_JOURNAL_REQUEST });
    axios
      .delete(`/journals/${payload.voucherNum}`)
      .then((response) => {
        dispatch({ type: journalConstants.UPDATE_JOURNAL_SUCCESS });
        const { newVoucherNum } = response.data;
        dispatch({
          type: journalConstants.GET_NEW_VOUCHER_NUM_SUCCESS,
          payload: { voucherNum: newVoucherNum },
        });
        dispatch(getAllJournals({ year: year }));
        toast.success('Journal deleted successfully!');
      })
      .catch((error) => {
        dispatch({
          type: journalConstants.UPDATE_JOURNAL_FAILURE,
          payload: { error },
        });
        toast.error('Journal delete failed! Please try later');
      });
  };
};
