export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export const userConstants = {
  USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
};

export const categoryConstants = {
  GET_ALL_CATEGORIES_REQUEST: 'GET_ALL_CATEGORIES_REQUEST',
  GET_ALL_CATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_SUCCESS',
  GET_ALL_CATEGORIES_FAILURE: 'GET_ALL_CATEGORIES_FAILURE',
  ADD_NEW_CATEGORY_REQUEST: 'ADD_NEW_CATEGORY_REQUEST',
  ADD_NEW_CATEGORY_SUCCESS: 'ADD_NEW_CATEGORY_SUCCESS',
  ADD_NEW_CATEGORY_FAILURE: 'ADD_NEW_CATEGORY_FAILURE',
  UPDATE_CATEGORIES_REQUEST: 'UPDATE_CATEGORIES_REQUEST',
  UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_FAILURE: 'UPDATE_CATEGORIES_FAILURE',
  DELETE_CATEGORIES_REQUEST: 'DELETE_CATEGORIES_REQUEST',
  DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
  DELETE_CATEGORIES_FAILURE: 'DELETE_CATEGORIES_FAILURE',

  UPDATE_PRODUCTS_BY_CATEGORY_REQUEST: 'UPDATE_PRODUCTS_BY_CATEGORY_REQUEST',
  UPDATE_PRODUCTS_BY_CATEGORY_SUCCESS: 'UPDATE_PRODUCTS_BY_CATEGORY_SUCCESS',
  UPDATE_PRODUCTS_BY_CATEGORY_FAILURE: 'UPDATE_PRODUCTS_BY_CATEGORY_FAILURE',
};

export const productConstants = {
  GET_ALL_PRODUCTS_REQUEST: 'GET_ALL_PRODUCTS_REQUEST',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',
  ADD_NEW_PRODUCT_REQUEST: 'ADD_NEW_PRODUCT_REQUEST',
  ADD_NEW_PRODUCT_SUCCESS: 'ADD_NEW_PRODUCT_SUCCESS',
  ADD_NEW_PRODUCT_FAILURE: 'ADD_NEW_PRODUCT_FAILURE',
  UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE: 'UPDATE_PRODUCT_FAILURE',
  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',

  ADD_NEW_SIZE_REQUEST: 'ADD_NEW_SIZE_REQUEST',
  ADD_NEW_SIZE_SUCCESS: 'ADD_NEW_SIZE_SUCCESS',
  ADD_NEW_SIZE_FAILURE: 'ADD_NEW_SIZE_FAILURE',
  ADD_NEW_COLOUR_REQUEST: 'ADD_NEW_COLOUR_REQUEST',
  ADD_NEW_COLOUR_SUCCESS: 'ADD_NEW_COLOUR_SUCCESS',
  ADD_NEW_COLOUR_FAILURE: 'ADD_NEW_COLOUR_FAILURE',
  ADD_NEW_SHADE_REQUEST: 'ADD_NEW_SHADE_REQUEST',
  ADD_NEW_SHADE_SUCCESS: 'ADD_NEW_SHADE_SUCCESS',
  ADD_NEW_SHADE_FAILURE: 'ADD_NEW_SHADE_FAILURE',

  GET_ALL_SIZES_SUCCESS: 'GET_ALL_SIZES_SUCCESS',
  GET_ALL_COLOURS_SUCCESS: 'GET_ALL_COLOURS_SUCCESS',
  GET_ALL_SHADES_SUCCESS: 'GET_ALL_SHADES_SUCCESS',
};

export const accountNameConstants = {
  GET_ALL_ACCOUNT_NAMES_REQUEST: 'GET_ALL_ACCOUNT_NAMES_REQUEST',
  GET_ALL_ACCOUNT_NAMES_SUCCESS: 'GET_ALL_ACCOUNT_NAMES_SUCCESS',
  GET_ALL_ACCOUNT_NAMES_FAILURE: 'GET_ALL_ACCOUNT_NAMES_FAILURE',
  ADD_NEW_ACCOUNT_NAME_REQUEST: 'ADD_NEW_ACCOUNT_NAME_REQUEST',
  ADD_NEW_ACCOUNT_NAME_SUCCESS: 'ADD_NEW_ACCOUNT_NAME_SUCCESS',
  ADD_NEW_ACCOUNT_NAME_FAILURE: 'ADD_NEW_ACCOUNT_NAME_FAILURE',
  UPDATE_ACCOUNT_NAME_REQUEST: 'UPDATE_ACCOUNT_NAME_REQUEST',
  UPDATE_ACCOUNT_NAME_SUCCESS: 'UPDATE_ACCOUNT_NAME_SUCCESS',
  UPDATE_ACCOUNT_NAME_FAILURE: 'UPDATE_ACCOUNT_NAME_FAILURE',
  DELETE_ACCOUNT_NAME_REQUEST: 'DELETE_ACCOUNT_NAME_REQUEST',
  DELETE_ACCOUNT_NAME_SUCCESS: 'DELETE_ACCOUNT_NAME_SUCCESS',
  DELETE_ACCOUNT_NAME_FAILURE: 'DELETE_ACCOUNT_NAME_FAILURE',
};
export const accountSubnameConstants = {
  GET_ALL_ACCOUNT_SUBNAMES_REQUEST: 'GET_ALL_ACCOUNT_SUBNAMES_REQUEST',
  GET_ALL_ACCOUNT_SUBNAMES_SUCCESS: 'GET_ALL_ACCOUNT_SUBNAMES_SUCCESS',
  GET_ALL_ACCOUNT_SUBNAMES_FAILURE: 'GET_ALL_ACCOUNT_SUBNAMES_FAILURE',
  ADD_NEW_ACCOUNT_SUBNAME_REQUEST: 'ADD_NEW_ACCOUNT_SUBNAME_REQUEST',
  ADD_NEW_ACCOUNT_SUBNAME_SUCCESS: 'ADD_NEW_ACCOUNT_SUBNAME_SUCCESS',
  ADD_NEW_ACCOUNT_SUBNAME_FAILURE: 'ADD_NEW_ACCOUNT_SUBNAME_FAILURE',
  UPDATE_ACCOUNT_SUBNAME_REQUEST: 'UPDATE_ACCOUNT_SUBNAME_REQUEST',
  UPDATE_ACCOUNT_SUBNAME_SUCCESS: 'UPDATE_ACCOUNT_SUBNAME_SUCCESS',
  UPDATE_ACCOUNT_SUBNAME_FAILURE: 'UPDATE_ACCOUNT_SUBNAME_FAILURE',
  DELETE_ACCOUNT_SUBNAME_REQUEST: 'DELETE_ACCOUNT_SUBNAME_REQUEST',
  DELETE_ACCOUNT_SUBNAME_SUCCESS: 'DELETE_ACCOUNT_SUBNAME_SUCCESS',
  DELETE_ACCOUNT_SUBNAME_FAILURE: 'DELETE_ACCOUNT_SUBNAME_FAILURE',
};
export const accountGroupHeadConstants = {
  GET_ALL_ACCOUNT_GROUP_HEADS_REQUEST: 'GET_ALL_ACCOUNT_GROUP_HEADS_REQUEST',
  GET_ALL_ACCOUNT_GROUP_HEADS_SUCCESS: 'GET_ALL_ACCOUNT_GROUP_HEADS_SUCCESS',
  GET_ALL_ACCOUNT_GROUP_HEADS_FAILURE: 'GET_ALL_ACCOUNT_GROUP_HEADS_FAILURE',
  ADD_NEW_ACCOUNT_GROUP_HEAD_REQUEST: 'ADD_NEW_ACCOUNT_GROUP_HEAD_REQUEST',
  ADD_NEW_ACCOUNT_GROUP_HEAD_SUCCESS: 'ADD_NEW_ACCOUNT_GROUP_HEAD_SUCCESS',
  ADD_NEW_ACCOUNT_GROUP_HEAD_FAILURE: 'ADD_NEW_ACCOUNT_GROUP_HEAD_FAILURE',
};

export const accountHeadConstants = {
  GET_ALL_ACCOUNT_HEADS_REQUEST: 'GET_ALL_ACCOUNT_HEADS_REQUEST',
  GET_ALL_ACCOUNT_HEADS_SUCCESS: 'GET_ALL_ACCOUNT_HEADS_SUCCESS',
  GET_ALL_ACCOUNT_HEADS_FAILURE: 'GET_ALL_ACCOUNT_HEADS_FAILURE',
  ADD_NEW_ACCOUNT_HEAD_REQUEST: 'ADD_NEW_ACCOUNT_HEAD_REQUEST',
  ADD_NEW_ACCOUNT_HEAD_SUCCESS: 'ADD_NEW_ACCOUNT_HEAD_SUCCESS',
  ADD_NEW_ACCOUNT_HEAD_FAILURE: 'ADD_NEW_ACCOUNT_HEAD_FAILURE',
  UPDATE_ACCOUNT_HEAD_REQUEST: 'UPDATE_ACCOUNT_HEAD_REQUEST',
  UPDATE_ACCOUNT_HEAD_SUCCESS: 'UPDATE_ACCOUNT_HEAD_SUCCESS',
  UPDATE_ACCOUNT_HEAD_FAILURE: 'UPDATE_ACCOUNT_HEAD_FAILURE',
};

export const pnlHeadConstants = {
  GET_ALL_PNL_HEADS_REQUEST: 'GET_ALL_PNL_HEADS_REQUEST',
  GET_ALL_PNL_HEADS_SUCCESS: 'GET_ALL_PNL_HEADS_SUCCESS',
  GET_ALL_PNL_HEADS_FAILURE: 'GET_ALL_PNL_HEADS_FAILURE',
  ADD_NEW_PNL_HEAD_REQUEST: 'ADD_NEW_PNL_HEAD_REQUEST',
  ADD_NEW_PNL_HEAD_SUCCESS: 'ADD_NEW_PNL_HEAD_SUCCESS',
  ADD_NEW_PNL_HEAD_FAILURE: 'ADD_NEW_PNL_HEAD_FAILURE',
  UPDATE_PNL_HEAD_REQUEST: 'UPDATE_PNL_HEAD_REQUEST',
  UPDATE_PNL_HEAD_SUCCESS: 'UPDATE_PNL_HEAD_SUCCESS',
  UPDATE_PNL_HEAD_FAILURE: 'UPDATE_PNL_HEAD_FAILURE',
};

export const buyerSellerConstants = {
  GET_ALL_BUYER_SELLERS_REQUEST: 'GET_ALL_BUYER_SELLERS_REQUEST',
  GET_ALL_BUYER_SELLERS_SUCCESS: 'GET_ALL_BUYER_SELLERS_SUCCESS',
  GET_ALL_BUYER_SELLERS_FAILURE: 'GET_ALL_BUYER_SELLERS_FAILURE',
  ADD_NEW_BUYER_SELLER_REQUEST: 'ADD_NEW_BUYER_SELLER_REQUEST',
  ADD_NEW_BUYER_SELLER_SUCCESS: 'ADD_NEW_BUYER_SELLER_SUCCESS',
  ADD_NEW_BUYER_SELLER_FAILURE: 'ADD_NEW_BUYER_SELLER_FAILURE',
  UPDATE_BUYER_SELLER_REQUEST: 'UPDATE_BUYER_SELLER_REQUEST',
  UPDATE_BUYER_SELLER_SUCCESS: 'UPDATE_BUYER_SELLER_SUCCESS',
  UPDATE_BUYER_SELLER_FAILURE: 'UPDATE_BUYER_SELLER_FAILURE',
};

export const initialDataConstants = {
  GET_ALL_INITIAL_DATA_REQUEST: 'GET_ALL_INITIAL_DATA_REQUEST',
  GET_ALL_INITIAL_DATA_SUCCESS: 'GET_ALL_INITIAL_DATA_SUCCESS',
  GET_ALL_INITIAL_DATA_FAILURE: 'GET_ALL_INITIAL_DATA_FAILURE',
};

export const orderConstants = {
  GET_CUSTOMER_ORDER_REQUEST: 'GET_CUSTOMER_ORDER_REQUEST',
  GET_CUSTOMER_ORDER_SUCCESS: 'GET_CUSTOMER_ORDER_SUCCESS',
  GET_CUSTOMER_ORDER_FAILURE: 'GET_CUSTOMER_ORDER_FAILURE',

  UPDATE_CUSTOMER_ORDER_REQUEST: 'UPDATE_CUSTOMER_ORDER_REQUEST',
  UPDATE_CUSTOMER_ORDER_SUCCESS: 'UPDATE_CUSTOMER_ORDER_SUCCESS',
  UPDATE_CUSTOMER_ORDER_FAILURE: 'UPDATE_CUSTOMER_ORDER_FAILURE',

  UPDATE_TRACKING_ID_REQUEST: 'UPDATE_TRACKING_ID_REQUEST',
  UPDATE_TRACKING_ID_SUCCESS: 'UPDATE_TRACKING_ID_SUCCESS',
  UPDATE_TRACKING_ID_FAILURE: 'UPDATE_TRACKING_ID_FAILURE',

  GET_TRACKING_DETAILS_REQUEST: 'GET_TRACKING_DETAILS_REQUEST',
  GET_TRACKING_DETAILS_SUCCESS: 'GET_TRACKING_DETAILS_SUCCESS',
  GET_TRACKING_DETAILS_FAILURE: 'GET_TRACKING_DETAILS_FAILURE',

  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
};
export const pageConstants = {
  CREATE_PAGE_REQUEST: 'CREATE_PAGE_REQUEST',
  CREATE_PAGE_SUCCESS: 'CREATE_PAGE_SUCCESS',
  CREATE_PAGE_FAILURE: 'CREATE_PAGE_FAILURE',

  GET_ALL_PAGES_SUCCESS: 'GET_ALL_PAGES_SUCCESS',
  GET_ALL_PAGES_FAILURE: 'GET_ALL_PAGES_FAILURE',
};

export const carouselConstants = {
  GET_TRUE_CAROUSELS_REQUEST: 'GET_TRUE_CAROUSELS_REQUEST',
  GET_TRUE_CAROUSELS_SUCCESS: 'GET_TRUE_CAROUSELS_SUCCESS',
  GET_TRUE_CAROUSELS_FAILURE: 'GET_TRUE_CAROUSELS_FAILURE',

  GET_ALL_CAROUSELS_REQUEST: 'GET_ALL_CAROUSELS_REQUEST',
  GET_ALL_CAROUSELS_SUCCESS: 'GET_ALL_CAROUSELS_SUCCESS',
  GET_ALL_CAROUSELS_FAILURE: 'GET_ALL_CAROUSELS_FAILURE',

  ADD_CAROUSELS_REQUEST: 'ADD_CAROUSELS_REQUEST',
  ADD_CAROUSELS_SUCCESS: 'ADD_CAROUSELS_SUCCESS',
  ADD_CAROUSELS_FAILURE: 'ADD_CAROUSELS_FAILURE',
};

export const usersConstants = {
  GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',
  GET_ALL_USERS_ADDRESSES_REQUEST: 'GET_ALL_USERS_ADDRESSES_REQUEST',
  GET_ALL_USERS_ADDRESSES_SUCCESS: 'GET_ALL_USERS_ADDRESSES_SUCCESS',
  GET_ALL_USERS_ADDRESSES_FAILURE: 'GET_ALL_USERS_ADDRESSES_FAILURE',

  APPROVE_AS_RESELLER_REQUEST: 'APPROVE_AS_RESELLER_REQUEST',
  APPROVE_AS_RESELLER_SUCCESS: 'APPROVE_AS_RESELLER_SUCCESS',
  APPROVE_AS_RESELLER_FAILURE: 'APPROVE_AS_RESELLER_FAILURE',

  UPDATE_RESELLER_OFFER_REQUEST: 'UPDATE_RESELLER_OFFER_REQUEST',
  UPDATE_RESELLER_OFFER_SUCCESS: 'UPDATE_RESELLER_OFFER_SUCCESS',
  UPDATE_RESELLER_OFFER_FAILURE: 'UPDATE_RESELLER_OFFER_FAILURE',
};
export const subscribersConstants = {
  GET_ALL_SUBSCRIBERS_REQUEST: 'GET_ALL_SUBSCRIBERS_REQUEST',
  GET_ALL_SUBSCRIBERS_SUCCESS: 'GET_ALL_SUBSCRIBERS_SUCCESS',
  GET_ALL_SUBSCRIBERS_FAILURE: 'GET_ALL_SUBSCRIBERS_FAILURE',
};

export const cardConstants = {
  GET_TRUE_CARDS_REQUEST: 'GET_TRUE_CARDS_REQUEST',
  GET_TRUE_CARDS_SUCCESS: 'GET_TRUE_CARDS_SUCCESS',
  GET_TRUE_CARDS_FAILURE: 'GET_TRUE_CARDS_FAILURE',

  GET_ALL_CARDS_REQUEST: 'GET_ALL_CARDS_REQUEST',
  GET_ALL_CARDS_SUCCESS: 'GET_ALL_CARDS_SUCCESS',
  GET_ALL_CARDS_FAILURE: 'GET_ALL_CARDS_FAILURE',

  ADD_CARD_REQUEST: 'ADD_CARD_REQUEST',
  ADD_CARD_SUCCESS: 'ADD_CARD_SUCCESS',
  ADD_CARD_FAILURE: 'ADD_CARD_FAILURE',
};

export const couponConstants = {
  GET_ALL_COUPONS_REQUEST: 'GET_ALL_COUPONS_REQUEST',
  GET_ALL_COUPONS_SUCCESS: 'GET_ALL_COUPONS_SUCCESS',
  GET_ALL_COUPONS_FAILURE: 'GET_ALL_COUPONS_FAILURE',

  ADD_NEW_COUPON_REQUEST: 'ADD_NEW_COUPON_REQUEST',
  ADD_NEW_COUPON_SUCCESS: 'ADD_NEW_COUPON_SUCCESS',
  ADD_NEW_COUPON_FAILURE: 'ADD_NEW_COUPON_FAILURE',

  UPDATE_COUPON_REQUEST: 'UPDATE_COUPON_REQUEST',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILURE: 'UPDATE_COUPON_FAILURE',
};

export const cartConstants = {
  GET_ALL_CARTS_REQUEST: 'GET_ALL_CARTS_REQUEST',
  GET_ALL_CARTS_SUCCESS: 'GET_ALL_CARTS_SUCCESS',
  GET_ALL_CARTS_FAILURE: 'GET_ALL_CARTS_FAILURE',
};
export const cashConstants = {
  GET_ALL_CASHES_REQUEST: 'GET_ALL_CASHES_REQUEST',
  GET_ALL_CASHES_SUCCESS: 'GET_ALL_CASHES_SUCCESS',
  GET_ALL_CASHES_FAILURE: 'GET_ALL_CASHES_FAILURE',
  ADD_NEW_CASH_REQUEST: 'ADD_NEW_CASH_REQUEST',
  ADD_NEW_CASH_SUCCESS: 'ADD_NEW_CASH_SUCCESS',
  ADD_NEW_CASH_FAILURE: 'ADD_NEW_CASH_FAILURE',
  UPDATE_CASH_REQUEST: 'UPDATE_CASH_REQUEST',
  UPDATE_CASH_SUCCESS: 'UPDATE_CASH_SUCCESS',
  UPDATE_CASH_FAILURE: 'UPDATE_CASH_FAILURE',
  DELETE_CASH_REQUEST: 'DELETE_CASH_REQUEST',
  DELETE_CASH_SUCCESS: 'DELETE_CASH_SUCCESS',
  DELETE_CASH_FAILURE: 'DELETE_CASH_FAILURE',
};

export const journalConstants = {
  GET_ALL_JOURNALS_REQUEST: 'GET_ALL_JOURNALS_REQUEST',
  GET_ALL_JOURNALS_SUCCESS: 'GET_ALL_JOURNALS_SUCCESS',
  GET_ALL_JOURNALS_FAILURE: 'GET_ALL_JOURNALS_FAILURE',
  ADD_NEW_JOURNAL_REQUEST: 'ADD_NEW_JOURNAL_REQUEST',
  ADD_NEW_JOURNAL_SUCCESS: 'ADD_NEW_JOURNAL_SUCCESS',
  ADD_NEW_JOURNAL_FAILURE: 'ADD_NEW_JOURNAL_FAILURE',
  ADD_NEW_GROUP_JOURNAL_REQUEST: 'ADD_NEW_GROUP_JOURNAL_REQUEST',
  ADD_NEW_GROUP_JOURNAL_SUCCESS: 'ADD_NEW_GROUP_JOURNAL_SUCCESS',
  ADD_NEW_GROUP_JOURNAL_FAILURE: 'ADD_NEW_GROUP_JOURNAL_FAILURE',
  UPDATE_JOURNAL_REQUEST: 'UPDATE_JOURNAL_REQUEST',
  UPDATE_JOURNAL_SUCCESS: 'UPDATE_JOURNAL_SUCCESS',
  UPDATE_JOURNAL_FAILURE: 'UPDATE_JOURNAL_FAILURE',
  EDIT_ENTRY_REQUEST: 'EDIT_ENTRY_REQUEST',
  EDIT_ENTRY_SUCCESS: 'EDIT_ENTRY_SUCCESS',
  EDIT_ENTRY_FAILURE: 'EDIT_ENTRY_FAILURE',
  DELETE_JOURNAL_REQUEST: 'DELETE_JOURNAL_REQUEST',
  DELETE_JOURNAL_SUCCESS: 'DELETE_JOURNAL_SUCCESS',
  DELETE_JOURNAL_FAILURE: 'DELETE_JOURNAL_FAILURE',
  GET_NEW_VOUCHER_NUM_REQUEST: 'GET_NEW_VOUCHER_NUM_REQUEST',
  GET_NEW_VOUCHER_NUM_SUCCESS: 'GET_NEW_VOUCHER_NUM_SUCCESS',
  GET_NEW_VOUCHER_NUM_FAILURE: 'GET_NEW_VOUCHER_NUM_FAILURE',
};

export const ledgerConstants = {
  GET_LEDGER_DETAILS_REQUEST: 'GET_LEDGER_DETAILS_REQUEST',
  GET_LEDGER_DETAILS_SUCCESS: 'GET_LEDGER_DETAILS_SUCCESS',
  GET_LEDGER_DETAILS_FAILURE: 'GET_LEDGER_DETAILS_FAILURE',

  VIEW_ALL_RECORDS_REQUEST: 'VIEW_ALL_RECORDS_REQUEST',
  VIEW_ALL_RECORDS_SUCCESS: 'VIEW_ALL_RECORDS_SUCCESS',
  HIDE_ALL_RECORDS_REQUEST: 'HIDE_ALL_RECORDS_REQUEST',
  HIDE_ALL_RECORDS_SUCCESS: 'HIDE_ALL_RECORDS_SUCCESS',
};

export const trialBalanceConstants = {
  GET_TRIAL_BALANCE_REQUEST: 'GET_TRIAL_BALANCE_REQUEST',
  GET_TRIAL_BALANCE_SUCCESS: 'GET_TRIAL_BALANCE_SUCCESS',
  GET_TRIAL_BALANCE_FAILURE: 'GET_TRIAL_BALANCE_FAILURE',
  SAVE_TRIAL_BALANCE_REQUEST: 'SAVE_TRIAL_BALANCE_REQUEST',
  SAVE_TRIAL_BALANCE_SUCCESS: 'SAVE_TRIAL_BALANCE_SUCCESS',
  SAVE_TRIAL_BALANCE_FAILURE: 'SAVE_TRIAL_BALANCE_FAILURE',

  SAVE_GROSS_PROFIT_CD_REQUEST: 'SAVE_GROSS_PROFIT_CD_REQUEST',
  SAVE_GROSS_PROFIT_CD_SUCCESS: 'SAVE_GROSS_PROFIT_CD_SUCCESS',
  SAVE_GROSS_PROFIT_CD_FAILURE: 'SAVE_GROSS_PROFIT_CD_FAILURE',

  SAVE_NET_PROFIT_CD_REQUEST: 'SAVE_NET_PROFIT_CD_REQUEST',
  SAVE_NET_PROFIT_CD_SUCCESS: 'SAVE_NET_PROFIT_CD_SUCCESS',
  SAVE_NET_PROFIT_CD_FAILURE: 'SAVE_NET_PROFIT_CD_FAILURE',
};

export const globalConstants = {
  SAVE_CURRENT_YEAR_REQUEST: 'SAVE_CURRENT_YEAR_REQUEST',
  SAVE_CURRENT_YEAR_SUCCESS: 'SAVE_CURRENT_YEAR_SUCCESS',
  SAVE_CURRENT_YEAR_FAILURE: 'SAVE_CURRENT_YEAR_FAILURE',

  SAVE_APP_NAME_REQUEST: 'SAVE_APP_NAME_REQUEST',
  SAVE_APP_NAME_SUCCESS: 'SAVE_APP_NAME_SUCCESS',
  SAVE_APP_NAME_FAILURE: 'SAVE_APP_NAME_FAILURE',
};
