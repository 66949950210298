import findOpeningBal from '../../../../../helpers/findOpeningBal';
import formatDate2 from '../../../../../helpers/formatDate2';

export default function calculateGroupwise(
  groupBySubname,
  accountName,
  accountNameRedux,
  year,
  fromDate,
  toDate,
  crdr,
) {
  let rowsGroupwise = [];
  let uuid = 0;

  for (const [key] of Object.entries(groupBySubname)) {
    // console.log(groupBySubname[key])
    let AccountName = groupBySubname[key][0].Account_Name;
    let Account_Head_Id = groupBySubname[key][0].Account_Head_Id;
    let groupwiseOB = 0;
    let groupwiseCB = 0;

    const debitTotalByGroup = Number(
      groupBySubname[key].reduce((a, b) => ({ Debit: Number(a.Debit) + Number(b.Debit) })).Debit,
    ).toFixed(2);
    const creditTotalByGroup = Number(
      groupBySubname[key].reduce((a, b) => ({ Credit: Number(a.Credit) + Number(b.Credit) }))
        .Credit,
    ).toFixed(2);

    if (groupBySubname[key][0].Account_Subname !== '') {
      const account = accountNameRedux.accountNames.find(
        (element) =>
          element.name === AccountName &&
          element.subname === groupBySubname[key][0].Account_Subname,
      );
      groupwiseOB = Number(findOpeningBal(account.openingBal, year));
      const diff = Number(
        crdr === 'Cr'
          ? Number(creditTotalByGroup) - Number(debitTotalByGroup)
          : Number(debitTotalByGroup) - Number(creditTotalByGroup),
      ).toFixed(2);
      groupwiseCB = Number((Number(groupwiseOB) + Number(diff)).toFixed(2));

      uuid++;
      rowsGroupwise.push({
        id: uuid,
        Account_Name: AccountName,
        Account_Subname: key,
        Account_Head_Id: Account_Head_Id,
        OP_Balance: groupwiseOB.toFixed(2),
        Debit: debitTotalByGroup,
        Credit: creditTotalByGroup,
        Date: `${formatDate2(fromDate)} - ${formatDate2(toDate)}`,
        CL_Balance: groupwiseCB.toFixed(2),
      });
    }
  }

  function findSubaccountsWithoutTransactions(array1, array2) {
    let diff = [];

    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i].Account_Subname !== array2[j].subname) {
          diff.push(array2[j]);
        }
      }
    }

    if (array1.length === 0) {
      // there is no grouping, because there is no transactions
      for (let j = 0; j < array2.length; j++) {
        diff.push(array2[j]);
      }
    }

    const mp = new Map();
    diff.forEach((item) => mp.set(item, !isNaN(mp.get(item)) ? mp.get(item) + 1 : 1));

    diff = []; // clear the array

    //get the elements which are present one time greater than others, bcoz of two for loops used above,
    //these items are not present in out table when they don't have any transactions
    let maxValue = -1;
    for (let [key, value] of mp) {
      if (value > maxValue) maxValue = value;
    }
    for (let [key, value] of mp) {
      if (value === maxValue) diff.push(key);
    }
    return diff;
  }
  const filteredAccounts = accountNameRedux.accountNames.filter((item) => {
    return item.name === accountName;
  });

  const subaccountsWithoutTransactions = findSubaccountsWithoutTransactions(
    rowsGroupwise,
    filteredAccounts,
  );

  subaccountsWithoutTransactions.forEach((item) => {
    rowsGroupwise.push({
      id: uuid,
      Account_Name: item.name,
      Account_Subname: item.subname,
      Account_Head_Id: item.accountHead._id,
      OP_Balance: findOpeningBal(item.openingBal, year),
      Debit: Number(0).toFixed(2),
      Credit: Number(0).toFixed(2),
      Date: `${formatDate2(fromDate)} - ${formatDate2(toDate)}`,
      CL_Balance: findOpeningBal(item.openingBal, year),
    });
  });

  // sort according to account subnames
  rowsGroupwise = rowsGroupwise.sort((a, b) =>
    a.Account_Subname > b.Account_Subname ? 1 : b.Account_Subname > a.Account_Subname ? -1 : 0,
  );

  //replace the ids of rows into a sorted numbers
  rowsGroupwise = rowsGroupwise.map((item, idx) => {
    return { ...item, id: idx + 1 };
  });

  const groupwiseOBTotal =
    rowsGroupwise.length > 1 &&
    rowsGroupwise.reduce((a, b) => ({ OP_Balance: Number(a.OP_Balance) + Number(b.OP_Balance) }))
      .OP_Balance;
  const groupwiseDebitTotal =
    rowsGroupwise.length > 1 &&
    rowsGroupwise.reduce((a, b) => ({ Debit: Number(a.Debit) + Number(b.Debit) })).Debit;
  const groupwiseCreditTotal =
    rowsGroupwise.length > 1 &&
    rowsGroupwise.reduce((a, b) => ({ Credit: Number(a.Credit) + Number(b.Credit) })).Credit;
  const groupwiseCBTotal =
    rowsGroupwise.length > 1 &&
    rowsGroupwise.reduce((a, b) => ({ CL_Balance: Number(a.CL_Balance) + Number(b.CL_Balance) }))
      .CL_Balance;

  rowsGroupwise.length > 1 &&
    rowsGroupwise.push({
      id: '',
      Account_Name: '',
      Account_Subname: 'Total',
      Account_Head_Id: '',
      OP_Balance: groupwiseOBTotal.toFixed(2),
      Debit: groupwiseDebitTotal.toFixed(2),
      Credit: groupwiseCreditTotal.toFixed(2),
      Date: '',
      CL_Balance: groupwiseCBTotal.toFixed(2),
    });
  return rowsGroupwise;
}
