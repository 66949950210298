import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { LocalShipping } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import MenuIcon from '@material-ui/icons/Menu';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import axios from '../../../helpers/axios';
import formatDate from '../../../helpers/formatDate';
import { getTrackingDetails, updateOrder, updateTrackingId } from '../../../redux/actions';
import {
  darkBlackColour,
  mainColour1,
  mainColour2,
  secColour2,
  specialButtonColour,
} from '../../../utils';
import Invoice from './Invoice';
import OrderCard from './OrderCard';
import useStyles from './OrderCard/useStyles';
import ShiprocketDialog from './ShiprocketDialog';

const OrderTemplate = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orderItem, steps, activeStatus } = props;

  const [expand, setExpand] = useState(false);
  const [type, setType] = useState('');
  const [trackingId, setTrackingId] = useState('');

  const loading = useSelector((state) => state.order.loading);
  const tracking_data = useSelector((state) => state.order.tracking_data);
  const [open, setOpen] = useState(false);

  const handleTrackingId = (orderId, invoiceId, email) => {
    const payload = {
      orderId,
      invoiceId,
      trackingId,
      email,
    };
    dispatch(updateTrackingId(payload));
    setTrackingId('');
  };

  const onOrderUpdate = (orderId, invoiceId, type, email, trackingId) => {
    const payload = {
      orderId,
      invoiceId,
      type,
      email,
      trackingId,
    };
    dispatch(updateOrder(payload));
  };

  const handleTrackingDetails = async (trackingId) => {
    dispatch(getTrackingDetails({ trackingId: trackingId }));
    setOpen(true);
  };
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(orderItem.trackingId);
      toast.success('Tracking ID copied!');
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handleFetchPaymentDetails = async (paymentId) => {
    try {
      const response = await axios.get(`/admin/orders/payment/${paymentId}`);
      alert(JSON.stringify(response.data, null, 4));
    } catch (err) {
      alert(JSON.stringify(err, null, 4));
    }
  };

  const sendOrderEmail = async (payload) => {
    try {
      const response = await axios.post('/admin/orders/email', payload);
      if (response.status === 201) {
        toast.success('Email sent successfully!');
      }
    } catch (err) {
      toast.error('Email sending failed!');
    }
  };

  const [openShiprocketDialog, setOpenShiprocketDialog] = useState(false);

  const invoiceRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: orderItem.invoiceId,
  });

  return (
    <div style={{ backgroundColor: secColour2, margin: '10px 0', padding: '10px 20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6' className={classes.orderedBy}>
          Order ID : {orderItem.invoiceId} | Ordered By : {orderItem.user.name}
        </Typography>

        <Button
          variant='contained'
          style={{ backgroundColor: mainColour1, color: 'white' }}
          size='small'
          onClick={() => setExpand((prev) => !prev)}
        >
          <MenuIcon />
        </Button>
      </div>
      <OrderCard
        key={orderItem._id}
        orderItem={orderItem}
        type={type}
        setType={setType}
        onOrderUpdate={onOrderUpdate}
        formatDate={formatDate}
      />
      <Collapse in={expand} timeout='auto' unmountOnExit>
        <Grid container className={classes.desktopMobile}>
          <>
            <Grid item sx={12} sm={3} direction='column' justifyContent='center'>
              <Typography style={{ fontWeight: 'bold' }}>Shipping Address</Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Name : </span>
                {orderItem.address2.name}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Address : </span>
                {orderItem.address2.address}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>City/District/Town : </span>
                {orderItem.address2.cityDistrictTown}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Zip Code : </span>
                {orderItem.address2.pinCode}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>State : </span>
                {orderItem.address2.state}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Landmark : </span>
                {orderItem.address2.landmark}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Phone : </span>
                {orderItem.address2.mobileNumber}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Alternate Phone : </span>
                {orderItem.address2.alternatePhone}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>GSTIN : </span>
                {orderItem.address2.gstin}
              </Typography>
              <Typography style={{ fontSize: '12px' }} variant='body2'>
                <span style={{ fontWeight: 'bold' }}>Email : </span>
                {orderItem.user.email}
              </Typography>
            </Grid>
            <Grid
              item
              sx={12}
              sm={7}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <div>
                <Stepper activeStep={activeStatus} className={classes.stepper}>
                  {steps.map((label) => (
                    <Step key={label.date}>
                      <StepLabel>
                        <Typography variant='body2'>{label.type}</Typography>
                        <Typography variant='body2'>{formatDate(label.date)}</Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <TextField
                  style={{ backgroundColor: 'white', marginTop: '10px' }}
                  fullWidth
                  variant='outlined'
                  label='Tracking ID'
                  id='outlined-size-small'
                  value={trackingId}
                  type='text'
                  onChange={(e) => setTrackingId(e.target.value)}
                />
                <Button
                  disabled={trackingId === ''}
                  style={{ backgroundColor: darkBlackColour, color: 'white' }}
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={() =>
                    handleTrackingId(orderItem._id, orderItem.invoiceId, orderItem.user.email)
                  }
                >
                  <EditIcon />
                  Update TID
                </Button>
              </div>
              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Tracking ID : {orderItem.trackingId}
              </Typography>

              <div>
                <Button
                  className={classes.letterSize}
                  variant='contained'
                  onClick={() => handleTrackingDetails(orderItem.trackingId)}
                  style={{ backgroundColor: specialButtonColour, color: 'white' }}
                >
                  Track order
                </Button>
              </div>
              <Dialog
                fullWidth
                onClose={() => setOpen(false)}
                aria-labelledby='customized-dialog-title'
                open={open}
              >
                <DialogTitle
                  id='customized-dialog-title'
                  onClose={() => setOpen(false)}
                  style={{ borderBottom: `4px solid ${mainColour2}` }}
                >
                  Tracking Details
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                  {loading ? (
                    <CircularProgress style={{ color: mainColour1 }} />
                  ) : (
                    <>
                      {tracking_data?.shipment_track_activities?.length > 0 ? (
                        <Stepper activeStep={0} orientation='vertical' className={classes.stepper}>
                          {tracking_data?.shipment_track_activities.map((label, idx) => (
                            <Step key={label.date} completed={idx > 0}>
                              <StepLabel>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography variant='body2'>{label.date}</Typography>
                                  <>
                                    <Typography variant='body1'>
                                      {label['sr-status-label']}
                                    </Typography>
                                    {/* <Typography variant="body1">{label.status}</Typography> */}
                                    <Typography variant='body2'>{label.location}</Typography>
                                    {/* <Typography variant="body2">{label["sr-status"]}</Typography> */}
                                    <Typography variant='body2'>
                                      {label['sr-status-label']}
                                    </Typography>
                                    <Typography variant='body2'>{label.activity}</Typography>
                                  </>
                                </div>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Stepper activeStep={activeStatus} className={classes.stepper}>
                            {steps.map((label) => (
                              <Step key={label.date}>
                                <StepLabel>
                                  <Typography variant='body2'>{label.type}</Typography>
                                  <Typography variant='body2'>{formatDate(label.date)}</Typography>
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                          <Typography variant='body'>
                            {orderItem.trackingId}
                            <Tooltip title='Click to copy tracking id' placement='right-start'>
                              <ContentCopyIcon onClick={handleCopy} className={classes.copyIcon} />
                            </Tooltip>
                          </Typography>
                          <a
                            style={{ color: 'white', textDecoration: 'none' }}
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx'
                          >
                            <Button
                              className={classes.letterSize}
                              variant='contained'
                              style={{ backgroundColor: mainColour2, color: 'white' }}
                            >
                              Go to IndiaPost
                            </Button>
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid item sx={12} sm={2}>
              <select onChange={(e) => setType(e.target.value)} style={{ width: '100%' }}>
                <option value={''}>Select Status</option>
                {orderItem.orderStatus.map((status, idx) => {
                  return (
                    <>
                      {!status.isCompleted ? (
                        <option key={idx} value={status.type}>
                          {status.type}
                        </option>
                      ) : null}
                    </>
                  );
                })}
              </select>

              <div>
                <Button
                  disabled={orderItem.orderStatus[3].isCompleted}
                  style={{ backgroundColor: specialButtonColour, margin: '5px auto' }}
                  fullWidth
                  variant='contained'
                  color='secondary'
                  size='small'
                  onClick={() =>
                    onOrderUpdate(
                      orderItem._id,
                      orderItem.invoiceId,
                      type,
                      orderItem.user.email,
                      orderItem.trackingId,
                    )
                  }
                >
                  Confirm
                </Button>
                <Button
                  style={{ backgroundColor: darkBlackColour, color: 'white', marginBottom: '5px' }}
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={handlePrint}
                >
                  <GetAppIcon /> &nbsp; Invoice
                </Button>
                <Button
                  style={{
                    backgroundColor: specialButtonColour,
                    color: 'white',
                    marginBottom: '5px',
                  }}
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={() => handleFetchPaymentDetails(orderItem.razorpayPaymentId)}
                >
                  Payment Details
                </Button>
                <Button
                  style={{ backgroundColor: darkBlackColour, color: 'white', marginBottom: '5px' }}
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={() =>
                    sendOrderEmail({
                      orderId: orderItem._id,
                      userId: orderItem.user._id,
                      userEmail: orderItem.user.email,
                    })
                  }
                >
                  <EmailIcon /> &nbsp; Send Email
                </Button>
                <Button
                  style={{
                    backgroundColor: specialButtonColour,
                    color: 'white',
                    marginBottom: '5px',
                  }}
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={() => setOpenShiprocketDialog((prev) => !prev)}
                >
                  <LocalShipping /> &nbsp; Shiprocket
                </Button>
                <ShiprocketDialog
                  open={openShiprocketDialog}
                  setOpen={setOpenShiprocketDialog}
                  orderId={orderItem._id}
                />
              </div>
            </Grid>
            <div ref={invoiceRef}>
              {expand && <Invoice orderItem={orderItem} formatDate={formatDate} />}
            </div>
          </>
        </Grid>
      </Collapse>
    </div>
  );
};

export default OrderTemplate;
