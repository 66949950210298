import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '../../../components/Dialog';

import { useDispatch } from 'react-redux';
import CustomInput from '../../../components/CustomInput';
import { updateResellerOffer } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
const UpdateOffer = (props) => {
  const dispatch = useDispatch();
  const { user, open, setOpen } = props;
  const [offer, setOffer] = useState(0);

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={() => setOpen(false)}>
        Update Offer ({user.Name})
      </DialogTitle>
      <DialogContent dividers>
        <CustomInput
          fullWidth
          label='Percent'
          placeholder='Percent'
          value={offer}
          type='text'
          onChange={(e) => setOffer(e.target.value)}
          eyeIcon={false}
          showInputValue={true}
        />
        <Grid container direction='row' justifyContent='center'>
          <Button
            autoFocus
            variant='contained'
            style={{ backgroundColor: mainColour2, color: 'white' }}
            onClick={() => {
              dispatch(updateResellerOffer({ user_id: user.user_id, offer: offer }));
              setOpen(false);
            }}
          >
            Update
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateOffer;
