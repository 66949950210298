import { makeStyles } from '@material-ui/core';
import { mainColour1, mainColour2 } from '../../../../utils';
const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: '100%',
      minWidth: '100%',
      marginBottom: 10,
      border: '1px solid #D87708',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      overflowX: 'auto',
      padding: '0 5px',
      [theme.breakpoints.between('sm', 'md')]: {
        // maxWidth: 700,
        // minWidth: 700,
      },
      [theme.breakpoints.down('sm')]: {
        // maxWidth: 500,
        // minWidth: 500,
        // display: 'flex',
        // flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        // maxWidth: 320,
        minWidth: 240,
        // display: 'flex',
        // flexDirection: 'column',
      },
    },
    media: {
      height: 100,
      width: 100,
      [theme.breakpoints.between('sm', 'md')]: {
        // maxWidth: 100,
        // minWidth: 100,
        zoom: '80%',
      },
      [theme.breakpoints.down('xs')]: {
        // height: 75,
        // width: 75,
        zoom: '50%',
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',

      justifyContent: 'space-around',
      alignItems: 'center',
      [theme.breakpoints.between('xs', 'md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    letterSize: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        zoom: '80%',
      },
    },
    orderedBy: {
      fontWeight: 'bold',
      color: mainColour1,
      [theme.breakpoints.up('sm')]: {
        fontSize: '22px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    chip: {
      color: 'white',
      fontSize: '14px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '8px',
        zoom: '80%',
      },
    },
    desktopMobile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
      [theme.breakpoints.between('xs', 'sm')]: {
        flexDirection: 'column',
      },
    },
    stepper: {
      zoom: '85%',
      [theme.breakpoints.down('sm')]: {
        zoom: '60%',
      },
      [theme.breakpoints.down('xs')]: {
        zoom: '45%',
      },
    },
    heading: {
      fontWeight: 600,
      color: mainColour1,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    heading2: {
      backgroundColor: mainColour2,
      color: 'white',
      textAlign: 'center',
      fontWeight: 600,
      padding: '20px 0',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        padding: '10px 0',
        marginBottom: '10px',
      },
    },
    copyIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  { index: 1 }, //  optional argument for JSS, to set position after MUI stylesheets
);

export default useStyles;
