import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { trialBalanceConstants } from './constants';

const getTrialBalance = (payload) => {
  return async (dispatch) => {
    dispatch({ type: trialBalanceConstants.GET_TRIAL_BALANCE_REQUEST });

    axios
      .get(`/trialbalance?fromDate=${payload.fromDate}&toDate=${payload.toDate}`)
      .then((response) => {
        const { cashes, journals } = response.data;
        dispatch({
          type: trialBalanceConstants.GET_TRIAL_BALANCE_SUCCESS,
          payload: { cashes: cashes, journals: journals },
        });
        toast.success('Trial balance fetched successfully!');
      })
      .catch((error) => {
        toast.error('Trial balance fetch unsuccessful!');
        dispatch({
          type: trialBalanceConstants.GET_TRIAL_BALANCE_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const saveTrialBalance = (payload) => {
  return async (dispatch) => {
    // dispatch({ type: trialBalanceConstants.SAVE_TRIAL_BALANCE_REQUEST });
    try {
      setTimeout(() => {
        dispatch({
          type: trialBalanceConstants.SAVE_TRIAL_BALANCE_SUCCESS,
          payload: {
            trialBalanceDetails: payload.rowsGroupwise,
            fromDate: payload.fromDate,
            toDate: payload.toDate,
          },
        });
        toast.success('Trial balance saved successfully!');
      }, 500);
    } catch (error) {
      toast.error('Trial balance save unsuccessful!');
      dispatch({
        type: trialBalanceConstants.SAVE_TRIAL_BALANCE_FAILURE,
        payload: { error: error },
      });
    }
  };
};

export const saveGrossProfitCD = (grossProfitCD) => {
  return async (dispatch) => {
    dispatch({ type: trialBalanceConstants.SAVE_GROSS_PROFIT_CD_REQUEST });

    try {
      dispatch({
        type: trialBalanceConstants.SAVE_GROSS_PROFIT_CD_SUCCESS,
        payload: { grossProfitCD: grossProfitCD },
      });
    } catch (error) {
      toast.error('Gross Profit C/D unsuccessful!');
      dispatch({
        type: trialBalanceConstants.SAVE_GROSS_PROFIT_CD_FAILURE,
        payload: { error: error },
      });
    }
  };
};

export const saveNetProfitCD = (netProfitCD) => {
  return async (dispatch) => {
    dispatch({ type: trialBalanceConstants.SAVE_NET_PROFIT_CD_REQUEST });

    try {
      dispatch({
        type: trialBalanceConstants.SAVE_NET_PROFIT_CD_SUCCESS,
        payload: { netProfitCD: netProfitCD },
      });
    } catch (error) {
      toast.error('Net Profit C/D unsuccessful!');
      dispatch({
        type: trialBalanceConstants.SAVE_NET_PROFIT_CD_FAILURE,
        payload: { error: error },
      });
    }
  };
};

export const updateNextYearOpeningBal = (payload) => {
  return () => {
    axios
      .put('/accountnames/openingbalance', payload)
      .then(() => {
        toast.success('Opening Balances updated successfully!');
      })
      .catch((error) => {
        toast.error('Trial balance fetch unsuccessful!');
      });
  };
};

export { getTrialBalance };
