import { Button, ButtonGroup, Grid, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import ButtonsGroupAccounts from '../../../components/ButtonsGroupAccounts';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import findOpeningBal from '../../../helpers/findOpeningBal';
import formatDate2 from '../../../helpers/formatDate2';
import Layout from '../../../layouts/Layout';
import { addAccountName, addNewAccountGroupHead } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import UpdateAccountDialogbox from './UpdateAccountDialogbox';
const filter = createFilterOptions();

const AccountMaster = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const year = useSelector((state) => state.global.year);

  const accountGroupHeadRedux = useSelector((state) => state.accountGroupHead);
  const accountNameRedux = useSelector((state) => state.accountName);
  const accountHeadRedux = useSelector((state) => state.accountHead);
  const pnlHeadRedux = useSelector((state) => state.pnlHead);

  const accountNameList = accountNameRedux.accountNames
    .map((accountName) => accountName.name)
    .filter((value, index, self) => self.indexOf(value) === index);
  const accountGroupHeadList = accountGroupHeadRedux.accountGroupHeads.map(
    (accountGroupHead) => accountGroupHead.name,
  );
  const accountHeadList = accountHeadRedux.accountHeads.map((accountHead) => accountHead.name);
  const pnlHeadList = pnlHeadRedux.pnlHeads.map((pnlHead) => pnlHead.name);

  const [accountName, setAccountName] = useState('');
  const [accountSubname, setAccountSubname] = useState('');
  const [accountOpeningDate, setAccountOpeningDate] = useState(new Date());
  const [accountClosingDate, setAccountClosingDate] = useState(null);
  const [accountOpeningBal, setAccountOpeningBal] = useState(0);
  const [accountGroupHead, setAccountGroupHead] = useState('');
  const [newAccountGroupHead, setNewAccountGroupHead] = useState('');
  const [accountHead, setAccountHead] = useState('');
  const [pnlHead, setPnlHead] = useState('');

  const handleAddNewAccountGroupHead = () => {
    const payload = {
      name: newAccountGroupHead,
    };
    dispatch(addNewAccountGroupHead(payload));
    setNewAccountGroupHead('');
  };
  const handleAddAccountName = () => {
    if (accountName === '') return toast.error('Account name is required !');
    if (accountGroupHead === '') return toast.error('Account Group Head is required !');
    if (accountHead === '') return toast.error('Account Head is required !');
    if (pnlHead === '') return toast.error('PNL Head is required !');
    const requiredAccountGroupHeadId = accountGroupHeadRedux.accountGroupHeads.find(
      (item) => item.name === accountGroupHead,
    )._id;
    const requiredAccountHeadId = accountHeadRedux.accountHeads.find(
      (item) => item.name === accountHead,
    )._id;
    const requiredPnlHeadId = pnlHeadRedux.pnlHeads.find((item) => item.name === pnlHead)._id;
    const payload = {
      name: accountName,
      subname: accountSubname,
      openingDate: accountOpeningDate,
      closingDate: accountClosingDate,
      openingBal: accountOpeningBal,
      accountGroupHead: requiredAccountGroupHeadId,
      accountHead: requiredAccountHeadId,
      pnlHead: requiredPnlHeadId,
    };
    dispatch(addAccountName(payload));
    // setAccountName("")
    setAccountSubname('');
    setAccountOpeningDate(null);
    setAccountClosingDate(null);
    setAccountOpeningBal(0);
    setAccountGroupHead('');
    setAccountHead('');
    setPnlHead('');
  };

  const columns = [
    { field: 'id', width: 100 },
    {
      field: 'Edit',
      renderCell: (params) => (
        <ButtonGroup>
          <Button
            style={{ backgroundColor: mainColour2 }}
            size='small'
            onClick={() => {
              showUpdateAccountDialog(params.row.item);
            }}
          >
            <EditIcon style={{ color: 'white' }} />
          </Button>
        </ButtonGroup>
      ),
      width: 120,
      align: 'center',
    },
    { field: 'Account_Name', width: 200, align: 'center' },
    { field: 'Account_Subname', width: 200, align: 'center' },
    { field: 'Opening_Date', width: 175, align: 'center' },
    { field: 'Closing_Date', width: 175, align: 'center' },
    { field: 'Opening_Bal', width: 175, align: 'center' },
    { field: 'Account_Group_Head', width: 200, align: 'center' },
    { field: 'Account_Head', width: 200, align: 'center' },
    { field: 'Pnl_Head', width: 200, align: 'center' },
  ];

  let rowss = [];
  accountNameRedux.accountNames.map((item, idx) => {
    const openingBalArray = item.openingBal;
    return rowss.push({
      id: idx + 1,
      // Date: formatDate(order.createdAt),
      item,
      Account_Name: item.name,
      Account_Subname: item.subname,
      Opening_Date: formatDate2(item.openingDate),
      Closing_Date: formatDate2(item.closingDate),
      Opening_Bal: findOpeningBal(openingBalArray, year).toFixed(2),
      Account_Group_Head: item.accountGroupHead.name,
      Account_Head: item.accountHead.name,
      Pnl_Head: item.pnlHead.name,
    });
  });

  const [myAccount, setMyAccount] = useState(null);
  const [updateAccountDialog, setUpdateAccountDialog] = useState(false);

  const showUpdateAccountDialog = (item) => {
    setUpdateAccountDialog(true);
    const accountToBeUpdated = accountNameRedux.accountNames.find(
      (account) => item._id === account._id,
    );
    setMyAccount(accountToBeUpdated);
  };

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item sm={9} xs={12}>
          <Typography variant='h4' className={classes.heading}>
            ACCOUNT MASTER
          </Typography>
        </Grid>
        <Grid item sm={3} xs={12}>
          <ButtonsGroupAccounts
            btn1='Cash'
            btn2='Journal'
            btn3='Ledger'
            link1='/accounts-transactions-cash'
            link2='/accounts-transactions-journal'
            link3='/accounts-transactions-ledger'
          />
        </Grid>
      </Grid>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          {/* <TextField variant="outlined" value={accountName} label="Account Name" placeholder="Account Name" onChange={(e) => setAccountName(e.target.value)} /> */}
          <Autocomplete
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push(params.inputValue);
              }
              return filtered;
            }}
            options={accountNameList}
            value={accountName}
            onChange={(e, value) => setAccountName(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Account Name'
                label='Account Name'
                variant='outlined'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            variant='outlined'
            value={accountSubname}
            label='Account Subname'
            placeholder='Account Subname'
            onChange={(e) => setAccountSubname(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='Opening Date'
              value={accountOpeningDate}
              onChange={(newValue) => {
                setAccountOpeningDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} variant='outlined' focused />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              // label="Closing Date"
              value={accountClosingDate}
              onChange={(newValue) => {
                setAccountClosingDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label='Closing Date' variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            variant='outlined'
            value={accountOpeningBal}
            label='Account Opening Bal'
            placeholder='Opening Bal'
            onChange={(e) => setAccountOpeningBal(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='account_group_head_select'
            options={accountGroupHeadList}
            getOptionLabel={(option) => option}
            value={accountGroupHead}
            onChange={(e, value) => setAccountGroupHead(value)}
            renderInput={(params) => (
              <TextField {...params} label='Account Group Head' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Autocomplete
            autoHighlight
            id='account_head__select'
            options={accountHeadList}
            getOptionLabel={(option) => option}
            value={accountHead}
            onChange={(e, value) => setAccountHead(value)}
            renderInput={(params) => <TextField {...params} label='Acct Head' variant='outlined' />}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='pnl_head__select'
            options={pnlHeadList}
            getOptionLabel={(option) => option}
            value={pnlHead}
            onChange={(e, value) => setPnlHead(value)}
            renderInput={(params) => <TextField {...params} label='PNL Head' variant='outlined' />}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={2} style={{ display: 'flex' }}>
          <TextField
            variant='outlined'
            value={newAccountGroupHead}
            label='New Group Head'
            placeholder='New Group Head'
            onChange={(e) => setNewAccountGroupHead(e.target.value)}
          />
          <Button
            onClick={handleAddNewAccountGroupHead}
            variant='contained'
            className={classes.externalLinks}
            style={{ height: '56px' }}
          >
            <PublishIcon />
          </Button>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
          <Button
            onClick={handleAddAccountName}
            variant='contained'
            className={classes.externalLinks}
            style={{ height: '56px' }}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {!accountNameRedux.loading ? (
            <MyTable rows={rowss} columns={columns} rowHeight={60} />
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>

      {updateAccountDialog && (
        <UpdateAccountDialogbox
          myAccount={myAccount}
          updateAccountDialog={updateAccountDialog}
          setUpdateAccountDialog={setUpdateAccountDialog}
        />
      )}
    </Layout>
  );
};

export default AccountMaster;
