const baseUrl1 = 'https://api.sambalpurifab.in'; // aws beanstalk
// const baseUrl1 = "https://vercelapi.sambalpurifab.in"; //vercel serverless
// const baseUrl2 = 'http://localhost:8800'; //localhost server
// const baseUrl2 = "https://vercelapi.sambalpurifab.in"; //vercel serverless
const baseUrl2 = 'https://api.sambalpurifab.in'; //aws beanstalk

export const api =
  process.env.REACT_APP_NODE_ENV === 'production' ? `${baseUrl1}/api` : `${baseUrl2}/api`;

const userApp1 = 'https://www.sambalpurifab.in';
const userApp2 = 'http://localhost:3000';
export const userApp =
  process.env.REACT_APP_NODE_ENV === 'production' ? `${userApp1}` : `${userApp2}`;

export const SF_LOGO =
  'https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Logo-Circular-Compressed.webp';
export const SF_QR_IN =
  'https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Logos/qr_code_sf.in.png';
export const SF_QR_COM =
  'https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Logos/qr_code_sf.com.png';
export const HANDLOOM_LOGO =
  'https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Logos/Handloom-Logo.jpg';
export const HANDLOOM_MARK =
  'https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Logos/handloom-mark.jpg';
export const MODEL_IMAGE =
  'https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Logos/ModelImage.jpeg';

export const mainColour1 = 'black';
export const mainColour2 = '#073965';
export const mainColour3 = '#FBB040';
export const secColour = '#FBF7ED';
export const secColour2 = '#F3FCF4';
export const darkBlackColour = '#212121';
export const specialButtonColour = '#FB4E4D';
export const redColour = '#FE0102';
export const sidebarColour = '#FBF7ED';
export const titleRedColour = 'red';
