import { usersConstants } from '../actions/constants';

const initState = {
  error: null,
  customers: [],
  addresses: [],
  loading: false,
};

const usersReducers = (state = initState, action) => {
  switch (action.type) {
    case usersConstants.GET_ALL_USERS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case usersConstants.GET_ALL_USERS_SUCCESS:
      state = {
        ...state,
        customers: action.payload.users.reverse(),
        loading: false,
      };
      break;
    case usersConstants.GET_ALL_USERS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case usersConstants.GET_ALL_USERS_ADDRESSES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case usersConstants.GET_ALL_USERS_ADDRESSES_SUCCESS: {
      const ads = action.payload.addresses.reverse();
      const adsArray = ads.map((item) => item.address);
      const finalAddresses = [];
      adsArray.map((item) => {
        item.map((ele) => {
          finalAddresses.push(ele);
        });
      });
      state = {
        ...state,
        addresses: finalAddresses,
        loading: false,
      };
      break;
    }
    case usersConstants.GET_ALL_USERS_ADDRESSES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case usersConstants.APPROVE_AS_RESELLER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case usersConstants.APPROVE_AS_RESELLER_SUCCESS: {
      const customers = state.customers.map((item) => {
        if (item._id == action.payload.user._id) {
          item.reseller = action.payload.user.reseller;
        }
        return item;
      });
      state = {
        ...state,
        customers: customers,
        loading: false,
      };
      break;
    }
    case usersConstants.APPROVE_AS_RESELLER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    //////////////////////////////////

    case usersConstants.UPDATE_RESELLER_OFFER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case usersConstants.UPDATE_RESELLER_OFFER_SUCCESS: {
      const custs = state.customers.map((item) => {
        if (item._id == action.payload.user._id) {
          item.offer = action.payload.user.offer;
        }
        return item;
      });
      state = {
        ...state,
        customers: custs,
        loading: false,
      };
      break;
    }
    case usersConstants.UPDATE_RESELLER_OFFER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      break;
  }

  return state;
};

export default usersReducers;
