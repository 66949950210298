import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles(() => ({
  myDropzone: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    width: '100%',
    border: '2px dashed green',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#C1D1D6',
      // border: '2px dashed green',
    },
  },
}));
export const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: '10px',
  marginBottom: '10px',
};

export const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  // border: '1px solid #eaeaea',
  width: '25%',
  height: '160px',
  padding: '2.5px',
  boxSizing: 'border-box',
};

export const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

export const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

export function MultipleFileUploadField({ handleProductPictures }) {
  const classes = useStyles();

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*', 'video/*', '.pdf'],
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    maxSize: 1000 * 1024, // 1 MB
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach(file => URL.revokeObjectURL(file.preview));
    handleProductPictures(files);
  }, [files]);

  return (
    <>
      <div>
        <div {...getRootProps()} className={classes.myDropzone}>
          <input {...getInputProps()} />
          <p>Drag and drop images here, or click to select images</p>
        </div>
        <div style={thumbsContainer}>
          {files.map((file) => (
            <div style={thumb} key={file.name}>
              <div style={thumbInner}>
                <img src={file.preview} style={img} />
                {/* <p>{file.preview}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
