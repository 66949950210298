import { Button, Grid, TextField, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import replaceImageUrl, { getSmallImageUrl } from '../../../helpers/replaceImageUrl';
import Layout from '../../../layouts/Layout';
import { getOrderReport } from '../../../redux/actions/order.actions';
import { mainColour1, mainColour2, mainColour3, userApp } from '../../../utils';

const Report = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.order.loading);
  const rows = useSelector((state) => state.order.report);

  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const [toDate, setToDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  );

  const columns = [
    { field: 'id', width: 100 },
    { field: 'Order ID', width: 150, align: 'center' },
    { field: 'Date', width: 200, align: 'center' },
    {
      field: 'Product',
      renderCell: (params) => {
        if (params.row.item) {
          let photo = replaceImageUrl(params.row?.item?.productId.productPictures[0].img);

          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <a href={photo} target='_blank' rel='noreferrer'>
                <img
                  src={getSmallImageUrl(photo)}
                  alt={`${params.row?.item?.name} ${params.row?.item?.prodCode}`}
                  className={classes.tableImage}
                />
              </a>
            </div>
          );
        } else {
          return null;
        }
      },
      width: 120,
    },
    {
      field: 'Link',
      renderCell: (params) => {
        if (params.row.item) {
          return (
            <a
              href={`${userApp}/product/${params.row?.item?.productId._id}`}
              target='_blank'
              rel='noreferrer'
            >
              <Button style={{ backgroundColor: mainColour3, color: mainColour1 }} size='small'>
                <LinkIcon />
              </Button>
            </a>
          );
        } else {
          return null;
        }
      },
      width: 120,
      align: 'center',
    },
    { field: 'ProdCode', width: 150, align: 'center' },
    { field: 'State', width: 150, align: 'center' },
    { field: 'HSN_Code', width: 150, align: 'center' },
    { field: 'IGST', width: 120, align: 'center' },
    { field: 'CGST', width: 120, align: 'center' },
    { field: 'SGST', width: 120, align: 'center' },
    { field: 'Qty', width: 100, align: 'center' },
    { field: 'GP', width: 100, align: 'center' },
    { field: 'Offer', width: 110, align: 'center' },
    { field: 'DiscAmt', width: 135, align: 'center' },
    { field: 'NP', width: 135, align: 'center' },
    { field: 'IGST_Amt', width: 150, align: 'center' },
    { field: 'CGST_Amt', width: 150, align: 'center' },
    { field: 'SGST_Amt', width: 150, align: 'center' },
    { field: 'Total_GST', width: 135, align: 'center' },
    { field: 'NP_GST', width: 135, align: 'center' },
    { field: 'Category', width: 135, align: 'center' },
  ];

  useEffect(() => {
    dispatch(getOrderReport(fromDate, toDate));
  }, []);

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            REPORT ({rows.length} Items)
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='From Date'
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='To Date'
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            onClick={() => dispatch(getOrderReport(fromDate, toDate))}
            variant='contained'
            style={{ height: '56px', backgroundColor: mainColour2, color: 'white' }}
          >
            Get Report
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {!loading ? (
            <MyTable rows={rows} columns={columns} rowHeight={150} />
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Report;
