import { CASH, PAYMENT, RECEIVED } from '../../../../../helpers/constants';
import formatDate2 from '../../../../../helpers/formatDate2';

export default function getIndivialRowsList(ledger, accountName, accountSubname, openingBal, crdr) {
  let uid = 0;
  let rows = [];
  rows.push({
    id: uid,
    Date: '',
    Voucher: '',
    Account_Name: '',
    Account_Subname: '',
    Account_Head_Id: '',
    Credit: '',
    Debit: '',
    CL_Balance: Number(Number(openingBal).toFixed(2)),
    Narration: `Opening Bal = ${Number(openingBal).toFixed(2)} ${crdr}`,
  });
  ledger.cashes?.length > 0 &&
    ledger.cashes?.map((item) => {
      uid += 1;
      const credit =
        accountName !== CASH
          ? item.type === RECEIVED
            ? Number(Math.abs(item.amount))
            : Number(0)
          : item.type === PAYMENT
          ? Number(Math.abs(item.amount))
          : Number(0);

      const debit =
        accountName !== CASH
          ? item.type === PAYMENT
            ? Number(Math.abs(item.amount))
            : Number(0)
          : item.type === RECEIVED
          ? Number(Math.abs(item.amount))
          : Number(0);

      return rows.push({
        id: uid,
        // item,
        DateFull: item.date,
        Date: formatDate2(item.date),
        Voucher: item.voucherNum,
        Account_Name: item.accountName.name,
        Account_Subname: item.accountName.subname,
        Account_Head_Id: item.accountName.accountHead,
        Debit: Number(debit).toFixed(2),
        Credit: Number(credit).toFixed(2),
        CL_Balance:
          crdr === 'Dr' ? Number(debit - credit).toFixed(2) : Number(credit - debit).toFixed(2),
        Narration: item.narration,
      });
    });

  ledger.journals?.length > 0 &&
    ledger.journals?.map((item) => {
      const entries = item.entries;
      return entries
        .filter((item) =>
          accountSubname === '' || accountSubname == null
            ? item.accountName.name === accountName
            : item.accountName.name === accountName && item.accountName.subname === accountSubname,
        )
        .map((entry) => {
          uid += 1;
          const debit = Math.abs(entry.dbAmount).toFixed(2);
          const credit = entry.crAmount.toFixed(2);
          rows.push({
            id: uid,
            DateFull: item.date,
            Date: formatDate2(item.date),
            Voucher: item.voucherNum,
            Account_Name: entry.accountName.name,
            Account_Subname: entry.accountName.subname,
            Account_Head_Id: entry.accountName.accountHead,
            Debit: Number(debit).toFixed(2),
            Credit: Number(credit).toFixed(2),
            CL_Balance:
              crdr === 'Dr' ? Number(debit - credit).toFixed(2) : Number(credit - debit).toFixed(2),

            Narration: entry.narration,
          });
        });
    });

  rows = rows.sort((a, b) => new Date(a.DateFull) - new Date(b.DateFull));

  //replace CL_Balance by sum of prev CL_Balance and current balance
  for (let i = 1; i < rows.length; i++) {
    rows[i] = {
      ...rows[i],
      CL_Balance: Number(rows[i - 1].CL_Balance) + Number(rows[i].CL_Balance),
    };
  }

  const debitTotal =
    rows.length > 0
      ? Number(rows.reduce((a, b) => ({ Debit: Number(a.Debit) + Number(b.Debit) })).Debit).toFixed(
          2,
        )
      : Number(0).toFixed(2);
  const creditTotal =
    rows.length > 0
      ? Number(
          rows.reduce((a, b) => ({ Credit: Number(a.Credit) + Number(b.Credit) })).Credit,
        ).toFixed(2)
      : Number(0).toFixed(2);

  //replace the ids of rows into a sorted numbers
  rows =
    rows.length > 0 &&
    rows.map((item, idx) => {
      return { ...item, id: idx + 1 };
    });

  // rows.length > 0 &&
  rows.push({
    id: '',
    Date: '',
    Voucher: '',
    Narration: 'Total',
    Account_Name: '',
    Account_Subname: '',
    Debit: debitTotal,
    Credit: creditTotal,
    CL_Balance: '',
  });

  return rows;
}
