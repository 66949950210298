import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Backdrop,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useStyles from '../../../assets/css/useStyles';
import CustomInput from '../../../components/CustomInput';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/Dialog';
import linearCategories from '../../../helpers/linearCategories';
import Layout from '../../../layouts/Layout';
import { createPage } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
/**
 * @author
 * @function NewPage
 **/

const NewPage = () => {
  const classes = useStyles();
  const [createDialog, setCreateDialog] = useState(false);
  const [title, setTitle] = useState('');
  const category = useSelector((state) => state.category);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [desc, setDesc] = useState('');
  const [type, setType] = useState('');
  const [banners, setBanners] = useState([]);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page);

  useEffect(() => {
    setCategories(linearCategories(category.categories));
  }, [category]);

  useEffect(() => {
    if (!page.loading) {
      setCreateDialog(false);
      setTitle('');
      setCategoryId('');
      setDesc('');
      setProducts([]);
      setBanners([]);
      setFile([]);
      setFile2([]);
    }
  }, [page]);

  const [file, setFile] = useState([]);
  function uploadSingleFile(e) {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
  }
  const [file2, setFile2] = useState([]);
  function uploadSingleFile2(e) {
    setFile2([...file2, URL.createObjectURL(e.target.files[0])]);
  }

  const handleBannerPictures = (e) => {
    setBanners([...banners, e.target.files[0]]);
  };

  const handleProductPictures = (e) => {
    setProducts([...products, e.target.files[0]]);
  };
  const onCategoryChange = (e) => {
    const category = categories.find((category) => category.value === e.target.value);
    setCategoryId(e.target.value);
    setType(category.type);
  };

  const submitPageForm = () => {
    if (title === '') {
      alert('Title is required');
      setCreateDialog(false);
      return;
    }

    const form = new FormData();
    form.append('title', title);
    if (desc !== '') form.append('description', desc);
    form.append('category', categoryId);
    form.append('type', type);
    banners.forEach((banner) => {
      form.append('banners', banner);
    });
    products.forEach((product) => {
      form.append('products', product);
    });
    setCreateDialog(false);
    dispatch(createPage(form));
  };

  const CreatePageDialog = () => {
    return (
      <Dialog fullWidth onClose={() => setCreateDialog(false)} open={createDialog}>
        <DialogTitle onClose={() => setCreateDialog(false)}>Create New Page</DialogTitle>
        <DialogContent dividers>
          <FormControl variant='outlined' className='form-control' style={{ marginBottom: '25px' }}>
            <InputLabel id='select_category'>Select Category</InputLabel>
            <Select labelId='select_category' value={categoryId} onChange={onCategoryChange}>
              {categories.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CustomInput
            fullWidth
            label='Page Title'
            placeholder='Page Title'
            value={title}
            type='text'
            onChange={(e) => setTitle(e.target.value)}
            eyeIcon={false}
            showInputValue={true}
          />

          <CustomInput
            fullWidth
            label='Page Description'
            placeholder='Page Description'
            value={desc}
            type='text'
            onChange={(e) => setDesc(e.target.value)}
            eyeIcon={false}
            showInputValue={true}
          />

          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            style={{ marginTop: '20px', width: '100%' }}
          >
            <Grid item md={12} sx={12}>
              <Grid container direction='row'>
                {file.length > 0 &&
                  file.map((item, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}
                      >
                        <img src={item} alt='Missing' height='100px' width='250px' />
                      </Grid>
                    );
                  })}
                <label htmlFor='upload-banners' style={{ width: '100%' }}>
                  <input
                    style={{ display: 'none' }}
                    id='upload-banners'
                    name='upload-banners'
                    type='file'
                    disabled={file.length === 4}
                    className='form-control'
                    onChange={(e) => {
                      uploadSingleFile(e);
                      handleBannerPictures(e);
                    }}
                  />
                  <Button
                    color='primary'
                    variant='contained'
                    component='span'
                    style={{ width: '100%' }}
                  >
                    Upload Banners
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            style={{ marginTop: '20px', width: '100%' }}
          >
            <Grid item md={12} sx={12}>
              <Grid container direction='row'>
                {file2.length > 0 &&
                  file2.map((item, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}
                      >
                        <img src={item} alt='Missing' height='175px' width='100%' />
                        {/* <CancelIcon onClick={() => {
                                                deleteFile(index)
                                                deleteLastSelectedPicture()
                                            }}
                                            style={{color:"#fff",marginTop:'15px',position:"relative", top:'-87.5%' ,left:"80%"}}
                                            /> */}
                        {/* <Button color="secondary" variant="contained" style={{marginTop:'15px',position:"relative", top:'-160px'}}
                                            onClick={() => {
                                                deleteFile(index)
                                                deleteLastSelectedPicture()
                                            }}>
                                            <CancelIcon/>
                                        </Button> */}
                      </Grid>
                    );
                  })}
                <label htmlFor='upload-products' style={{ width: '100%' }}>
                  <input
                    style={{ display: 'none' }}
                    id='upload-products'
                    name='upload-products'
                    type='file'
                    disabled={file.length === 4}
                    className='form-control'
                    onChange={(e) => {
                      uploadSingleFile2(e);
                      handleProductPictures(e);
                    }}
                  />
                  <Button
                    color='primary'
                    variant='contained'
                    component='span'
                    style={{ width: '100%' }}
                  >
                    Upload Products
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={submitPageForm} color='primary'>
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (page.loading) {
      <Backdrop open={true} />;
    } else {
      <Backdrop open={false} />;
    }
  }, [page.loading]);

  return (
    <Layout sidebar>
      {CreatePageDialog()}

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            ALL PAGES ({page.pages.length})
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            style={{ color: mainColour2 }}
            onClick={() => setCreateDialog(true)}
          >
            <AddCircleIcon style={{ color: mainColour2 }} />
            Create / Update Page
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        {page.pages.length > 0 &&
          page.pages.map((item, idx) => {
            return (
              <Grid container key={idx}>
                <Grid item md={1} sx={1}>
                  <Typography variant='h6'>{idx + 1}</Typography>
                </Grid>
                <Grid item md={2} sx={2}>
                  <Typography variant='h6'>{item.title}</Typography>
                </Grid>
                <Grid item md={4} sx={4}>
                  <Typography variant='h6'>{item.description}</Typography>
                </Grid>
                <Grid item md={5} sx={5}>
                  <Grid container>
                    {item.banners.length > 0 &&
                      item.banners.map((banner, _idx) => {
                        return (
                          <Grid item key={_idx}>
                            <img
                              src={`http://localhost:2000/public/${banner.img}`}
                              alt='Banner'
                              height='50px'
                              width='100px'
                              style={{ marginLeft: '10px' }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Layout>
  );
};

export default NewPage;
