import { Button, Grid, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import Layout from '../../../layouts/Layout';
import { mainColour1, mainColour3, userApp } from '../../../utils';
const QuantityAvailable = () => {
  const classes = useStyles();
  const product = useSelector((state) => state.product);
  const columns = [
    { field: 'id', width: 100 },
    // { field: 'Name', width: 150 },
    {
      field: 'Product',
      renderCell: (params) => {
        let photo = params.row.item.productPictures[0].img;
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href={photo} target='_blank' rel='noreferrer'>
              <img
                src={photo}
                alt={`${params.row.item.name} ${params.row.item.prodCode}`}
                className={classes.tableImage}
              />
            </a>
            {/* <div>
                            <p style={{ marginLeft: '10px', marginTop: '15px' }}>{params.row.item.name}</p>
                        </div> */}
          </div>
        );
      },
      width: 120,
    },
    {
      field: 'Link',
      renderCell: (params) => (
        <a href={`${userApp}/product/${params.row.item._id}`} target='_blank' rel='noreferrer'>
          <Button style={{ backgroundColor: mainColour3, color: mainColour1 }} size='small'>
            <LinkIcon />
          </Button>
        </a>
      ),
      width: 120,
      align: 'center',
    },
    { field: 'ProdCode', width: 150, align: 'center' },
    { field: 'Qty', width: 100, align: 'center' },
    { field: 'CP', width: 150, align: 'center' },
    { field: 'Total', width: 150, align: 'center' },
    { field: 'Colour', width: 150, align: 'center' },
    { field: 'Category', width: 250 },
  ];

  const rowss = product.products
    .filter((item) => item.quantity > 0)
    .map((item, idx) => {
      return {
        id: idx + 1,
        Name: item.name,
        item,
        ProdCode: item.prodCode,
        CP: item.cPrice,
        Total: item.cPrice * item.quantity,
        Colour: item.colour,
        Qty: item.quantity,
        Category: item.category.name,
      };
    });

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          {/* <Typography variant="h4" className={classes.heading}>AVAILABLE PRODUCTS ({product.products.filter(item => item.quantity > 0).length})</Typography> */}
          <Typography variant='h4' className={classes.heading}>
            AVAILABLE PRODUCTS (
            {product.products
              .filter((item) => item.quantity > 0)
              .reduce((previousValue, currentItem) => previousValue + currentItem.quantity, 0)}
            )
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            TOTAL COST (Rs.{' '}
            {product.products
              .filter((item) => item.quantity > 0)
              .reduce(
                (previousValue, currentItem) =>
                  previousValue + currentItem.quantity * currentItem.cPrice,
                0,
              )}
            )
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        {product.products.length > 0 ? (
          <MyTable columns={columns} rows={rowss} loading={product.loading} rowHeight={150} />
        ) : (
          <MyTableSkeleton />
        )}
      </Grid>
    </Layout>
  );
};

export default QuantityAvailable;
