import { ledgerConstants } from '../actions/constants';

const initialState = {
  accountId: '',
  cashes: [],
  journals: [],
  loading: false,
  error: null,
};

const ledgerReducers = (state = initialState, action) => {
  switch (action.type) {
    case ledgerConstants.GET_LEDGER_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ledgerConstants.GET_LEDGER_DETAILS_SUCCESS:
      state = {
        ...state,
        accountId: action.payload.accountId,
        cashes: action.payload.cashes,
        journals: action.payload.journals,
        loading: false,
      };
      break;
    case ledgerConstants.GET_LEDGER_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case ledgerConstants.VIEW_ALL_RECORDS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ledgerConstants.VIEW_ALL_RECORDS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case ledgerConstants.HIDE_ALL_RECORDS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ledgerConstants.HIDE_ALL_RECORDS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
      break;
  }

  return state;
};

export default ledgerReducers;
