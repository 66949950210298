import { Button, ButtonGroup, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import useStyles from '../../../../assets/css/useStyles';
import MyTable from '../../../../components/MyTable';
import replaceImageUrl, { getSmallImageUrl } from '../../../../helpers/replaceImageUrl';
import { mainColour1, mainColour2, mainColour3, userApp } from '../../../../utils';
const ProductTable = (props) => {
  const classes = useStyles();
  const { product, showProductDetailsDialogbox, showUpdateProductDialog, showDeleteProductDialog } =
    props;
  const columns = [
    { field: 'id', width: 100 },
    {
      field: 'Product',
      renderCell: (params) => {
        if (params.row.item.productPictures.length > 0) {
          let photo = replaceImageUrl(params.row.item.productPictures[0].img);
          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <a href={photo} target='_blank' rel='noreferrer'>
                <img
                  src={getSmallImageUrl(photo)}
                  alt={`${params.row.item.name} ${params.row.item.prodCode}`}
                  className={classes.tableImage}
                />
              </a>
            </div>
          );
        } else {
          return <Typography variant='body1'>No Image</Typography>;
        }
      },
      width: 120,
    },
    {
      field: 'Link',
      renderCell: (params) => (
        <a href={`${userApp}/product/${params.row.item._id}`} target='_blank' rel='noreferrer'>
          <Button style={{ backgroundColor: mainColour3, color: mainColour1 }} size='small'>
            <LinkIcon />
          </Button>
        </a>
      ),
      width: 120,
      align: 'center',
    },
    {
      field: 'Actions',
      renderCell: (params) => (
        <ButtonGroup color='primary' aria-label='outlined primary button group'>
          <Button
            style={{ borderColor: mainColour1 }}
            size='small'
            onClick={() => showProductDetailsDialogbox(params.row.item)}
          >
            <InfoIcon />
          </Button>
          <Button
            style={{ borderColor: mainColour1 }}
            size='small'
            onClick={() => {
              showUpdateProductDialog(params.row.item);
            }}
          >
            <EditIcon style={{ color: mainColour2 }} />
          </Button>
          <Button
            style={{ borderColor: mainColour1 }}
            size='small'
            onClick={() => {
              showDeleteProductDialog(params.row.item);
            }}
          >
            <DeleteIcon style={{ color: 'red' }} />
          </Button>
        </ButtonGroup>
      ),
      width: 150,
    },
    { field: 'ProdCode', width: 150, align: 'center' },
    { field: 'Qty', type: 'number', width: 100, align: 'center' },
    { field: 'CP', type: 'number', width: 100, align: 'center' },
    { field: 'GP', type: 'number', width: 100, align: 'center' },
    { field: 'Offer', type: 'number', width: 110, align: 'center' },
    { field: 'DiscAmt', type: 'number', width: 135, align: 'center' },
    { field: 'NP', type: 'number', width: 135, align: 'center' },
    { field: 'GstAmt', type: 'number', width: 135, align: 'center' },
    { field: 'NP_GST', type: 'number', width: 135, align: 'center' },
    { field: 'Colour', width: 120, align: 'center' },
    { field: 'Size', width: 110, align: 'center' },
    { field: 'Fabric', width: 110, align: 'center' },
    { field: 'FCount', width: 110, align: 'center' },
    { field: 'Blouse', width: 135, align: 'center' },
    // { field: 'MainCategory', width: 150 },
    { field: 'Category', width: 150 },
  ];

  const rowss = product.products.map((item, idx) => {
    item.blouse = item.blouse ? 1 : 0;
    for (let val in item) {
      if (val === undefined) {
        val = null;
      }
    }

    return {
      id: idx + 1,
      Name: item.name,
      item,
      ProdCode: item.prodCode,
      Offer: item.offer,
      CP: item.cPrice,
      GP: item.gPrice,
      DiscAmt: item.discAmt,
      NP: item.price,
      GstAmt: item.gstAmt,
      NP_GST: item.priceWithGST,
      Colour: item.colour,
      Size: item.size,
      Fabric: item.fabric,
      FCount: item.fabricCount,
      Blouse: item.blouse,
      Qty: item.quantity,
      Category: item.category.name,
    };
  });

  return <MyTable columns={columns} rows={rowss} loading={product.loading} rowHeight={150} />;
};

export default React.memo(ProductTable);
