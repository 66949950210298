import { trialBalanceConstants } from '../actions/constants';

const initialState = {
  accountId: '',
  cashes: [],
  journals: [],
  loading: false,
  error: null,
  trialBalanceDetails: [],
  fromDate: null,
  toDate: null,
  grossProfitCD: 0,
  netProfitCD: 0,
};

const ledgerReducers = (state = initialState, action) => {
  switch (action.type) {
    case trialBalanceConstants.GET_TRIAL_BALANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case trialBalanceConstants.GET_TRIAL_BALANCE_SUCCESS:
      state = {
        ...state,
        accountId: action.payload.accountId,
        cashes: action.payload.cashes,
        journals: action.payload.journals,
        loading: false,
      };
      break;
    case trialBalanceConstants.GET_TRIAL_BALANCE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case trialBalanceConstants.SAVE_TRIAL_BALANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case trialBalanceConstants.SAVE_TRIAL_BALANCE_SUCCESS:
      state = {
        ...state,
        trialBalanceDetails: action.payload.trialBalanceDetails,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
        loading: false,
      };
      break;
    case trialBalanceConstants.SAVE_TRIAL_BALANCE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case trialBalanceConstants.SAVE_GROSS_PROFIT_CD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case trialBalanceConstants.SAVE_GROSS_PROFIT_CD_SUCCESS:
      state = {
        ...state,
        grossProfitCD: action.payload.grossProfitCD,
        loading: false,
      };
      break;
    case trialBalanceConstants.SAVE_GROSS_PROFIT_CD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case trialBalanceConstants.SAVE_NET_PROFIT_CD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case trialBalanceConstants.SAVE_NET_PROFIT_CD_SUCCESS:
      state = {
        ...state,
        netProfitCD: action.payload.netProfitCD,
        loading: false,
      };
      break;
    case trialBalanceConstants.SAVE_NET_PROFIT_CD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    default:
      break;
  }

  return state;
};

export default ledgerReducers;
