import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../../../../assets/css/useStyles';

const EditEntryFromUpdateJournalDialogbox = (props) => {
  const classes = useStyles();
  const { journal, editEntryDialog, setEditEntryDialog, rows, setRows, loading, setLoading } =
    props;
  const [accountName, setAccountName] = useState(journal.accountName.name);
  const [accountSubname, setAccountSubname] = useState(journal.accountName.subname);
  const [dbAmount, setDbAmount] = useState(Math.abs(journal.dbAmount));
  const [crAmount, setCrAmount] = useState(Math.abs(journal.crAmount));
  const [narration, setNarration] = useState(journal.narration);

  const accountNameRedux = useSelector((state) => state.accountName);
  const [accountSubnameList, setAccountSubnameList] = useState([]);
  const accountNameList = accountNameRedux.accountNames
    .map((accountName) => accountName.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  useEffect(() => {
    const list = accountNameRedux.accountNames.filter((item) => item.name === accountName);
    const subnameList = list.map((item) => item.subname);
    setAccountSubnameList(subnameList);
  }, [accountName]);

  const editEntryForm = () => {
    setEditEntryDialog(false);

    let accountNameId;
    if (accountSubname === '') {
      accountNameId = accountNameRedux.accountNames.find((item) => item.name === accountName)._id;
    } else {
      accountNameId = accountNameRedux.accountNames.find(
        (item) => item.subname === accountSubname && item.name === accountName,
      )._id;
    }
    const payload = {
      id: journal.id,
      accountName: {
        name: accountName,
        subname: accountSubname,
        _id: accountNameId,
      },
      crAmount: Number(crAmount),
      dbAmount: Number(dbAmount * -1),
      narration: narration,
    };
    setLoading(true);

    const newRows = rows.map((row) => {
      if (row._id == journal._id) {
        return { ...row, ...payload };
      }
      return row;
    });
    setRows(newRows);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  return (
    <Dialog
      fullWidth
      onClose={() => setEditEntryDialog(false)}
      aria-labelledby='EditEntryFromUpdateJournalDialogbox'
      open={editEntryDialog}
    >
      <DialogTitle
        id='editEntryFromUpdateJournalDialogbox'
        onClose={() => setEditEntryDialog(false)}
      >
        Update Entry Details
      </DialogTitle>
      <DialogContent dividers>
        {journal && (
          <>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  id='account_name_select'
                  options={accountNameList}
                  getOptionLabel={(option) => option}
                  value={accountName}
                  onChange={(e, value) => setAccountName(value)}
                  renderInput={(params) => (
                    <TextField {...params} label='Account Name' variant='outlined' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  id='accountSubame_select'
                  options={accountSubnameList}
                  getOptionLabel={(option) => option}
                  value={accountSubname}
                  onChange={(e, value) => setAccountSubname(value)}
                  renderInput={(params) => (
                    <TextField {...params} label='Account Subname' variant='outlined' />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type='number'
                  variant='outlined'
                  value={dbAmount}
                  label='Dr. Amount'
                  placeholder='Db. Amount'
                  onChange={(e) => setDbAmount(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type='number'
                  variant='outlined'
                  value={crAmount}
                  label='Cr. Amount'
                  placeholder='Cr. Amount'
                  onChange={(e) => setCrAmount(e.target.value)}
                />
              </Grid>
            </Grid>

            <TextField
              style={{ marginTop: '10px' }}
              fullWidth
              variant='outlined'
              value={narration}
              label='Narration'
              placeholder='Narration'
              onChange={(e) => setNarration(e.target.value)}
            />

            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <Button autoFocus onClick={editEntryForm} className={classes.button2}>
                Save changes
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditEntryFromUpdateJournalDialogbox;
