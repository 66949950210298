import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import checkState from '../../../../helpers/checkState';
import formatDate from '../../../../helpers/formatDate';
import {
  HANDLOOM_MARK,
  SF_LOGO,
  SF_QR_COM,
  SF_QR_IN,
  mainColour1,
  userApp,
} from '../../../../utils';

const toIndianNumber = (str) => {
  return str.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
  // Basically the regex looks for a digit which is followed by
  // digits (in groups of two) followed by a digit and a decimal point.
};

const Invoice = React.forwardRef((props, ref) => {
  const { orderItem } = props;
  let totalQuantity = 0;
  let totalGrossPrice = 0;
  let totalDiscAmt = 0;
  let totalNetPrice = 0;
  let totalGSTAmt = 0;
  let grandTotal = 0;

  orderItem.items.map((item) => {
    totalQuantity += item.purchasedQty;
    totalGrossPrice += item.grossPrice;
    totalDiscAmt += item.discAmount;
    totalNetPrice += item.netPriceAftDisc;
    totalGSTAmt += item.gstAmt;
    grandTotal += item.payablePrice;
    return;
  });

  let couponPrice = orderItem.couponPrice ? orderItem.couponPrice : 0;
  let finalAmount = grandTotal.toFixed(2) - couponPrice.toFixed(2);
  return (
    <Paper style={{ padding: '20px' }}>
      <Grid container direction='column'>
        <Grid item>
          <Grid container direction='row' justifyContent='space-around' alignItems='center'>
            <Grid item>
              <img src={SF_LOGO} alt='Logo' height='115px' width='115px' />
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography
                  variant='h2'
                  style={{
                    color: mainColour1,
                    textAlign: 'center',
                    paddingTop: '20px',
                    fontFamily: 'Fjalla One',
                  }}
                >
                  SAMBALPURI FAB
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ fontSize: '20px', marginTop: '-5px' }} variant='body2'>
                  Plot No. 1480, Near Satco Office, Ambedkar Chowk, Bargarh, Odisha, India, Pincode-
                  768028{' '}
                </Typography>
                <Typography style={{ fontSize: '20px', marginTop: '-7.5px' }} variant='body2'>
                  Contact - {process.env.REACT_APP_PHONE_SUPPORT}, Email -{' '}
                  {process.env.REACT_APP_EMAIL_SUPPORT}, GSTIN - 21CVHPM4930R1Z5
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <img alt='Logo Missing' src={HANDLOOM_MARK} height='100px' width='100px' />
            </Grid>
          </Grid>
          <Divider style={{ width: '100%', backgroundColor: 'black' }} />
          <Grid
            container
            direction='row'
            justifyContent='space-around'
            alignItems='center'
            style={{ padding: '20px' }}
          >
            <Grid item style={{ visibility: 'hidden' }}>
              <img alt='QR code Missing' src={SF_QR_COM} height='85px' width='85px' />
            </Grid>
            <Grid item>
              <Typography variant='h4' style={{ fontWeight: 'bold' }}>
                INVOICE
              </Typography>
            </Grid>
            <Grid item>
              <img alt='QR code Missing' src={SF_QR_IN} height='85px' width='85px' />
            </Grid>
          </Grid>
        </Grid>

        {/* general details */}
        <Grid item>
          <Divider style={{ width: '100%', backgroundColor: 'black' }} />
          <Grid container direction='row' justifyContent='space-around'>
            <Grid
              item
              md={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>ORDER ID</Typography>
              <Typography style={{ fontWeight: 'bold' }} variant='body2'>
                {orderItem.invoiceId}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>PAYMENT MODE</Typography>
              <Typography style={{ fontWeight: 'bold' }} variant='body2'>
                {orderItem.paymentType.toUpperCase()}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>INVOICE NO</Typography>
              <Typography style={{ fontWeight: 'bold' }} variant='body2'>
                {orderItem.invoiceId}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>INVOICE DATE</Typography>
              <Typography style={{ fontWeight: 'bold' }} variant='body2'>
                {formatDate(orderItem.createdAt)}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: '100%', backgroundColor: 'black' }} />
        </Grid>

        {/* address */}
        <Grid item style={{ marginTop: '20px' }}>
          <Grid container direction='row' justifyContent='space-around'>
            <Grid item style={{ width: '45%' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
                CUSTOMER BILLING ADDRESS
              </Typography>
              {orderItem.address && (
                <>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Name : {orderItem.address.name}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Address : {orderItem.address.address}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Landmark : {orderItem.address.landmark}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    City/District/Town : {orderItem.address.cityDistrictTown}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Zip Code : {orderItem.address.pinCode}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    State : {orderItem.address.state}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Phone : {orderItem.address.mobileNumber}, {orderItem.address.alternatePhone}
                  </Typography>
                  {orderItem.address.gstin !== '' && (
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                      GSTIN : {orderItem.address.gstin}
                    </Typography>
                  )}
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Email : {orderItem.user.email}
                  </Typography>
                </>
              )}
            </Grid>

            <Divider orientation='vertical' flexItem style={{ backgroundColor: 'black' }} />

            <Grid item style={{ width: '45%' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
                CUSTOMER SHIPPING ADDRESS
              </Typography>
              {orderItem.address2 && (
                <>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Name : {orderItem.address2.name}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Address : {orderItem.address2.address}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Landmark : {orderItem.address2.landmark}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    City/District/Town : {orderItem.address2.cityDistrictTown}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Zip Code : {orderItem.address2.pinCode}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    State : {orderItem.address2.state}
                  </Typography>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Phone : {orderItem.address2.mobileNumber}, {orderItem.address2.alternatePhone}
                  </Typography>
                  {orderItem.address.gstin !== '' && (
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                      GSTIN : {orderItem.address2.gstin}
                    </Typography>
                  )}
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Email : {orderItem.user.email}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* table` */}
        <Grid item>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead>
                <TableRow
                  style={{ borderTop: '1.5px solid black', borderBottom: '1.5px solid black' }}
                >
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    Image
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    SKU
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    Description
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    HSN CODE
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ fontSize: '14px', fontWeight: 600, width: '5%' }}
                  >
                    Qty
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    Gross Price (₹)
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    Disc. Amt. (₹)
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    Net Price{' '}
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    GST %
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    GST Amt (₹)
                  </TableCell>
                  <TableCell align='center' style={{ fontSize: '14px', fontWeight: 600 }}>
                    Net Payable (₹)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderItem.items.map((item, index) => (
                  <TableRow key={index} style={{ borderBottom: '1.5px solid black' }}>
                    <TableCell align='left'>
                      <img
                        alt='Missing'
                        src={item.productId.productPictures[0].img}
                        width='90px'
                        height='90px'
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {item.productId.prodCode}
                      </Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {item.productId.colour} {item.productId.name}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {item.hsnCode}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {item.purchasedQty}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {toIndianNumber(item.grossPrice.toFixed(2))}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {toIndianNumber(item.discAmount.toFixed(2))}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {toIndianNumber(item.netPriceAftDisc.toFixed(2))}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {checkState(orderItem.address.state)
                          ? `${item.gst / 2}% CGST + ${item.gst / 2}% SGST`
                          : `${item.gst}% IGST`}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {toIndianNumber(item.gstAmt.toFixed(2))}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                        {toIndianNumber(item.payablePrice.toFixed(2))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow style={{ borderBottom: '1.5px solid black' }}>
                  <TableCell align='right' style={{ fontWeight: 600 }}>
                    Total
                  </TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right' style={{ fontWeight: 600 }}>
                    {totalQuantity}
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600 }}>
                    {toIndianNumber(totalGrossPrice.toFixed(2))}
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600 }}>
                    {toIndianNumber(totalDiscAmt.toFixed(2))}
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600 }}>
                    {toIndianNumber(totalNetPrice.toFixed(2))}
                  </TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right' style={{ fontWeight: 600 }}>
                    {toIndianNumber(totalGSTAmt.toFixed(2))}
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    {toIndianNumber(grandTotal.toFixed(2))}
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '1.5px solid black' }}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    Coupon
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    ₹{' '}
                    {toIndianNumber(
                      orderItem.couponPrice ? orderItem.couponPrice.toFixed(2) : '0.00',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '1.5px solid black' }}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    Shipping
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    ₹ 0.00
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '1.5px solid black' }}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    Net Total
                  </TableCell>
                  <TableCell align='right' style={{ fontWeight: 600, fontSize: '16px' }}>
                    ₹{toIndianNumber(finalAmount.toFixed(2))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item style={{ margin: '10px', border: '2px solid #E8B841' }}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>
                {' '}
                <a target='_blank' rel='noreferrer' href={`${userApp}/termsandconditions`}>
                  Terms and Conditions{' '}
                </a>
              </Typography>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Typography style={{ fontWeight: 'bold' }}>
                {' '}
                <a target='_blank' rel='noreferrer' href={`${userApp}/returnpolicy`}>
                  Return Policy
                </a>
              </Typography>
            </Grid>
            <Grid item style={{ width: '90%' }}>
              <Typography style={{ textAlign: 'justify' }}>
                Minor irregularity is the uniqueness of handlooms and makes each piece exquisite.
                Colour hues of the products may slightly vary from what appears in the images. In
                case you find any damage in the product, please email us at{' '}
                {process.env.REACT_APP_EMAIL_SUPPORT} or call us at{' '}
                {process.env.REACT_APP_PHONE_SUPPORT} within 24 hours of receiving the product. If
                you have opened the product, kindly fold it properly as received, or else the seller
                might reject to entertain its return.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
Invoice.displayName = 'Invoice';
export default Invoice;
