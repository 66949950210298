const findOpeningBal = (openingBalArray, year) => {
  // console.log(openingBalArray[2])
  const openingBalObject = openingBalArray?.find((item) => {
    // console.log((new Date(item.date1).getFullYear()))
    // console.log((new Date(`04/01/${year}`).getFullYear()))
    // console.log((new Date(item.date2).getFullYear()))
    // console.log((new Date(`03/31/${year+1}`).getFullYear()))

    // return (formatDate2(new Date(item.date1)) == formatDate2(new Date(`04/01/${year}`)) && formatDate2(new Date(`03/31/${year+1}`)) == formatDate2(new Date(item.date2)))
    return new Date(item.date1).getFullYear() == year;
  });
  return Number(openingBalObject?.openingBal);
};
export default findOpeningBal;
