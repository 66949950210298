import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { pageConstants } from './constants';
export const createPage = (form) => {
  return async (dispatch) => {
    dispatch({ type: pageConstants.CREATE_PAGE_REQUEST });
    try {
      const res = await axios.post('/pages', form);
      if (res.status === 201) {
        dispatch({
          type: pageConstants.CREATE_PAGE_SUCCESS,
          payload: { page: res.data.page },
        });
        toast.success('Page created successfully!');
      } else {
        dispatch({
          type: pageConstants.CREATE_PAGE_FAILURE,
          payload: { error: res.data.error },
        });
        toast.error('Page creation failed! Please try later');
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllPage = () => {
  return async (dispatch) => {
    //dispatch a get product request
    // dispatch({ type: productConstants.GET_ALL_PRODUCTS_REQUEST });

    axios
      .get('/pages')
      .then((response) => {
        console.log(response);
        const { pages } = response.data;
        dispatch({
          type: pageConstants.GET_ALL_PAGES_SUCCESS,
          payload: { pages: pages },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: pageConstants.GET_ALL_PAGES_FAILURE,
          payload: { error: error },
        });
      });
  };
};
