import { Button, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import Layout from '../../../layouts/Layout';
import { addCoupon } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import AddCoupon from './AddCoupon';
import UpdateCoupon from './UpdateCoupon';

const Coupons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon);
  const [open, setOpen] = useState(false);
  const [showUpdateCouponDialog, setShowUpdateCouponDialog] = useState(false);

  const [name, setName] = useState('');
  const [percent, setPercent] = useState('');

  const [myCoupon, setMyCoupon] = useState('');

  const handleClose = () => {
    const form = {};
    form.name = name;
    form.percent = percent;
    form.show = true;

    if (name === '') {
      toast.error('Coupon name is required!');
      return;
    }
    if (percent === '') {
      toast.error('Percent is required!');
      return;
    }

    dispatch(addCoupon(form));
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns = [
    { field: 'id', width: 100 },
    { field: 'Name', width: 150 },
    { field: 'Percent', width: 150 },
    {
      field: 'Status',
      renderCell: (params) => (
        <div
          style={{
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            backgroundColor: params.row.Status ? 'green' : 'red',
          }}
        ></div>
      ),
      width: 150,
      align: 'center',
    },
    {
      field: 'Edit',
      renderCell: (params) => (
        <Button
          variant='contained'
          style={{ backgroundColor: mainColour2 }}
          size='small'
          onClick={() => {
            setMyCoupon(params.row.item);
            setShowUpdateCouponDialog(true);
          }}
        >
          <EditIcon style={{ color: 'white' }} />
        </Button>
      ),
      width: 150,
    },
  ];

  const rowss = coupon.coupons.map((item, idx) => {
    return {
      id: idx + 1,
      Name: item.name,
      Percent: item.percent,
      Status: item.show,
      item: item,
    };
  });

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            COUPONS ({coupon.coupons.length})
          </Typography>
        </Grid>
        <Grid item>
          <Button style={{ color: mainColour2 }} variant='contained' onClick={handleClickOpen}>
            <AddCircleIcon color='secondary' style={{ color: mainColour2 }} /> Add
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        {!coupon.loading ? (
          <MyTable columns={columns} rows={rowss} loading={coupon.loading} />
        ) : (
          <MyTableSkeleton />
        )}
      </Grid>
      <AddCoupon
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        name={name}
        setName={setName}
        percent={percent}
        setPercent={setPercent}
      />
      <UpdateCoupon
        key={myCoupon._id}
        myCoupon={myCoupon}
        open={showUpdateCouponDialog}
        setOpen={setShowUpdateCouponDialog}
      />
    </Layout>
  );
};

export default Coupons;
