import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { couponConstants } from './constants';

const getCoupons = () => {
  return async (dispatch) => {
    dispatch({ type: couponConstants.GET_ALL_COUPONS_REQUEST });

    axios
      .get('/admin/coupons')
      .then((response) => {
        const { coupons } = response.data;
        dispatch({
          type: couponConstants.GET_ALL_COUPONS_SUCCESS,
          payload: { coupons: coupons },
        });
      })
      .catch((error) => {
        dispatch({
          type: couponConstants.GET_ALL_COUPONS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addCoupon = (form) => {
  return async (dispatch) => {
    dispatch({ type: couponConstants.ADD_NEW_COUPON_REQUEST });

    axios
      .post('/admin/coupons', form)
      .then((response) => {
        dispatch({
          type: couponConstants.ADD_NEW_COUPON_SUCCESS,
          payload: { coupon: response.data.coupon },
        });

        toast.success('Coupon created!');
      })
      .catch((error) => {
        dispatch({
          type: couponConstants.ADD_NEW_COUPON_FAILURE,
          payload: { error: error },
        });

        toast.error('Coupon already present in database!');
      });
  };
};

export const updateCoupon = (form) => {
  return async (dispatch) => {
    dispatch({ type: couponConstants.UPDATE_COUPON_REQUEST });

    axios
      .put(`/admin/coupons/${form._id}`, form)
      .then((response) => {
        dispatch({
          type: couponConstants.UPDATE_COUPON_SUCCESS,
          payload: { coupon: response.data.coupon },
        });
        dispatch(getCoupons());
        toast.success('Coupon updated!');
      })
      .catch((error) => {
        dispatch({
          type: couponConstants.UPDATE_COUPON_FAILURE,
          payload: { error: error },
        });

        toast.error('Error updating coupon!');
      });
  };
};

export { getCoupons };
