import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../assets/css/useStyles';
import MyTable from '../../../../components/MyTable';
import MyTableSkeleton from '../../../../components/MyTableSkeleton';
import formatDate2 from '../../../../helpers/formatDate2';
import { getAllJournals } from '../../../../redux/actions';
import UpdateJournalDialogbox from './UpdateJournal/UpdateJournalDialogbox';

const JournalsTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const journal = useSelector((state) => state.journal);
  const year = useSelector((state) => state.global.year);

  const [hide, setHide] = useState(false);

  /***********************************Get All journal entries****************************************/
  const allJournalColumns = [
    { field: 'id', width: 100 },
    { field: 'Date', type: 'dateTime', width: 175, align: 'left' },
    { field: 'Voucher', width: 120, align: 'left' },
    { field: 'Account_Name', width: 200, align: 'left' },
    { field: 'Account_Subname', width: 200, align: 'left' },
    { field: 'Debit', width: 120, align: 'right' },
    { field: 'Credit', width: 120, align: 'right' },
    { field: 'Narration', width: 400, align: 'left' },
  ];

  let uid = 0;
  let allJournalRows = [];
  journal.allJournals.map((item) => {
    const entries = item.entries;
    return entries.map((entry) => {
      uid += 1;
      allJournalRows.push({
        id: uid,
        item: entry,
        Date: formatDate2(item.date),
        Voucher: item.voucherNum,
        Account_Name: entry.accountName.name,
        Account_Subname: entry.accountName.subname,
        Debit: Math.abs(entry.dbAmount).toFixed(2),
        Credit: entry.crAmount.toFixed(2),
        Narration: entry.narration,
      });
    });
  });

  allJournalRows.length > 0 &&
    allJournalRows.push({
      id: uid + 1,
      Voucher: 'Total',
      Debit: Number(
        allJournalRows.reduce((a, b) => ({ Debit: Number(a.Debit) + Number(b.Debit) })).Debit,
      ).toFixed(2),
      Credit: Number(
        allJournalRows.reduce((a, b) => ({ Credit: Number(a.Credit) + Number(b.Credit) })).Credit,
      ).toFixed(2),
    });
  /***************************************************************************************/

  const [myJournal, setMyJournal] = useState(null);
  const [myVoucherNum, setMyVoucherNum] = useState(null);
  const [updateJournalDialog, setUpdateJournalDialog] = useState(false);

  const showUpdateJournalDialog = (Voucher) => {
    setUpdateJournalDialog(true);
    setMyVoucherNum(Voucher);
    const record = journal.allJournals.find((journal) => Voucher === journal.voucherNum);
    setMyJournal(record);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: '10px' }}>
        {hide ? (
          <Button
            onClick={() => {
              journal.allJournals.length === 0 && year && dispatch(getAllJournals({ year: year }));
              setHide(false);
            }}
            variant='contained'
            className={classes.button2}
          >
            View All
          </Button>
        ) : (
          <Button onClick={() => setHide(true)} variant='contained' className={classes.button2}>
            Hide All
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        {!journal.loading ? (
          hide ? null : (
            <MyTable
              rows={allJournalRows}
              columns={allJournalColumns}
              rowHeight={60}
              showUpdateJournalDialog={showUpdateJournalDialog}
            />
          )
        ) : (
          <MyTableSkeleton />
        )}
      </Grid>
      {updateJournalDialog && (
        <UpdateJournalDialogbox
          journal={myJournal}
          voucherNum={myVoucherNum}
          updateJournalDialog={updateJournalDialog}
          setUpdateJournalDialog={setUpdateJournalDialog}
        />
      )}
    </Grid>
  );
};

export default React.memo(JournalsTable);
