import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrintPaperHeading from '../../../../../components/Accounts/PrintPaperHeading';
import TableCell from '../../../../../components/Accounts/TableCell';
import TableRow from '../../../../../components/Accounts/TableRow';
import { ACCOUNT_GROUP_HEAD } from '../../../../../helpers/constants';
import formatDate2 from '../../../../../helpers/formatDate2';
import getAccountBalance from '../../../../../helpers/getAccountBal';
import { toIndianNumber } from '../../../../../helpers/toIndianNumber';
import { saveGrossProfitCD } from '../../../../../redux/actions';

const TradingAccountLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const trialBalance = useSelector((state) => state.trialBalance);
  const trialBalanceDetails = useSelector((state) =>
    state.trialBalance.trialBalanceDetails.slice(0, -1),
  );

  const purchase = trialBalanceDetails.find((item) => item.Account_Name === 'Purchase');
  let purchaseName = 'Purchase';
  let purchaseBal = getAccountBalance(purchase);

  const purchaseReturn = trialBalanceDetails.find(
    (item) => item.Account_Name === 'Purchase Return',
  );
  let purchaseReturnName = 'Purchase Return';
  let purchaseReturnBal = getAccountBalance(purchaseReturn);

  const sales = trialBalanceDetails.find((item) => item.Account_Name === 'Sales');
  let salesName = 'Sales';
  let salesBal = getAccountBalance(sales);

  const salesReturn = trialBalanceDetails.find((item) => item.Account_Name === 'Sales Return');
  let salesReturnName = 'Sales Return';
  let salesReturnBal = getAccountBalance(salesReturn);

  const openingStockTrd = trialBalanceDetails.find(
    (item) => item.Account_Name === 'Opening Stock Trd',
  );
  let openingStockTrdName = 'Opening Stock Trd';
  let openingStockTrdBal = getAccountBalance(openingStockTrd);

  const closingStockTrd = trialBalanceDetails.find(
    (item) => item.Account_Name === 'Closing Stock Trd',
  );
  let closingStockTrdName = 'Closing Stock Trd';
  let closingStockTrdBal = getAccountBalance(closingStockTrd);

  const directExpenses = trialBalanceDetails.filter(
    (item) => item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.directExp.id,
  );

  let expensesSum = 0;
  directExpenses?.forEach((item) => (expensesSum += Number(getAccountBalance(item))));

  const rightSideTotal = (Number(salesBal - salesReturnBal) + Number(closingStockTrdBal)).toFixed(
    2,
  );
  const leftSideTotal = (
    Number(openingStockTrdBal) +
    Number(purchaseBal - purchaseReturnBal) +
    Number(expensesSum)
  ).toFixed(2);

  const grossProfitCD = Number((Number(rightSideTotal) - Number(leftSideTotal)).toFixed(2));
  if (trialBalance.grossProfitCD !== grossProfitCD) {
    dispatch(saveGrossProfitCD(grossProfitCD));
  }

  return (
    <Paper ref={ref} style={{ margin: 50, border: '2px solid gray' }}>
      <PrintPaperHeading
        heading={`Trading Account for the year ended ${formatDate2(trialBalance.toDate)}`}
      />
      <TableContainer style={{ display: 'flex', borderRadius: '0' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Particulars</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='left'>To {openingStockTrdName}</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>₹ {toIndianNumber(openingStockTrdBal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>To {purchaseName}</TableCell>
              <TableCell align='right'>₹ {toIndianNumber(purchaseBal)}</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Less {purchaseReturnName}</TableCell>
              <TableCell align='right'>₹ {toIndianNumber(purchaseReturnBal)}</TableCell>
              <TableCell align='right'>
                ₹ {toIndianNumber((purchaseBal - purchaseReturnBal).toFixed(2))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' bold={true}>
                Direct Expenses
              </TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {directExpenses.length > 0 &&
              directExpenses.map((item, idx) => {
                const sum = Math.abs(Number(item.CB_Debit) - Number(item.CB_Credit)).toFixed(2);
                expensesSum += Number(sum);
                return (
                  <TableRow key={idx}>
                    <TableCell align='left'>To {item.Account_Name}</TableCell>
                    <TableCell align='right'></TableCell>
                    <TableCell align='right'>₹ {toIndianNumber(sum)}</TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell align='left'>To Gross Profit C/D</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>₹ {toIndianNumber(grossProfitCD)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right' bold={true}>
                Total
              </TableCell>
              <TableCell align='right' bold={true}>
                ₹ {toIndianNumber((grossProfitCD + Number(leftSideTotal)).toFixed(2))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Particulars</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='left'>By {salesName}</TableCell>
              <TableCell align='right'>₹ {toIndianNumber(salesBal)}</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Less {salesReturnName}</TableCell>
              <TableCell align='right'>₹ {toIndianNumber(salesReturnBal)}</TableCell>
              <TableCell align='right'>
                ₹ {toIndianNumber((salesBal - salesReturnBal).toFixed(2))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>By {closingStockTrdName}</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>₹ {toIndianNumber(closingStockTrdBal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {directExpenses.length > 0 &&
              directExpenses.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell align='left'> </TableCell>
                    <TableCell align='right'></TableCell>
                    <TableCell align='right'></TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right' bold={true}>
                ₹ {toIndianNumber(rightSideTotal)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

TradingAccountLayout.displayName = 'TradingAccountLayout';
export default TradingAccountLayout;
