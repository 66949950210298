import { Button, Grid, Switch } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomInput from '../../../components/CustomInput';
import { Dialog, DialogContent, DialogTitle } from '../../../components/Dialog';
import { updateCoupon } from '../../../redux/actions';
const UpdateCoupon = (props) => {
  const { myCoupon, open, setOpen } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState(myCoupon.name);
  const [percent, setPercent] = useState(myCoupon.percent);
  const [show, setShow] = useState(myCoupon.show);

  const handleClose = () => {
    const form = {};
    form._id = myCoupon._id;
    form.name = name;
    form.percent = percent;
    form.show = show;

    dispatch(updateCoupon(form));
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={() => setOpen(false)}>
        Edit Coupon
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomInput
            label='Name'
            placeholder='Name'
            value={name}
            type='text'
            onChange={(e) => setName(e.target.value)}
            eyeIcon={false}
            showInputValue={true}
          />
          <CustomInput
            label='Percent'
            placeholder='Percent'
            value={percent}
            type='text'
            onChange={(e) => setPercent(e.target.value)}
            eyeIcon={false}
            showInputValue={true}
          />
          <Switch
            checked={show}
            onChange={() => setShow((prev) => !prev)}
            style={{ color: 'green' }}
          />
        </div>
        <Grid container direction='row' justifyContent='center'>
          <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
            Update
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateCoupon;
