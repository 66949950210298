import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../assets/css/useStyles';
import { updateCash } from '../../../../redux/actions';

const UpdateCashDialogbox = (props) => {
  const classes = useStyles();
  const {
    cash,
    updateCashDialog,
    setUpdateCashDialog,
    accountNameList1,
    accountSubnameList1,
    accountNameRedux,
  } = props;
  const dispatch = useDispatch();

  const year = useSelector((state) => state.global.year);

  const [date, setDate] = useState(cash.date);
  const [accountName, setAccountName] = useState(cash.accountName.name);
  const [accountSubname, setAccountSubname] = useState(cash.accountName.subname);
  const [accountNameList, setAccountNameList] = useState(accountNameList1);
  const [accountSubnameList, setAccountSubnameList] = useState(accountSubnameList1);
  const [type, setType] = useState(cash.type);
  const [amount, setAmount] = useState(Math.abs(cash.amount));
  const [narration, setNarration] = useState(cash.narration);

  useEffect(() => {
    const list = accountNameRedux.accountNames.filter((item) => item.name === accountName);
    const subnameList = list.map((item) => item.subname);
    setAccountSubnameList(subnameList);
  }, [accountName]);
  const updateCashForm = () => {
    setUpdateCashDialog(false);

    let accountNameId;
    if (accountSubname === '') {
      accountNameId = accountNameRedux.accountNames.find((item) => item.name === accountName)._id;
    } else {
      accountNameId = accountNameRedux.accountNames.find(
        (item) => item.subname === accountSubname && item.name === accountName,
      )._id;
    }
    const payload = {
      _id: cash._id,
      date: date,
      accountName: accountNameId,
      type: type,
      amount: type === 'Received' ? amount : amount * -1,
      narration: narration,
    };

    dispatch(updateCash(payload, year));
  };
  return (
    <Dialog
      fullWidth
      onClose={() => setUpdateCashDialog(false)}
      aria-labelledby='updateCashDialogBox'
      open={updateCashDialog}
    >
      <DialogTitle id='updateAccountDialogBox' onClose={() => setUpdateCashDialog(false)}>
        Update Cash Details
      </DialogTitle>
      <DialogContent dividers>
        {cash && (
          <>
            <div style={{ textAlign: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat='dd/MM/yyyy'
                  label='Opening Date'
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} variant='outlined' focused />}
                />
              </LocalizationProvider>
            </div>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  id='account_name_select'
                  options={accountNameList}
                  getOptionLabel={(option) => option}
                  value={accountName}
                  onChange={(e, value) => setAccountName(value)}
                  renderInput={(params) => (
                    <TextField {...params} label='Account Name' variant='outlined' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  id='accountSubame_select'
                  options={accountSubnameList}
                  getOptionLabel={(option) => option}
                  value={accountSubname}
                  onChange={(e, value) => setAccountSubname(value)}
                  renderInput={(params) => (
                    <TextField {...params} label='Account Subname' variant='outlined' />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  id='received_payment_select'
                  options={['Received', 'Payment']}
                  getOptionLabel={(option) => option}
                  value={type}
                  onChange={(e, value) => setType(value)}
                  renderInput={(params) => (
                    <TextField {...params} required label='Receipt/Payment' variant='outlined' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type='number'
                  variant='outlined'
                  value={amount}
                  label='Amount'
                  placeholder='Amount'
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Grid>
            </Grid>

            <TextField
              style={{ marginTop: '10px' }}
              fullWidth
              variant='outlined'
              value={narration}
              label='Narration'
              placeholder='Narration'
              onChange={(e) => setNarration(e.target.value)}
            />

            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <Button autoFocus onClick={updateCashForm} className={classes.button2}>
                Save changes
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateCashDialogbox;
