import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import PrintPaperHeading from '../../../../../components/Accounts/PrintPaperHeading';
import TableCell from '../../../../../components/Accounts/TableCell';
import TableRow from '../../../../../components/Accounts/TableRow';
import { ACCOUNT_GROUP_HEAD, BALANCE_SHEET_ID } from '../../../../../helpers/constants';
import formatDate2 from '../../../../../helpers/formatDate2';
import getAccountBalance from '../../../../../helpers/getAccountBal';
import { toIndianNumber } from '../../../../../helpers/toIndianNumber';

const BalanceSheetLayout = React.forwardRef((props, ref) => {
  const trialBalance = useSelector((state) => state.trialBalance);
  const trialBalanceDetails = useSelector((state) =>
    state.trialBalance.trialBalanceDetails.slice(0, -1),
  );

  //Balance Sheet
  const currentLiabilities = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === BALANCE_SHEET_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.currentLiabilities.id,
  );
  const fixedLiabilities = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === BALANCE_SHEET_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.shortTermLiabilities.id,
  );
  const longtermLiabilities = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === BALANCE_SHEET_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.longTermLiabilies.id,
  );
  const currentAssets = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === BALANCE_SHEET_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.currentAssets.id &&
      item.Account_Name !== 'Drawing',
  );
  const fixedAssets = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === BALANCE_SHEET_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.fixedAssets.id,
  );

  //drawing account from current assets
  const drawing = trialBalanceDetails.find((item) => item.Account_Name === 'Drawing');
  const drawingName = drawing ? drawing.Account_Name : 'Drawing';
  const drawingBal = drawing
    ? Math.abs(Number(drawing.CB_Debit) - Number(drawing.CB_Credit)).toFixed(2)
    : Number(0).toFixed(2);

  const currentLiabilitiesName = 'Current Liabilities';
  const fixedLiabilitiesName = 'Fixed Liabilities';
  const longtermLiabilitiesName = 'Long Term Liabilities';
  const currentAssetsName = 'Current Assets';
  const fixedAssetsName = 'Fixed Assets';

  let longtermLiabilitiesTotal =
    Number(trialBalance.netProfitCD).toFixed(2) - Number(drawingBal).toFixed(2);

  let leftSideTotal = Number(longtermLiabilitiesTotal);
  let rightSideTotal = 0;

  // Balance rows at left and right side------------------------------------------------------------
  const leftRows =
    6 + currentLiabilities.length + fixedLiabilities.length + longtermLiabilities.length;
  const rightRows = 4 + currentAssets.length + fixedAssets.length;
  const extraRows = leftRows - rightRows;
  const dummyArray = new Array(Math.abs(extraRows)).fill(0);
  //--------------------------------------------------------------------------------------------------

  return (
    <Paper ref={ref} style={{ margin: 50, border: '2px solid gray' }}>
      <PrintPaperHeading heading={`Balance Sheet as on date ${formatDate2(trialBalance.toDate)}`} />
      <TableContainer style={{ display: 'flex', borderRadius: '0' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid gray' }}>
              <TableCell align='left'>Particulars</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='left' bold={true}>
                {currentLiabilitiesName}
              </TableCell>
            </TableRow>
            {currentLiabilities?.map((item, idx) => {
              const diff = getAccountBalance(item);
              leftSideTotal += Number(diff);
              return (
                <TableRow key={idx}>
                  <TableCell align='left'>{item.Account_Name}</TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'>₹ {toIndianNumber(diff)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell align='left' bold={true}>
                {fixedLiabilitiesName}
              </TableCell>
            </TableRow>
            {fixedLiabilities?.map((item, idx) => {
              const diff = getAccountBalance(item);
              leftSideTotal += Number(diff);
              return (
                <TableRow key={idx}>
                  <TableCell align='left'>{item.Account_Name}</TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'>₹ {toIndianNumber(diff)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell align='left' bold={true}>
                {longtermLiabilitiesName}
              </TableCell>
            </TableRow>
            {longtermLiabilities?.map((item, idx) => {
              const diff = getAccountBalance(item);
              leftSideTotal += Number(diff);
              longtermLiabilitiesTotal += Number(diff);
              return (
                <TableRow key={idx}>
                  <TableCell align='left'>{item.Account_Name}</TableCell>
                  <TableCell align='right'>₹ {toIndianNumber(diff)}</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell align='left'>Add Net Profit</TableCell>
              <TableCell align='right'>₹ {toIndianNumber(trialBalance.netProfitCD)}</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Less {drawingName}</TableCell>
              <TableCell align='right'>₹ {toIndianNumber(drawingBal)}</TableCell>
              <TableCell align='right'>
                ₹ {toIndianNumber(longtermLiabilitiesTotal.toFixed(2))}
              </TableCell>
            </TableRow>
            {/* insert rows to balance both sides */}
            {extraRows < 0 &&
              dummyArray?.map((item, idx) => (
                <TableRow key={item + idx}>
                  <TableCell align='left'></TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right' bold={true}>
                Total
              </TableCell>
              <TableCell align='right' bold={true}>
                ₹ {toIndianNumber(leftSideTotal.toFixed(2))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid gray' }}>
              <TableCell align='left'>Particulars</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='left' bold={true}>
                {currentAssetsName}
              </TableCell>
            </TableRow>
            {currentAssets?.map((item, idx) => {
              const diff = getAccountBalance(item);
              rightSideTotal += Number(diff);
              return (
                <TableRow key={idx}>
                  <TableCell align='left'>{item.Account_Name}</TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'>₹ {toIndianNumber(diff)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell align='left'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' bold={true}>
                {fixedAssetsName}
              </TableCell>
            </TableRow>
            {fixedAssets?.map((item, idx) => {
              const diff = getAccountBalance(item);
              rightSideTotal += Number(diff);
              return (
                <TableRow key={idx}>
                  <TableCell align='left'>{item.Account_Name}</TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'>₹ {toIndianNumber(diff)}</TableCell>
                </TableRow>
              );
            })}
            {/* insert rows to balance both sides */}
            {extraRows > 0 &&
              dummyArray.map((item, idx) => (
                <TableRow key={item + idx}>
                  <TableCell align='left'></TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right' bold={true}>
                ₹ {toIndianNumber(rightSideTotal.toFixed(2))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

BalanceSheetLayout.displayName = 'BalanceSheetLayout';
export default BalanceSheetLayout;
