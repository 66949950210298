import { cartConstants } from '../actions/constants';

const initialState = {
  carts: [],
  loading: false,
  error: null,
};

const cartReducers = (state = initialState, action) => {
  switch (action.type) {
    case cartConstants.GET_ALL_CARTS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cartConstants.GET_ALL_CARTS_SUCCESS:
      state = {
        ...state,
        carts: action.payload.carts.reverse(),
        loading: false,
      };
      break;

    case cartConstants.GET_ALL_CARTS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
      break;
  }

  return state;
};

export default cartReducers;
