import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import useStyles from '../../assets/css/useStyles';
import Layout from '../../layouts/Layout';
/**
 * @author
 * @function ExternalLinks
 **/

const ExternalLinks = () => {
  const classes = useStyles();

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            EXTERNAL LINKS
          </Typography>
        </Grid>
        {/* <Grid item>
                    <Button fullWidth style={{ color: mainColour2 }} variant="contained" onClick={handleClickOpen}>
                        <AddCircleIcon color="secondary" style={{ color: mainColour2 }} /> Add
                    </Button>
                </Grid> */}
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={1}>
          <a
            href='https://www.sambalpurifab.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              USER WEBSITE
            </Button>
          </a>
        </Grid>
        <Grid item sm={2}>
          <a
            href='https://accounts.sambalpurifab.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              ACCOUNTS PACKAGE
            </Button>
          </a>
        </Grid>

        <Grid item sm={2}>
          <a
            href='https://email.sambalpurifab.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              EMAIL
            </Button>
          </a>
        </Grid>

        <Grid item xs={12} sm={1}>
          <a
            href='https://admin.sambalpurifab.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              ADMIN PANEL
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://analytics.google.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              GOOGLE ANALYTICS
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://ads.google.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              GOOGLE ADS
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://marketplace.gocoop.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              GOCOOP MRKTPLACE
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://gst.gov.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              GST PORTAL
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://ewaybillgst.gov.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              E-WAY BILL
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://netpnb.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              PNB NETBANKING
            </Button>
          </a>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={1}>
          <a
            href='https://email.sambalpurifab.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              EMAIL
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://aws.amazon.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              AWS
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://razorpay.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              Razorpay
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://shiprocket.in'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              Shiprocket
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a
            href='https://cloud.mongodb.com'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              DATABASE
            </Button>
          </a>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ExternalLinks;
