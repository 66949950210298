import { cashConstants } from '../actions/constants';

const initialState = {
  cashes: [],
  loading: false,
  error: null,
};

const cashReducers = (state = initialState, action) => {
  switch (action.type) {
    case cashConstants.GET_ALL_CASHES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cashConstants.GET_ALL_CASHES_SUCCESS:
      state = {
        ...state,
        cashes: action.payload.cashes.reverse(),
        loading: false,
      };
      break;

    case cashConstants.ADD_NEW_CASH_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cashConstants.ADD_NEW_CASH_SUCCESS: {
      const cash = action.payload.cash;
      state.cashes.unshift(cash);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case cashConstants.ADD_NEW_CASH_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case cashConstants.UPDATE_CASH_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cashConstants.UPDATE_CASH_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case cashConstants.UPDATE_CASH_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case cashConstants.DELETE_CASH_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case cashConstants.DELETE_CASH_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case cashConstants.DELETE_CASH_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default cashReducers;
