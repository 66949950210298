export const PAYMENT = 'Payment';
export const RECEIVED = 'Received';

export const CASH = 'Cash';
export const SUNDRY_CREDITORS = 'Sundry Creditors';

export const OPENING_STOCK_TRD_ID = '62c82bd7557b628ba8a4734a';

// Account Heads
export const ASSETS_ID = '6288ea28832967516b640081';
export const CAPITAL_ID = '6288ea4d832967516b640082';
export const EXPENSES_ID = '6288eb61832967516b640083';
export const LIABILITIES_ID = '6288eb7e832967516b640084';
export const REVENUE_ID = '6288eb8c832967516b640085';

// PNL Heads
export const MANUFACTURING_ACCOUNT_ID = '6288ea28832967516b640081';
export const TRADING_ACCOUNT_ID = '6288ea4d832967516b640082';
export const PNL_ACCOUNT_ID = '6288eb61832967516b640083';
export const BALANCE_SHEET_ID = '6288eb7e832967516b640084';

// Account Group Heads

export const ACCOUNT_GROUP_HEAD = {
  longTermLiabilies: {
    id: '629b78ee8b09d8eb3f047067',
    name: 'Long Term Liabilities',
  },
  repairsAndDepre: {
    id: '62d00a8f29346018fafd1370',
    name: 'Repairs & Depreciation',
  },
  financialExp: {
    id: '62a5d3b38bb2c826041d4696',
    name: 'Financial Expenses',
  },
  directIncome: {
    id: '629b7fb18b09d8eb3f0470d1',
    name: 'Direct Income',
  },
  sellingAndDistExp: {
    id: '629b7e358b09d8eb3f0470bf',
    name: 'Selling & Distribution Expenses',
  },
  openingStock: {
    id: '62c82bbc557b628ba8a4733d',
    name: 'Opening Stock',
  },
  lossAndMiscExp: {
    id: '62b67d8986e22b42cd03185e',
    name: 'Losses & Misc. Expenses',
  },
  indirectIncome: {
    id: '62a729eed8471047ca9fd662',
    name: 'Indirect Income',
  },
  grossProfit: {
    id: '62c82c167d0c233123fed2a7',
    name: 'Gross Profit',
  },
  currentAssets: {
    id: '629b77c78b09d8eb3f047058',
    name: 'Current Assets',
  },
  otherIncome: {
    id: '62a5dac104711fe9d24ea63d',
    name: 'Other Income',
  },
  purchase: {
    id: '629b7a2a8b09d8eb3f047070',
    name: 'Purchase',
  },
  fixedAssets: {
    id: '629b7b798b09d8eb3f04708e',
    name: 'Fixed Assets',
  },
  sales: {
    id: '629b7aab8b09d8eb3f04707f',
    name: 'Sales',
  },
  closingStock: {
    id: '629b7eaa8b09d8eb3f0470c8',
    name: 'Closing Stock',
  },
  currentLiabilities: {
    id: '629b8b3deab45c799dcc086d',
    name: 'Current Liabilities',
  },
  shortTermLiabilities: {
    id: '62d1125fd2e1015893892d77',
    name: 'Short Term Liabilities',
  },
  directExp: {
    id: '629b7d588b09d8eb3f0470aa',
    name: 'Direct Expenses',
  },
  officeManagementExp: {
    id: '62d00a8129346018fafd1363',
    name: 'Office & Management Expenses',
  },
};
