import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from './HOC/PrivateRoute';
import ExternalLinks from './pages/ExternalLinks';
import Signin from './pages/Signin';
import AccountMaster from './pages/accounts/AccountMaster';
import BuyerSellerMaster from './pages/accounts/BuyerSellerMaster';
import Transactions from './pages/accounts/Transactions';
import BalanceSheet from './pages/accounts/Transactions/BalanceSheet';
import Cash from './pages/accounts/Transactions/Cash';
import Journal from './pages/accounts/Transactions/Journal';
import Ledger from './pages/accounts/Transactions/Ledger';
import PnlAccount from './pages/accounts/Transactions/PnlAccount';
import TradingAccount from './pages/accounts/Transactions/TradingAccount';
import TrialBalance from './pages/accounts/Transactions/TrialBalance';
import TrialBalance2 from './pages/accounts/Transactions/TrialBalance2';
import Addresses from './pages/admin/Addresses';
import AllUsers from './pages/admin/AllUsers';
import Cards from './pages/admin/Cards';
import Carousels from './pages/admin/Carousels';
import Carts from './pages/admin/Carts';
import Categories from './pages/admin/Categories';
import Coupons from './pages/admin/Coupons';
import Home from './pages/admin/Home';
import NewPage from './pages/admin/NewPage';
import Orders from './pages/admin/Orders';
import Products from './pages/admin/Products';
import QuantityAvailable from './pages/admin/QuantityAvailable';
import Report from './pages/admin/Report';
import Subscribers from './pages/admin/Subscribers';

import {
  getInitialDataAccounts,
  getInitialDataAdmin,
  isUserLoggedIn,
  selectApp,
} from './redux/actions';
import { mainColour2 } from './utils';

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const year = useSelector((state) => state.global.year);
  const app = useSelector((state) => state.global.app);

  const [open, setOpen] = useState(true);

  useEffect(() => {
    //if user is authenticated then only dispatch the function, else don't
    if (auth.authenticate && app === 'Accounts') {
      const payload = { year: year ? year : Number(process.env.REACT_APP_DEFAULT_YEAR) };
      dispatch(getInitialDataAccounts(payload));
    } else if (auth.authenticate && app === 'Admin') {
      dispatch(getInitialDataAdmin());
    }
  }, [auth.authenticate, year, app]);

  useEffect(() => {
    if (!auth.authenticate) {
      setOpen(true);
    }
    dispatch(isUserLoggedIn());
  }, [auth.authenticate]);

  const handleSelectApp = (appSelected) => {
    dispatch(selectApp(appSelected));
    setOpen(false);
  };

  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route path='/signin' component={Signin} />
          <PrivateRoute path='/externallinks' component={ExternalLinks} />
          <PrivateRoute exact path='/' component={Home} />

          <PrivateRoute path='/categories' component={Categories} />
          <PrivateRoute path='/products' component={Products} />
          <PrivateRoute path='/orders' component={Orders} />
          <PrivateRoute path='/pages' component={NewPage} />
          <PrivateRoute path='/carousels' component={Carousels} />
          <PrivateRoute path='/cards' component={Cards} />
          <PrivateRoute path='/allusers' component={AllUsers} />
          <PrivateRoute path='/addresses' component={Addresses} />
          <PrivateRoute path='/subscribers' component={Subscribers} />
          <PrivateRoute path='/report' component={Report} />
          <PrivateRoute path='/coupons' component={Coupons} />
          <PrivateRoute path='/carts' component={Carts} />
          <PrivateRoute path='/quantityavailable' component={QuantityAvailable} />

          <PrivateRoute path='/accounts-accountmaster' component={AccountMaster} />
          <PrivateRoute path='/accounts-buyersellermaster' component={BuyerSellerMaster} />
          <PrivateRoute exact path='/transactions' component={Transactions} />
          <PrivateRoute exact path='/accounts-transactions-cash' component={Cash} />
          <PrivateRoute exact path='/accounts-transactions-ledger' component={Ledger} />
          <PrivateRoute exact path='/accounts-transactions-journal' component={Journal} />
          <PrivateRoute exact path='/accounts-transactions-trialbalance' component={TrialBalance} />
          <PrivateRoute
            exact
            path='/accounts-transactions-trialbalance2'
            component={TrialBalance2}
          />
          <PrivateRoute
            exact
            path='/accounts-transactions-tradingaccount'
            component={TradingAccount}
          />
          <PrivateRoute exact path='/accounts-transactions-pnlaccount' component={PnlAccount} />
          <PrivateRoute exact path='/accounts-transactions-balancesheet' component={BalanceSheet} />
        </Switch>
      </Router>
      <Dialog
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => setOpen(false)}
          style={{ borderBottom: `4px solid ${mainColour2}` }}
        >
          Select Application
        </DialogTitle>
        <DialogContent>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={6}>
              <Button
                style={{ width: '100%', backgroundColor: mainColour2, color: 'white' }}
                onClick={() => handleSelectApp('Admin')}
              >
                Admin
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ width: '100%', backgroundColor: mainColour2, color: 'white' }}
                onClick={() => handleSelectApp('Accounts')}
              >
                Accounts
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default App;
