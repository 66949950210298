import { Button, Grid, Switch, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import MyTable from '../../../components/MyTable';
import MyTableSkeleton from '../../../components/MyTableSkeleton';
import formatDate from '../../../helpers/formatDate';
import Layout from '../../../layouts/Layout';
import { approveAsReseller } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import UpdateOffer from './UpdateOffer';

const AllUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  const showUpdateOfferDialog = (item) => {
    setOpen(true);
    setUser(item);
  };

  const columns = [
    { field: 'id', width: 100 },
    // { field: 'Role', width: 100 },
    { field: 'Name', width: 200 },
    { field: 'Email', width: 250 },
    { field: 'Phone', width: 200 },
    {
      field: 'Reseller',
      align: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>{params.row.Reseller}</div>
            <Switch
              checked={params.row.Reseller === 'Yes'}
              onChange={() =>
                dispatch(
                  approveAsReseller({
                    user_id: params.row.user_id,
                    reseller: params.row.Reseller === 'Yes' ? false : true,
                  }),
                )
              }
              style={{ color: 'green' }}
            />
          </div>
        );
      },
      width: 135,
    },
    {
      field: 'Offer',
      align: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>{params.row.Offer}</div>
            <Button size='small' variant='outlined'>
              <EditIcon
                style={{ color: mainColour2 }}
                onClick={() => showUpdateOfferDialog(params.row)}
              />
            </Button>
          </div>
        );
      },
      width: 120,
    },
    { field: 'Joined On', width: 175 },
    { field: 'Username', width: 200 },
    // { field: 'ObjectId', width: 200 },
  ];
  const rows =
    users.customers.length > 0
      ? users.customers.map((user, idx) => {
          return {
            id: idx + 1,
            // Role: user.role,
            user_id: user._id,
            'Joined On': formatDate(user.createdAt),
            Name: user.name,
            Email: user.email,
            Phone: user.contactNumber ? user.contactNumber : null,
            Reseller: user.reseller === true ? 'Yes' : 'No',
            Offer: user.offer,
            Username: user.username,
            // ObjectId: user._id,
          };
        })
      : [];

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            ALL USERS ({users.customers.length})
          </Typography>
        </Grid>
        {!users.loading && users.customers.length > 0 ? (
          <MyTable rows={rows} columns={columns} showSearch={false} />
        ) : (
          <MyTableSkeleton />
        )}
      </Grid>

      <UpdateOffer user={user} open={open} setOpen={setOpen} />
    </Layout>
  );
};

export default AllUsers;
