import { accountHeadConstants } from '../actions/constants';

const initialState = {
  accountHeads: [],
  loading: false,
  error: null,
};

const accountHeadReducers = (state = initialState, action) => {
  switch (action.type) {
    case accountHeadConstants.GET_ALL_ACCOUNT_HEADS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountHeadConstants.GET_ALL_ACCOUNT_HEADS_SUCCESS:
      state = {
        ...state,
        accountHeads: action.payload.accountHeads,
        loading: false,
      };
      break;
    case accountHeadConstants.ADD_NEW_ACCOUNT_HEAD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountHeadConstants.ADD_NEW_ACCOUNT_HEAD_SUCCESS: {
      const accountHead = action.payload.accountHead;
      state.accountHeads.push(accountHead);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case accountHeadConstants.ADD_NEW_ACCOUNT_HEAD_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case accountHeadConstants.UPDATE_ACCOUNT_HEAD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountHeadConstants.UPDATE_ACCOUNT_HEAD_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case accountHeadConstants.UPDATE_ACCOUNT_HEAD_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default accountHeadReducers;
