import { Button, Grid } from '@material-ui/core';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '../../../../components/Dialog';
import { mainColour1, mainColour2 } from '../../../../utils';
const DeleteProductDialogbox = (props) => {
  const { setDeleteProductDialog, deleteProductDialog, myProduct, deleteProductYes } = props;
  return (
    <Dialog
      fullWidth
      onClose={() => setDeleteProductDialog(false)}
      aria-labelledby='updateProductDialogBox'
      open={deleteProductDialog}
    >
      <DialogTitle id='updateProductDialogBox' onClose={() => setDeleteProductDialog(false)}>
        Delete Product
      </DialogTitle>
      <DialogContent dividers>
        {myProduct && (
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginBottom: '20px' }}
          >
            <Grid item xs={6} md={6}>
              <Typography variant='h6'>{myProduct.name}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography variant='h6'>{myProduct.prodCode}</Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          style={{ backgroundColor: mainColour2, color: 'white' }}
          onClick={() => setDeleteProductDialog(false)}
        >
          No
        </Button>
        <Button
          variant='contained'
          style={{ backgroundColor: mainColour1, color: 'white' }}
          onClick={deleteProductYes}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProductDialogbox;
