import { Button, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import BackdropLoading from '../../../components/BackdropLoading';
import replaceImageUrl from '../../../helpers/replaceImageUrl';
import Layout from '../../../layouts/Layout';
import { addCarousel } from '../../../redux/actions';
import { mainColour2 } from '../../../utils';
import AddCarousel from './AddCarousel';

const Carousels = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const carousel = useSelector((state) => state.carousel);
  const [open, setOpen] = useState(false);

  const [occasion, setOccasion] = useState('');
  const [productPictures, setProductPictures] = useState([]);

  const handleClose = () => {
    const form = new FormData();
    form.append('occasion', occasion);
    form.append('show', true);

    for (let pic of productPictures) {
      form.append('carouselPictures', pic);
    }

    if (occasion === '') {
      toast.error('Ocassion name is required !');
      return;
    }
    if (productPictures.length < 1) {
      toast.error('Atleast one carousel is required !');
      return;
    }

    dispatch(addCarousel(form));
    setOpen(false);
    setProductPictures([]);
  };

  const handleProductPictures = (e) => {
    setProductPictures([...productPictures, e.target.files[0]]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            CAROUSELS ({carousel.carousels.length})
          </Typography>
        </Grid>
        <Grid item>
          <Button style={{ color: mainColour2 }} variant='contained' onClick={handleClickOpen}>
            <AddCircleIcon color='secondary' style={{ color: mainColour2 }} /> Add
          </Button>
        </Grid>
      </Grid>

      {carousel.loading ? (
        <BackdropLoading loading={carousel.loading} />
      ) : (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: '20px' }}
        >
          {!carousel.loading && carousel.carousels.length > 0 ? (
            carousel.carousels.map((item, idx) => {
              return (
                <Grid
                  key={idx}
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item md={1}>
                    <Typography>{idx + 1}</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography>{item.occasion}</Typography>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        backgroundColor: item.show ? 'green' : 'red',
                      }}
                    ></div>
                  </Grid>
                  <Grid item md={5} sx={5}>
                    <Grid container>
                      {item.carouselPictures.length > 0 &&
                        item.carouselPictures.map((banner, _idx) => {
                          const photo = replaceImageUrl(banner.img);
                          return (
                            <Grid item key={_idx}>
                              <a href={photo} target='_blank' rel='noopener noreferrer nofollow'>
                                <img
                                  src={photo}
                                  alt='Banner'
                                  height='75px'
                                  width='200px'
                                  style={{ marginLeft: '10px' }}
                                />
                              </a>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Skeleton animation='wave' variant='rect' height='100vh' />
          )}
        </Grid>
      )}
      <AddCarousel
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        occasion={occasion}
        setOccasion={setOccasion}
        productPictures={productPictures}
        handleProductPictures={handleProductPictures}
      />
    </Layout>
  );
};

export default Carousels;
