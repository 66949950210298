import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import useStyles from '../../../../assets/css/useStyles';
import ButtonsGroupAccounts from '../../../../components/ButtonsGroupAccounts/index';
import MyTable from '../../../../components/MyTable';
import MyTableSkeleton from '../../../../components/MyTableSkeleton';
import {
  ASSETS_ID,
  CAPITAL_ID,
  CASH,
  EXPENSES_ID,
  LIABILITIES_ID,
  REVENUE_ID,
} from '../../../../helpers/constants';
import findOpeningBal from '../../../../helpers/findOpeningBal';
import formatDate2 from '../../../../helpers/formatDate2';
import Layout from '../../../../layouts/Layout';
import {
  getCashLedger,
  getLedger,
  hideAllRecords,
  viewAllRecords,
} from '../../../../redux/actions';
import { mainColour2 } from '../../../../utils';
import LedgerLayout from './LedgerLayout';
import calculateGroupwise from './helpers/calculateGroupwise';
import getIndivialRowsList from './helpers/getIndividualRowsList';

const Ledger = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const year = useSelector((state) => state.global.year);
  const ledger = useSelector((state) => state.ledger);
  const accountNameRedux = useSelector((state) => state.accountName);
  const accountNameList = accountNameRedux.accountNames
    .map((accountName) => accountName.name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort();
  const [accountSubnameList, setAccountSubnameList] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountSubname, setAccountSubname] = useState(null);

  useEffect(() => {
    const list = accountNameRedux.accountNames.filter((item) => item.name === accountName);
    const subnameList = list.map((item) => item.subname);
    setAccountSubnameList(subnameList.sort());
  }, [accountName]);

  const docRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => docRef.current,
    documentTitle: `Ledger - ${accountName} - ${accountSubname} (${formatDate2(
      fromDate,
    )} - ${formatDate2(toDate)})`,
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    // removeAfterPrint: true
  });

  const handleLedger = () => {
    const start = new Date(`04/01/${year}`);
    const end = new Date(`03/31/${year + 1}`);

    if (!fromDate) return toast.error('FROM date is required !');
    if (!toDate) return toast.error('TO date is required !');

    if (fromDate < start || fromDate > end) {
      return toast.error('Invalid FROM Date !');
    }

    if (toDate < start || toDate > end) {
      return toast.error('Invalid TO date !');
    }
    if (fromDate > toDate) {
      return toast.error('Please check the dates !');
    }
    if (accountName === '') return toast.error('Account name is required !');
    const payload = {
      fromDate: fromDate,
      toDate: toDate,
      accountName: accountName,
      accountSubname: accountSubname !== null ? accountSubname : '',
    };
    if (accountName !== CASH) dispatch(getLedger(payload));
    else dispatch(getCashLedger({ fromDate: fromDate, toDate: toDate }));
  };

  const columns = [
    { field: 'id', width: 100 },
    { field: 'Date', type: 'dateTime', width: 120, align: 'left' },
    { field: 'Voucher', width: 120, align: 'left' },
    { field: 'Narration', width: 250, align: 'left' },
    { field: 'Debit', type: 'number', width: 120, align: 'right' },
    { field: 'Credit', type: 'number', width: 120, align: 'right' },
    { field: 'CL_Balance', type: 'number', width: 160, align: 'right' },
  ];

  const columnsGroupwise = [
    { field: 'id', width: 100 },
    { field: 'Date', type: 'dateTime', width: 200, align: 'left' },
    { field: 'Account_Name', width: 200, align: 'left' },
    { field: 'Account_Subname', width: 200, align: 'left' },
    { field: 'OP_Balance', type: 'number', width: 160, align: 'right' },
    { field: 'Debit', type: 'number', width: 120, align: 'right' },
    { field: 'Credit', type: 'number', width: 120, align: 'right' },
    { field: 'CL_Balance', type: 'number', width: 160, align: 'right' },
  ];

  let account, crdr, openingBal;
  if (accountName && accountName === CASH) {
    account = accountNameRedux.accountNames.find((element) => element.name === accountName);
    crdr = 'Dr';
  } else if (accountName && accountName !== '' && accountSubname && accountSubname !== '') {
    account = accountNameRedux.accountNames.find(
      (element) => element.name === accountName && element.subname === accountSubname,
    );
    if (account.accountHead._id == ASSETS_ID || account.accountHead._id == EXPENSES_ID) {
      //assets,expenses
      crdr = 'Dr';
    } else if (
      account.accountHead._id == LIABILITIES_ID ||
      account.accountHead._id == CAPITAL_ID ||
      account.accountHead._id == REVENUE_ID
    ) {
      //liabilities,capital,revenue
      crdr = 'Cr';
    }
  } else if (accountName && accountName !== '') {
    account = accountNameRedux.accountNames.find((element) => element.name === accountName);
    if (account.accountHead._id == ASSETS_ID || account.accountHead._id == EXPENSES_ID) {
      //assets,expenses
      crdr = 'Dr';
    } else if (
      account.accountHead._id == LIABILITIES_ID ||
      account.accountHead._id == CAPITAL_ID ||
      account.accountHead._id == REVENUE_ID
    ) {
      //liabilities,capital,revenue
      crdr = 'Cr';
    }
  }
  openingBal = account ? Number(findOpeningBal(account.openingBal, year)) : Number(0);

  // Individual record-----------------------------------------------------------------------------------------
  const rows = getIndivialRowsList(ledger, accountName, accountSubname, openingBal, crdr);
  // End--------------------------------------------------------------------------------------------------------------

  // Group by subname when only account name is given--------------------------------------------------------------
  // Ex: sundry creditors has name subnames like KC Meher, Bhima Meher etc, so we have to group by each of the subnames
  const groupBySubname =
    rows.length > 2 && accountName && accountName !== CASH
      ? rows.reduce((group, row) => {
          const { Account_Subname } = row;
          //rows.length > 2 bcoz: opening bal row and total row
          group[Account_Subname] = group[Account_Subname] ?? [];
          group[Account_Subname].push(row);
          return group;
        }, {})
      : {};

  const rowsGroupwise =
    accountName && (accountSubname === '' || accountSubname === null)
      ? calculateGroupwise(
          groupBySubname,
          accountName,
          accountNameRedux,
          year,
          fromDate,
          toDate,
          crdr,
        )
      : [];

  // End-------------------------------------------------------------------------------------------------------

  // View All records of groupwise rows----------------------------------------------------------
  const [viewAll, setViewAll] = useState(false);
  const handleViewAll = () => {
    setViewAll((prev) => !prev);
  };
  useEffect(() => {
    if (viewAll) {
      dispatch(viewAllRecords());
    } else {
      dispatch(hideAllRecords());
    }
  }, [viewAll]);
  // End-----------------------------------------------------------------------------------------------

  const rowsWhenAccountNameIsNotCash = viewAll
    ? rows.sort((a, b) => new Date(a.DateFull) - new Date(b.DateFull))
    : accountSubname === '' || accountSubname === null
    ? accountSubnameList[0] === ''
      ? rows
      : rowsGroupwise
    : rows;

  const columnsWhenAccountNameIsNotCash = viewAll
    ? columns
    : accountSubname === '' || accountSubname === null
    ? accountSubnameList[0] === ''
      ? columns
      : columnsGroupwise
    : columns;

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid
          item
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant='h4' className={classes.heading}>
            LEDGER
          </Typography>
          <Button variant='contained' className={classes.printButton} onClick={handlePrint}>
            <Print />
          </Button>
        </Grid>
        <Grid item>
          <ButtonsGroupAccounts
            btn1='Account Master'
            btn2='Cash'
            btn3='Journal'
            link1='/accounts-accountmaster'
            link2='/accounts-transactions-cash'
            link3='/accounts-transactions-journal'
          />
        </Grid>
      </Grid>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='accountName_select'
            options={accountNameList}
            getOptionLabel={(option) => option}
            value={accountName}
            onChange={(e, value) => {
              setAccountName(value);
              setAccountSubname(null); //reset subname when name is changed
              setAccountSubnameList([]); //reset subname when name is changed
            }}
            // disabled={fromDate===null || toDate===null}
            renderInput={(params) => (
              <TextField {...params} required label='Account Name' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='accountSubame_select'
            options={accountSubnameList}
            getOptionLabel={(option) => option}
            value={accountSubname}
            onChange={(e, value) => setAccountSubname(value)}
            renderInput={(params) => (
              <TextField {...params} label='Account Subname' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='From Date'
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='To Date'
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            onClick={handleLedger}
            variant='contained'
            style={{ height: '56px', backgroundColor: mainColour2, color: 'white' }}
          >
            Get Ledger
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        {!ledger.loading ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {((accountName && accountName !== '' && accountSubname && accountSubname !== '') ||
              accountName === CASH ||
              (accountSubnameList.length === 1 && accountSubnameList[0] === '')) && (
              <>
                <Typography variant='h4' className={classes.heading}>
                  OP BALANCE = Rs.{Number(openingBal).toFixed(2)}{' '}
                  {accountName === CASH ? 'Dr' : crdr}
                </Typography>
                <Typography variant='h4' className={classes.heading}>
                  CL BALANCE = Rs.{Number(rows.at(-2).CL_Balance).toFixed(2)}{' '}
                  {rows.length > 0 &&
                    (accountName === CASH
                      ? 'Dr'
                      : rows[1].Account_Head_Id === EXPENSES_ID /*expenses*/ ||
                        rows[1].Account_Head_Id === ASSETS_ID /*assets*/ // because row zero contains opening balace narration
                      ? 'Dr'
                      : 'Cr')}
                </Typography>
              </>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h4' className={classes.heading}>
              OP BALANCE = Rs.0.00 {accountName === CASH ? 'Dr' : crdr}
            </Typography>
            <Typography variant='h4' className={classes.heading}>
              CL BALANCE = Rs.0.00 {accountName === CASH ? 'Dr' : crdr}
            </Typography>
          </div>
        )}
      </Grid>

      {accountName !== '' && accountSubname === '' ? (
        <Button variant='contained' onClick={handleViewAll} className={classes.button2}>
          {viewAll ? 'Hide All' : 'View All'}
        </Button>
      ) : null}

      <div style={{ display: 'none' }}>
        <LedgerLayout
          ref={docRef}
          accountName={accountName}
          accountSubname={accountSubname}
          fromDate={fromDate}
          toDate={toDate}
          rows={rowsWhenAccountNameIsNotCash}
          columns={columnsWhenAccountNameIsNotCash}
        />
      </div>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {!ledger.loading ? (
            accountName !== CASH ? (
              (rows.length > 0 || rowsGroupwise.length > 0) && (
                <MyTable
                  rows={rowsWhenAccountNameIsNotCash}
                  columns={columnsWhenAccountNameIsNotCash}
                  rowHeight={60}
                  showSearch={false}
                />
              )
            ) : (
              <MyTable rows={rows} columns={columns} rowHeight={60} showSearch={false} />
            )
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Ledger;
