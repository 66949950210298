import { Button, Grid, Typography } from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import useStyles from '../../../../assets/css/useStyles';
import ButtonsGroupAccounts from '../../../../components/ButtonsGroupAccounts/index';
import formatDate2 from '../../../../helpers/formatDate2';
import Layout from '../../../../layouts/Layout';
import PnlAccountLayout from './PnlAccountLayout';

const PnlAccount = () => {
  const classes = useStyles();
  const trialBalance = useSelector((state) => state.trialBalance);

  const docRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => docRef.current,
    documentTitle: `PnL-${formatDate2(trialBalance.toDate)}`,
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    // removeAfterPrint: true
  });
  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid
          item
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant='h4' className={classes.heading}>
            PROFIT & LOSS ACCOUNT
          </Typography>
          <Button variant='contained' className={classes.printButton} onClick={handlePrint}>
            <Print />
          </Button>
        </Grid>
        <Grid item>
          <ButtonsGroupAccounts
            btn1='Account Master'
            btn2='Cash'
            btn3='Journal'
            link1='/accounts-accountmaster'
            link2='/accounts-transactions-cash'
            link3='/accounts-transactions-journal'
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          <PnlAccountLayout ref={docRef} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PnlAccount;
