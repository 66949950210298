import { Avatar, Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import Layout from '../../layouts/Layout';
import { login } from '../../redux/actions';
import { mainColour2 } from '../../utils';

const useStyles = makeStyles((theme) => ({
  div: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '15px',
    },
  },
  paper: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    elevation: '3',
    backgroundColor: 'white',
    borderRadius: '25px',
  },
  box: {
    border: `2px solid ${mainColour2}`,
    borderRadius: 25,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: mainColour2,
  },
  logo: {
    height: '100px',
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      height: '75px',
      width: '75px',
    },
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: mainColour2,
    color: 'white',
    '&:hover': {
      backgroundColor: mainColour2,
      transform: 'scale(1.05)',
    },
  },
  actionText: {
    color: mainColour2,
  },
}));

const Signin = () => {
  const classes = useStyles();

  const [showPasswordValue, setShowPasswordValue] = useState(false);
  const handleClickShowPasswordValue = () => {
    setShowPasswordValue((preVal) => !preVal);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userLogin = (e) => {
    e.preventDefault();
    const user = {
      email,
      password,
    };
    dispatch(login(user)); //dispatch login action
  };

  //if user is already logged in then redirect the user to Admin dashboard i.e the Home page
  if (auth.authenticate) {
    return <Redirect to={`/`} />;
  }
  return (
    <Layout model>
      <div className={classes.div}>
        <Grid
          container
          md={4}
          xs={11}
          maxWidth='md'
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Box border={2} className={classes.box}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Sign in
              </Typography>
              <form className={classes.form} onSubmit={userLogin}>
                <CustomInput
                  fullWidth
                  label='Email'
                  placeholder='Email'
                  value={email}
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                  eyeIcon={false}
                  showInputValue={true}
                />

                <CustomInput
                  fullWidth
                  label='Password'
                  placeholder='Password'
                  value={password}
                  type='password'
                  onChange={(e) => setPassword(e.target.value)}
                  eyeIcon={true}
                  showInputValue={showPasswordValue}
                  handleClickShowInputValue={handleClickShowPasswordValue}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  {!auth.authenticating ? (
                    'Sign In'
                  ) : (
                    <CircularProgress size={25} style={{ color: 'white' }} />
                  )}
                </Button>
              </form>
            </div>
          </Box>
          <Box mt={8}>
            <Typography variant='body2' color='textSecondary' align='center'>
              {'Copyright © '}
              <a href='https://sambalpurifab.in/' target='_blank' rel='noreferrer'>
                Sambalpuri Fab
              </a>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </Box>
        </Grid>
      </div>
    </Layout>
  );
};

export default Signin;
