import { Button, ButtonGroup, Grid, TextField, Typography } from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../assets/css/useStyles';
import ButtonsGroupAccounts from '../../../../components/ButtonsGroupAccounts/index';
import MyTable from '../../../../components/MyTable';
import MyTableSkeleton from '../../../../components/MyTableSkeleton';
import findOpeningBal from '../../../../helpers/findOpeningBal';
import formatDate2 from '../../../../helpers/formatDate2';
import Layout from '../../../../layouts/Layout';
import { addCash, deleteCash } from '../../../../redux/actions';
import { mainColour2 } from '../../../../utils';
import UpdateCashDialogbox from './UpdateCashDialogbox';

const Cash = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const year = useSelector((state) => state.global.year);
  const cash = useSelector((state) => state.cash);
  const accountNameRedux = useSelector((state) => state.accountName);
  const accountNameList = accountNameRedux.accountNames
    .map((accountName) => accountName.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  const [accountSubnameList, setAccountSubnameList] = useState([]);

  const [date, setDate] = useState(null);
  const [accountName, setAccountName] = useState('');
  const [accountSubname, setAccountSubname] = useState('');
  const [type, setType] = useState('Received');
  const [amount, setAmount] = useState(0);
  const [narration, setNarration] = useState('');

  useEffect(() => {
    const list = accountNameRedux.accountNames.filter((item) => item.name === accountName);
    const subnameList = list.map((item) => item.subname);
    setAccountSubnameList(subnameList);
  }, [accountName]);

  const handleCash = () => {
    if (date === '' || date === null) return toast.error('Date is required !');
    const present = new Date(date);
    const from = new Date(`04/01/${year}`);
    const to = new Date(`03/31/${year + 1}`);
    if (present < from || present > to) {
      return toast.error('Invalid Date !');
    }
    if (accountName === '') return toast.error('Account name is required !');

    if (type === '') return toast.error('Receipt/Payment type is required !');
    if (amount === 0) return toast.error('Amount is required !');
    let accountNameId;
    if (accountSubname === '') {
      accountNameId = accountNameRedux.accountNames.find((item) => item.name === accountName)._id;
    } else {
      accountNameId = accountNameRedux.accountNames.find(
        (item) => item.subname === accountSubname && item.name === accountName,
      )._id;
    }
    const payload = {
      date: date,
      accountName: accountNameId,
      // accountSubname: accountSubname,
      type: type,
      amount: type === 'Received' ? amount : amount * -1,
      narration: narration,
      year: year,
    };
    dispatch(addCash(payload, year));
    // setDate("")
    setAccountName('');
    setAccountSubname('');
    // setType("Received");
    setAmount(0);
    setNarration('');
  };

  const columns = [
    { field: 'id', width: 100 },
    // { field: 'Date', type:'dateTime',valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),width: 175, align: 'left' },
    {
      field: 'Actions',
      renderCell: (params) => (
        <ButtonGroup>
          <Button
            size='small'
            variant='outlined'
            onClick={() => showUpdateCashDialog(params.row.item)}
          >
            <EditIcon style={{ color: mainColour2 }} />
          </Button>
          <Button
            size='small'
            variant='outlined'
            onClick={() => dispatch(deleteCash({ _id: params.row.item._id }, year))}
          >
            <DeleteIcon style={{ color: 'red' }} />
          </Button>
        </ButtonGroup>
      ),
      width: 135,
      align: 'center',
    },
    { field: 'Date', type: 'dateTime', width: 175, align: 'left' },
    { field: 'Voucher', width: 135, align: 'left' },
    { field: 'Account_Name', width: 200, align: 'left' },
    { field: 'Account_Subname', width: 200, align: 'left' },
    {
      field: 'R/P',
      renderCell: (params) => (
        <Button size='small'>
          {params.row.item.type === 'Received' ? (
            <CallReceivedIcon style={{ color: 'green' }} />
          ) : (
            <CallMadeIcon style={{ color: 'red' }} />
          )}
        </Button>
      ),
      width: 120,
      align: 'center',
    },
    { field: 'Amount', width: 135, align: 'right' },
    { field: 'Narration', width: 400, align: 'left' },
  ];

  let rowss = [];
  cash.cashes.map((item, idx) => {
    return rowss.push({
      id: idx + 1,
      item,
      Date: formatDate2(item.date),
      Voucher: item.voucherNum,
      Account_Name: item.accountName.name,
      Account_Subname: item.accountName.subname,
      'R/P': item.type,
      Amount: Math.abs(item.amount).toFixed(2),
      Narration: item.narration,
    });
  });

  const [cashBal, setCashBal] = useState(0);
  useEffect(() => {
    if (accountNameRedux.accountNames.length === 0) {
      return;
    }
    const openingBalArray = accountNameRedux.accountNames.find(
      (item) => item.name === 'Cash',
    ).openingBal;
    let total = findOpeningBal(openingBalArray, year);

    if (cash.cashes.length > 0) {
      total += cash.cashes.reduce((a, b) => ({
        amount: Number(a.amount) + Number(b.amount),
      })).amount;
    }

    setCashBal(total);
  }, [cash.loading]);

  const [myCash, setMyCash] = useState(null);
  const [updateCashDialog, setUpdateCashDialog] = useState(false);

  const showUpdateCashDialog = (item) => {
    setUpdateCashDialog(true);
    const cashToBeUpdated = cash.cashes.find((cash) => item._id === cash._id);
    setMyCash(cashToBeUpdated);
  };

  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant='h4' className={classes.heading}>
            CASH {type === 'Payment' ? 'PAYMENT' : 'RECEIVED'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='dd/MM/yyyy'
              label='Date'
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required variant='outlined' focused />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='received_payment_select'
            options={['Received', 'Payment']}
            getOptionLabel={(option) => option}
            value={type}
            onChange={(e, value) => setType(value)}
            renderInput={(params) => (
              <TextField {...params} required label='Receipt/Payment' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item>
          <ButtonsGroupAccounts
            btn1='Account Master'
            btn2='Journal'
            btn3='Ledger'
            link1='/accounts-accountmaster'
            link2='/accounts-transactions-journal'
            link3='/accounts-transactions-ledger'
          />
        </Grid>
      </Grid>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='accountName_select'
            options={accountNameList}
            getOptionLabel={(option) => option}
            value={accountName}
            onChange={(e, value) => setAccountName(value)}
            disabled={date === null}
            renderInput={(params) => (
              <TextField {...params} required label='Account Name' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            autoHighlight
            id='accountSubame_select'
            options={accountSubnameList}
            getOptionLabel={(option) => option}
            value={accountSubname}
            onChange={(e, value) => setAccountSubname(value)}
            disabled={accountName === ''}
            renderInput={(params) => (
              <TextField {...params} label='Account Subname' variant='outlined' />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            type='number'
            disabled={accountName === ''}
            variant='outlined'
            value={amount}
            label='Amount'
            placeholder='Amount'
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            variant='outlined'
            value={narration}
            label='Narration'
            placeholder='Narration'
            onChange={(e) => setNarration(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            onClick={handleCash}
            variant='contained'
            className={classes.externalLinks}
            style={{ height: '56px' }}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      {/* Links */}
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          {!cash.loading ? (
            <Typography variant='h4' className={classes.heading}>
              CASH BAL = Rs.{cashBal.toFixed(2)} {cashBal > 0 ? 'Dr' : 'Cr'}
            </Typography>
          ) : (
            <Typography variant='h4' className={classes.heading}>
              CASH BAL = Rs.0.00
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item md={12} xs={12}>
          {!cash.loading ? (
            <MyTable rows={rowss} columns={columns} rowHeight={60} />
          ) : (
            <MyTableSkeleton />
          )}
        </Grid>
      </Grid>
      {updateCashDialog && (
        <UpdateCashDialogbox
          cash={myCash}
          updateCashDialog={updateCashDialog}
          setUpdateCashDialog={setUpdateCashDialog}
          accountNameList1={accountNameList}
          accountSubnameList1={accountSubnameList}
          accountNameRedux={accountNameRedux}
        />
      )}
    </Layout>
  );
};

export default Cash;
