import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { Skeleton } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomInput from '../../../../components/CustomInput';
import { Dialog, DialogContent, DialogTitle } from '../../../../components/Dialog';
import { mainColour1, mainColour2 } from '../../../../utils';
import { MultipleFileUploadField } from '../fileUpload/MultipleFileUploadField';
import RequiredText from './RequiredText';
const AddProductDialogbox = (props) => {
  const {
    category,
    open,
    setOpen,
    handleClose,
    newDesign,
    onChangeNewDesign,
    handleNewDesignSubmit,
    newProductDetails,
    setNewProductDetails,
    onChangeNewProductDetails,
    createCategoryList,
    handleProductPictures,
  } = props;

  const fabricList = ['Cotton', 'Silk', 'Tussar', ''];
  const fabricCountList = [
    '4Ply x 3Ply',
    '3Ply x 2Ply',
    '120',
    '100',
    '80',
    '60',
    '40',
    'Tussar Silk',
    '',
  ];
  const product = useSelector((state) => state.product);
  const sizeList = [...useSelector((state) => state.product.sizes.map((item) => item.size)), ''];
  const colourList = [
    ...useSelector((state) => state.product.colours.map((item) => item.colour)),
    '',
  ];
  const shadeList = [...useSelector((state) => state.product.shades.map((item) => item.shade)), ''];

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle
        id='customized-dialog-title'
        onClose={() => setOpen(false)}
        style={{ borderBottom: `4px solid ${mainColour2}` }}
      >
        Add New Product
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ backgroundColor: '#aed581' }}
        >
          <Grid
            item
            xs={4}
            md={4}
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
          >
            <FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
              <InputLabel htmlFor='newSize'>Add New Size</InputLabel>
              <OutlinedInput
                id='newSize'
                type='text'
                name='newSize'
                value={newDesign.newSize}
                onChange={onChangeNewDesign}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      color={mainColour1}
                      aria-label='publish'
                      component='span'
                      onClick={() => handleNewDesignSubmit('size', newDesign.newSize)}
                    >
                      <PublishIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='Add New Size'
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
          >
            <FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
              <InputLabel htmlFor='newColour'>Add New Colour</InputLabel>
              <OutlinedInput
                id='newColour'
                type='text'
                name='newColour'
                value={newDesign.newColour}
                onChange={onChangeNewDesign}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      color={mainColour1}
                      aria-label='publish'
                      component='span'
                      onClick={() => handleNewDesignSubmit('colour', newDesign.newColour)}
                    >
                      <PublishIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='Add New Colour'
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
          >
            <FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
              <InputLabel htmlFor='newShade'>Add New Shade</InputLabel>
              <OutlinedInput
                id='newShade'
                type='text'
                name='newShade'
                value={newDesign.newShade}
                onChange={onChangeNewDesign}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      color={mainColour1}
                      aria-label='publish'
                      component='span'
                      onClick={() => handleNewDesignSubmit('shade', newDesign.newShade)}
                    >
                      <PublishIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='Add New Shade'
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={6} md={6}>
            <FormControl variant='outlined' className='form-control' style={{ marginTop: '-20px' }}>
              <InputLabel id='categoryId_select'>Select Category</InputLabel>
              <Select
                labelId='categoryId_select'
                name='categoryId'
                value={newProductDetails.categoryId}
                onChange={onChangeNewProductDetails}
                label='Select Category'
              >
                {createCategoryList(category.categories).map((item) =>
                  item.parentId ? (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      style={{ dispaly: 'flex', justifyContent: 'flex-end' }}
                    >
                      {item.name}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      disabled
                      key={item.value}
                      value={item.value}
                      style={{ backgroundColor: 'black', color: 'white' }}
                    >
                      {item.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={6}>
            <CustomInput
              fullWidth
              name='prodCode'
              value={newProductDetails.prodCode}
              label='Product Code'
              placeholder='Product Code'
              type='text'
              onChange={onChangeNewProductDetails}
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={6} md={6}>
            {newProductDetails.categoryId === '' && <RequiredText text='Category' />}
          </Grid>
          <Grid item xs={6} md={6}>
            {newProductDetails.prodCode === '' && <RequiredText text='Product code' />}
          </Grid>
        </Grid>
        <CustomInput
          fullWidth
          name='name'
          value={newProductDetails.name}
          label='Product Name'
          placeholder='Product Name'
          type='text'
          onChange={onChangeNewProductDetails}
          eyeIcon={false}
          showInputValue={true}
        />
        {newProductDetails.name === '' && <RequiredText text='Name' />}

        <div>
          {!product.loading ? (
            <MultipleFileUploadField handleProductPictures={handleProductPictures} />
          ) : (
            <Skeleton type='rect' width='100%' height='160px' />
          )}
        </div>

        {/* <CustomInput
          fullWidth
          name='description'
          value={newProductDetails.description}
          label='Description'
          placeholder='Description'
          type='text'
          onChange={onChangeNewProductDetails}
          eyeIcon={false}
          showInputValue={true}
        /> */}
        {/* {newProductDetails.description === '' && <RequiredText text='Description' />} */}

        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={4} md={4}>
            <CustomInput
              fullWidth
              required
              name='cPrice'
              value={newProductDetails.cPrice}
              label='Cost Price'
              placeholder='Cost Price'
              type='text'
              onChange={(e) =>
                setNewProductDetails((prev) => ({
                  ...prev,
                  cPrice: e.target.value,
                  price: Math.round(
                    e.target.value * Number(process.env.REACT_APP_PRODUCT_PRICE_TIMES),
                  ),
                }))
              }
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <CustomInput
              fullWidth
              required
              name='gPrice'
              value={newProductDetails.gPrice}
              label='Gross Sell Price'
              placeholder='Gross Sell Price'
              type='text'
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <CustomInput
              fullWidth
              required
              name='Gross Sell Price + GST'
              value={Number(
                (Number(newProductDetails.gPrice) * (100 + Number(newProductDetails.GST))) / 100,
              ).toFixed(2)}
              label='Gross Sell Price + GST'
              placeholder='Gross Sell Price + GST'
              type='text'
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
        </Grid>

        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={4} md={4}>
            <CustomInput
              fullWidth
              required
              name='offer'
              value={newProductDetails.offer}
              label='Offer'
              placeholder='Offer'
              type='text'
              onChange={onChangeNewProductDetails}
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <CustomInput
              fullWidth
              required
              name='price'
              value={newProductDetails.price}
              label='Net Sell Price'
              placeholder='Net Sell Price'
              type='text'
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <CustomInput
              fullWidth
              required
              name='Net Sell Price + GST'
              value={(Number(newProductDetails.price) + Number(newProductDetails.gstAmt)).toFixed(
                2,
              )}
              label='Net Sell Price + GST'
              placeholder='Net Sell Price + GST'
              type='text'
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={6} md={6}>
            <CustomInput
              fullWidth
              required
              name='quantity'
              value={newProductDetails.quantity}
              label='Quantity'
              placeholder='Quantity'
              type='text'
              onChange={onChangeNewProductDetails}
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <CustomInput
              fullWidth
              required
              name='blouse'
              value={newProductDetails.blouse}
              label='Blouse'
              placeholder='Blouse'
              type='text'
              onChange={onChangeNewProductDetails}
              eyeIcon={false}
              showInputValue={true}
            />
          </Grid>
        </Grid>

        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={4} md={4}>
            <Autocomplete
              autoHighlight
              style={{ marginBottom: '12px' }}
              id='size_select'
              options={sizeList}
              getOptionLabel={(option) => option}
              name='size'
              value={newProductDetails.size}
              onChange={(e, value) => setNewProductDetails((prev) => ({ ...prev, size: value }))}
              renderInput={(params) => <TextField {...params} label='Size' variant='outlined' />}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Autocomplete
              autoHighlight
              style={{ marginBottom: '12px' }}
              id='colour_select'
              options={colourList}
              getOptionLabel={(option) => option}
              name='colour'
              value={newProductDetails.colour}
              onChange={(e, value) => setNewProductDetails((prev) => ({ ...prev, colour: value }))}
              renderInput={(params) => <TextField {...params} label='Colour' variant='outlined' />}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Autocomplete
              autoHighlight
              style={{ marginBottom: '12px' }}
              id='shade_select'
              options={shadeList}
              getOptionLabel={(option) => option}
              name='shade'
              value={newProductDetails.shade}
              onChange={(e, value) => setNewProductDetails((prev) => ({ ...prev, shade: value }))}
              renderInput={(params) => <TextField {...params} label='Shade' variant='outlined' />}
            />
          </Grid>
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={4} md={4}>
            {(newProductDetails.size === '' || !newProductDetails.size) && (
              <RequiredText text='Size' />
            )}
          </Grid>
          <Grid item xs={4} md={4}>
            {(newProductDetails.colour === '' || !newProductDetails.colour) && (
              <RequiredText text='Colour' />
            )}
          </Grid>
          <Grid item xs={4} md={4}>
            {(newProductDetails.shade === '' || !newProductDetails.shade) && (
              <RequiredText text='Shade' />
            )}
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={6} md={6}>
            <Autocomplete
              autoHighlight
              style={{ marginBottom: '12px' }}
              id='fabric_select'
              options={fabricList}
              getOptionLabel={(option) => option}
              name='fabric'
              value={newProductDetails.fabric}
              onChange={(e, value) => setNewProductDetails((prev) => ({ ...prev, fabric: value }))}
              renderInput={(params) => <TextField {...params} label='Fabric' variant='outlined' />}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Autocomplete
              autoHighlight
              style={{ marginBottom: '12px' }}
              id='fabric_count_select'
              options={fabricCountList}
              getOptionLabel={(option) => option}
              name='fCount'
              value={newProductDetails.fCount}
              onChange={(e, value) => setNewProductDetails((prev) => ({ ...prev, fCount: value }))}
              renderInput={(params) => (
                <TextField {...params} label='Fabric Count' variant='outlined' />
              )}
            />
          </Grid>
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={6} md={6}>
            {(newProductDetails.fabric === '' || !newProductDetails.fabric) && (
              <RequiredText text='Fabric type' />
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            {(newProductDetails.fCount === '' || !newProductDetails.fCount) && (
              <RequiredText text='Fabric count' />
            )}
          </Grid>
        </Grid>

        <Grid container direction='row' justifyContent='center'>
          <Button
            autoFocus
            onClick={() => handleClose()}
            style={{ backgroundColor: mainColour2, color: 'white', width: '100%' }}
          >
            {!product.loading ? (
              'Create'
            ) : (
              <CircularProgress size={25} style={{ color: 'white' }} />
            )}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddProductDialogbox;
