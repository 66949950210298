import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import useStyles from '../../../assets/css/useStyles';
import BackdropLoading from '../../../components/BackdropLoading';
import formatDate2 from '../../../helpers/formatDate2';
import Layout from '../../../layouts/Layout';
import { mainColour2, specialButtonColour } from '../../../utils';

const Home = () => {
  const classes = useStyles();
  const app = useSelector((state) => state.global.app);
  const order = useSelector((state) => state.order);
  const orders = useSelector((state) => state.order.orders.reverse());

  let count = 0;
  let totalDisc = 0;
  let grossPrice = 0;
  let numOfItems = [];
  let dates = [];
  let numSame = 0; // number of quanitity in each order if dates are same

  orders.map((order, idx) => {
    const items = order.items;
    const date = formatDate2(order.createdAt);

    if (dates.length === 0) {
      dates.push(date);
      for (let i in items) {
        numSame += items[i].purchasedQty;
      }
    } else if (date === dates[dates.length - 1]) {
      for (let i in items) {
        numSame += items[i].purchasedQty;
      }
    } else if (date !== dates[dates.length - 1]) {
      numOfItems.push(numSame);
      numSame = 0;
      dates.push(date);
      for (let i in items) {
        numSame += items[i].purchasedQty;
      }
    }

    if (idx === orders.length - 1) {
      numOfItems.push(numSame);
    }

    return items.map((item) => {
      count += item.purchasedQty;
      totalDisc += item.discAmount;
      grossPrice += item.grossPrice;
    });
  });

  const date1 = new Date('12/22/2021');
  const date2 = new Date();
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const days = Difference_In_Time / (1000 * 3600 * 24);
  // const days = Math.abs(day2 - day1);

  const toIndianNumber = (str) => {
    return str.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
    // Basically the regex looks for a digit which is followed by
    // digits (in groups of two) followed by a digit and a decimal point.
  };

  return (
    <Layout sidebar>
      <BackdropLoading loading={order.loading} />
      {app === 'Admin' && orders.length !== 0 ? (
        <>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            <Grid item>
              <Typography variant='h4' className={classes.heading}>
                DASHBOARD
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' className={classes.heading}>
                BASE DATE ({`${formatDate2(date1)}`})
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            <Grid
              item
              xs={5}
              md={2}
              className={classes.grid}
              style={{ backgroundColor: 'crimson' }}
            >
              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item>
                  <Typography className={classes.top}>Total orders</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h5' className={classes.middle}>
                    Rs. {toIndianNumber(grossPrice.toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.bottom}>{orders.length} orders</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} md={2} className={classes.grid} style={{ backgroundColor: 'gray' }}>
              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item>
                  <Typography className={classes.top}>Average Per Day</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h5' className={classes.middle}>
                    Rs. {toIndianNumber((grossPrice / days).toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.bottom}>
                    {toIndianNumber((orders.length / days).toFixed(2))} orders
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={5}
              md={2}
              className={classes.grid}
              style={{ backgroundColor: specialButtonColour }}
            >
              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item>
                  <Typography className={classes.top}>Items Sold</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h5' className={classes.middle}>
                    {count}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.bottom}>
                    {toIndianNumber((count / orders.length).toFixed(2))} per order
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} md={2} className={classes.grid} style={{ backgroundColor: 'green' }}>
              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item>
                  <Typography className={classes.top}>Total Discount</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h5' className={classes.middle}>
                    Rs. {toIndianNumber(totalDisc.toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.bottom}>
                    {toIndianNumber(((totalDisc / grossPrice) * 100).toFixed(2))} %
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '20px' }}
          >
            <Grid item xs={12} md={12}>
              <Bar
                height={400}
                width={600}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  },
                }}
                data={{
                  labels: dates,
                  datasets: [
                    {
                      // barPercentage: 0.5,
                      barThickness: 10,
                      // maxBarThickness: 8,
                      // minBarLength: 2,
                      label: 'Number of items',
                      data: numOfItems,
                      backgroundColor: mainColour2,
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Line
                height={400}
                width={600}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  },
                }}
                data={{
                  labels: dates,
                  datasets: [
                    {
                      // barPercentage: 0.5,
                      barThickness: 10,
                      // maxBarThickness: 8,
                      // minBarLength: 2,
                      label: 'Number of items',
                      data: numOfItems,
                      backgroundColor: mainColour2,
                    },
                  ],
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : app === 'Accounts' ? (
        <>
          <img
            src='https://sambalpuri-fab-image.s3.ap-south-1.amazonaws.com/Accountant.jpg'
            alt='Account Image'
            style={{ width: '100%', height: '90vh' }}
          />
          <div style={{ position: 'relative', top: '-60vh', textAlign: 'center' }}>
            <Typography variant='h1' style={{ color: 'white', fontWeight: 'bold' }}>
              Welcome to the Accounts of SAMBALPURI FAB
            </Typography>
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default Home;
