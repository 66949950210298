import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import PrintPaperHeading from '../../../../../components/Accounts/PrintPaperHeading';
import formatDate2 from '../../../../../helpers/formatDate2';

const LedgerLayout = React.forwardRef((props, ref) => {
  const { rows, columns, accountName, accountSubname, fromDate, toDate } = props;
  return (
    <Paper ref={ref} style={{ margin: 50, border: '2px solid gray' }}>
      <PrintPaperHeading heading={`At/Po/Dist. - Bargarh, Odisha - 768028`} />
      <Typography variant='h4' style={{ fontWeight: 600, textAlign: 'center' }}>
        {accountName}
        {accountSubname && `  -  `}
        {accountSubname}
      </Typography>
      <Typography
        variant='h4'
        style={{
          fontWeight: 600,
          textAlign: 'center',
          borderBottom: '2px solid gray',
          borderRadius: 0,
        }}
      >
        {`(${formatDate2(fromDate)} - ${formatDate2(toDate)})`}
      </Typography>
      <TableContainer style={{ display: 'flex', borderRadius: '0' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid gray' }}>
              {columns.map((col, idx) => {
                const align = ['OP_Balance', 'Debit', 'Credit', 'CL_Balance'].includes(col.field)
                  ? 'right'
                  : 'left';
                const value = col.field === 'id' ? 'Sl No.' : col.field;
                return !(
                  accountSubname === null &&
                  (col.field === 'Date' || col.field === 'Account_Name')
                ) ? (
                  <TableCell key={idx} align={align} style={{ fontWeight: 600, fontSize: '22px' }}>
                    {value}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.map((row, idx) => {
                return (
                  <TableRow key={idx}>
                    {columns.map((col, idx) => {
                      const align = ['OP_Balance', 'Debit', 'Credit', 'CL_Balance'].includes(
                        col.field,
                      )
                        ? 'right'
                        : 'left';
                      const value =
                        ['OP_Balance', 'Debit', 'Credit', 'CL_Balance'].includes(col.field) &&
                        (row[col.field] === 0 || row[col.field])
                          ? Number(row[col.field]).toFixed(2)
                          : row[col.field];
                      return !(
                        accountSubname === null &&
                        (col.field === 'Date' || col.field === 'Account_Name')
                      ) ? (
                        <TableCell key={idx} align={align} style={{ fontSize: '20px' }}>
                          {value}
                        </TableCell>
                      ) : null;
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

LedgerLayout.displayName = 'LedgerLayout';
export default LedgerLayout;
