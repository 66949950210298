import { Grid, Typography } from '@material-ui/core';

const ProductDetailField = (props) => {
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      {props.secondKey ? (
        <>
          <Grid item xs={6} md={6}>
            <Typography variant='h6'>{props.firstKey}</Typography>
            <p className='value'>{props.firstValue}</p>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography variant='h6'>{props.secondKey}</Typography>
            <p className='value'>{props.secondValue}</p>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6} md={6}>
            <Typography variant='h6'>{props.firstKey}</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <p className='value'>{props.firstValue}</p>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProductDetailField;
