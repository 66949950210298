import { couponConstants } from '../actions/constants';

const initialState = {
  coupons: [],
  loading: false,
  error: null,
};

const couponReducers = (state = initialState, action) => {
  switch (action.type) {
    case couponConstants.GET_ALL_COUPONS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case couponConstants.GET_ALL_COUPONS_SUCCESS:
      state = {
        ...state,
        coupons: action.payload.coupons.reverse(),
        loading: false,
      };
      break;
    case couponConstants.GET_ALL_COUPONS_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case couponConstants.ADD_NEW_COUPON_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case couponConstants.ADD_NEW_COUPON_SUCCESS: {
      const coupon = action.payload.coupon;
      state.coupons.unshift(coupon);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case couponConstants.ADD_NEW_COUPON_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case couponConstants.UPDATE_COUPON_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case couponConstants.UPDATE_COUPON_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case couponConstants.UPDATE_COUPON_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }
  return state;
};

export default couponReducers;
