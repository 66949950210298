import { journalConstants } from '../actions/constants';

const initialState = {
  voucherNum: '',
  journals: [],
  allJournals: [],
  loading: false,
  error: null,
};

const journalReducers = (state = initialState, action) => {
  switch (action.type) {
    case journalConstants.GET_NEW_VOUCHER_NUM_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.GET_NEW_VOUCHER_NUM_SUCCESS:
      state = {
        ...state,
        voucherNum: action.payload.voucherNum,
        loading: false,
      };
      break;

    case journalConstants.GET_ALL_JOURNALS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.GET_ALL_JOURNALS_SUCCESS:
      state = {
        ...state,
        allJournals: action.payload.journals.reverse(),
        loading: false,
      };
      break;

    case journalConstants.ADD_NEW_JOURNAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.ADD_NEW_JOURNAL_SUCCESS: {
      const journal = action.payload.journal;
      const date = action.payload.date;
      state.journals.push(journal);
      state = {
        ...state,
        date: date,
        loading: false,
      };
      break;
    }
    case journalConstants.ADD_NEW_JOURNAL_FAILURE:
      state = {
        ...initialState,
        error: action.payload.error,
      };
      break;
    ////////////////////////////////////////////////////////////////////////////////////
    case journalConstants.ADD_NEW_GROUP_JOURNAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.ADD_NEW_GROUP_JOURNAL_SUCCESS:
      state = {
        ...state,
        journals: [],
        loading: false,
      };
      break;
    case journalConstants.ADD_NEW_GROUP_JOURNAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    ///////////////////////////////////////////////////////////////////////////////////////
    case journalConstants.EDIT_ENTRY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.EDIT_ENTRY_SUCCESS:
      state.journals[action.payload.id] = action.payload;
      delete state.journals[action.payload.id]['id'];
      state = {
        ...state,
        loading: false,
      };
      break;
    case journalConstants.EDIT_ENTRY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case journalConstants.UPDATE_JOURNAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.UPDATE_JOURNAL_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case journalConstants.UPDATE_JOURNAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case journalConstants.DELETE_JOURNAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case journalConstants.DELETE_JOURNAL_SUCCESS: {
      const id = action.payload.index;
      state.journals.splice(id, 1); //argument 1 means remove only one element starting from index
      //splice returns the removed element
      state = {
        ...state,
        // journals:journals,
        loading: false,
      };
      break;
    }
    case journalConstants.DELETE_JOURNAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default journalReducers;
