const replaceImageUrl = (url) => {
  if (url.includes('sambalpuri-fab-image.s3.amazonaws.com')) {
    return url.replace(
      'sambalpuri-fab-image.s3.amazonaws.com',
      process.env.REACT_APP_CLOUDFRONT_URL,
    );
  } else if (url.includes('sambalpuri-fab-image.s3.ap-south-1.amazonaws.com')) {
    return url.replace(
      'sambalpuri-fab-image.s3.ap-south-1.amazonaws.com',
      process.env.REACT_APP_CLOUDFRONT_URL,
    );
  }
  return url;
};

export const getSmallImageUrl = (alreadyReplacedUrl) => {
  return `https://wsrv.nl/?url=${replaceImageUrl(alreadyReplacedUrl)}&h=150`;
};

export default replaceImageUrl;
