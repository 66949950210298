import { Typography } from '@material-ui/core';
import React from 'react';

const RequiredText = ({ text }) => {
  return (
    <Typography
      variant='body2'
      style={{ color: 'red', fontWeight: 600, fontSize: '12px', marginTop: '-5px' }}
    >
      * {text} is required
    </Typography>
  );
};

export default RequiredText;
