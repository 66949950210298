import { accountNameConstants } from '../actions/constants';

const initialState = {
  accountNames: [],
  loading: false,
  error: null,
};

const accountNameReducers = (state = initialState, action) => {
  switch (action.type) {
    case accountNameConstants.GET_ALL_ACCOUNT_NAMES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountNameConstants.GET_ALL_ACCOUNT_NAMES_SUCCESS:
      state = {
        ...state,
        accountNames: action.payload.accountNames,
        loading: false,
      };
      break;

    case accountNameConstants.ADD_NEW_ACCOUNT_NAME_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountNameConstants.ADD_NEW_ACCOUNT_NAME_SUCCESS: {
      const accountName = action.payload.accountName;
      state.accountNames.push(accountName);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case accountNameConstants.ADD_NEW_ACCOUNT_NAME_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case accountNameConstants.UPDATE_ACCOUNT_NAME_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountNameConstants.UPDATE_ACCOUNT_NAME_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case accountNameConstants.UPDATE_ACCOUNT_NAME_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case accountNameConstants.DELETE_ACCOUNT_NAME_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case accountNameConstants.DELETE_ACCOUNT_NAME_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case accountNameConstants.DELETE_ACCOUNT_NAME_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default accountNameReducers;
