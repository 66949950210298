import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { buyerSellerConstants } from './constants';

const getAllBuyerSellers = () => {
  return async (dispatch) => {
    //dispatch a get product request
    dispatch({ type: buyerSellerConstants.GET_ALL_BUYER_SELLERS_REQUEST });

    axios
      .get('/buyersellers')
      .then((response) => {
        const { buyerSellers } = response.data;
        dispatch({
          type: buyerSellerConstants.GET_ALL_BUYER_SELLERS_SUCCESS,
          payload: { buyerSellers: buyerSellers },
        });
      })
      .catch((error) => {
        dispatch({
          type: buyerSellerConstants.GET_ALL_BUYER_SELLERS_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addBuyerSeller = (payload) => {
  return async (dispatch) => {
    dispatch({ type: buyerSellerConstants.ADD_NEW_BUYER_SELLER_REQUEST });

    axios
      .post('/buyersellers', payload)
      .then((response) => {
        dispatch({
          type: buyerSellerConstants.ADD_NEW_BUYER_SELLER_SUCCESS,
          payload: { buyerSeller: response.data.buyerSeller },
        });
        dispatch(getAllBuyerSellers());
        toast.success('New party created!');
      })
      .catch((error) => {
        dispatch({
          type: buyerSellerConstants.ADD_NEW_BUYER_SELLER_FAILURE,
          payload: { error: error },
        });
        toast.error('Party creation failed! Please try later');
      });
  };
};

export const updateBuyerSeller = (payload) => {
  return async (dispatch) => {
    dispatch({ type: buyerSellerConstants.UPDATE_BUYER_SELLER_REQUEST });
    axios
      .put(`/buyersellers/${payload._id}`, payload)
      .then(() => {
        dispatch({ type: buyerSellerConstants.UPDATE_BUYER_SELLER_SUCCESS });
        dispatch(getAllBuyerSellers());
        toast.success('Party updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: buyerSellerConstants.UPDATE_BUYER_SELLER_FAILURE,
          payload: { error },
        });
        toast.error('Party update failed! Please try later');
      });
  };
};

export { getAllBuyerSellers };
