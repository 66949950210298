import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrintPaperHeading from '../../../../../components/Accounts/PrintPaperHeading';
import TableCell from '../../../../../components/Accounts/TableCell';
import TableRow from '../../../../../components/Accounts/TableRow';
import { ACCOUNT_GROUP_HEAD, EXPENSES_ID, REVENUE_ID } from '../../../../../helpers/constants';
import formatDate2 from '../../../../../helpers/formatDate2';
import getAccountBalance from '../../../../../helpers/getAccountBal';
import { toIndianNumber } from '../../../../../helpers/toIndianNumber';
import { saveNetProfitCD } from '../../../../../redux/actions';

const PnlAccountLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const trialBalance = useSelector((state) => state.trialBalance);
  const trialBalanceDetails = useSelector((state) =>
    state.trialBalance.trialBalanceDetails.slice(0, -1),
  );

  //Profit & Loss
  const officeAndManagement = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === EXPENSES_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.officeManagementExp.id,
  );
  const sellingAndDist = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === EXPENSES_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.sellingAndDistExp.id,
  );
  const financialExp = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === EXPENSES_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.financialExp.id,
  );
  const repairsAndDepre = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === EXPENSES_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.repairsAndDepre.id,
  );
  const lossesAndMisc = trialBalanceDetails.filter(
    (item) =>
      item.Pnl_Head._id === EXPENSES_ID &&
      item.Account_Group_Head._id === ACCOUNT_GROUP_HEAD.lossAndMiscExp.id,
  );

  const revenueAccounts = trialBalanceDetails.filter(
    (item) => item.Account_Head._id === REVENUE_ID && item.Pnl_Head._id === EXPENSES_ID,
  );
  const officeAndManagementName = ACCOUNT_GROUP_HEAD.officeManagementExp.name;
  const sellingAndDistName = ACCOUNT_GROUP_HEAD.sellingAndDistExp.name;
  const financialExpName = ACCOUNT_GROUP_HEAD.financialExp.name;
  const repairsAndDepreName = ACCOUNT_GROUP_HEAD.repairsAndDepre.name;
  const lossesAndMiscName = ACCOUNT_GROUP_HEAD.lossAndMiscExp.name;

  let revenueSum = 0;
  revenueAccounts?.forEach((item) => (revenueSum += Number(getAccountBalance(item))));

  let officeAndManagementSum = 0;
  officeAndManagement?.forEach(
    (item) => (officeAndManagementSum += Number(getAccountBalance(item))),
  );
  let sellingAndDistSum = 0;
  sellingAndDist?.forEach((item) => (sellingAndDistSum += Number(getAccountBalance(item))));
  let financialExpSum = 0;
  financialExp?.forEach((item) => (financialExpSum += Number(getAccountBalance(item))));
  let repairsAndDepreSum = 0;
  repairsAndDepre?.forEach((item) => (repairsAndDepreSum += Number(getAccountBalance(item))));
  let lossesAndMiscSum = 0;
  lossesAndMisc?.forEach((item) => (lossesAndMiscSum += Number(getAccountBalance(item))));

  const expensesSum =
    officeAndManagementSum +
    sellingAndDistSum +
    financialExpSum +
    repairsAndDepreSum +
    lossesAndMiscSum;

  // Balance rows at left and right side------------------------------------------------------------
  const leftRows =
    7 +
    officeAndManagement.length +
    sellingAndDist.length +
    financialExp.length +
    repairsAndDepre.length +
    lossesAndMisc.length;
  const rightRows = 2 + revenueAccounts.length;
  const extraRows = leftRows - rightRows;
  const dummyArray = new Array(Math.abs(extraRows)).fill(0);
  //--------------------------------------------------------------------------------------------------

  const rightSideTotal = Number(revenueSum + trialBalance.grossProfitCD).toFixed(2);

  const netProfitCD = Number(
    Number(rightSideTotal).toFixed(2) - Number(expensesSum).toFixed(2),
  ).toFixed(2);
  if (trialBalance.netProfitCD !== netProfitCD) {
    dispatch(saveNetProfitCD(netProfitCD));
  }

  return (
    <Paper ref={ref} style={{ margin: 50, border: '2px solid gray' }}>
      <PrintPaperHeading
        heading={`Profit & Loss Account for the year ended ${formatDate2(trialBalance.toDate)}`}
      />
      <TableContainer style={{ display: 'flex', borderRadius: '0' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid gray' }}>
              <TableCell align='left'>Particulars</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='left' bold={true}>
                {officeAndManagementName}
              </TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {officeAndManagement?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell align='left'>To {item.Account_Name}</TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>₹ {toIndianNumber(getAccountBalance(item))}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align='left' bold={true}>
                {sellingAndDistName}
              </TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {sellingAndDist?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell align='left'>To {item.Account_Name}</TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>₹ {toIndianNumber(getAccountBalance(item))}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align='left' bold={true}>
                {financialExpName}
              </TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {financialExp?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell align='left'>To {item.Account_Name}</TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>₹ {toIndianNumber(getAccountBalance(item))}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align='left' bold={true}>
                {repairsAndDepreName}
              </TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {repairsAndDepre?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell align='left'>To {item.Account_Name}</TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>₹ {toIndianNumber(getAccountBalance(item))}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align='left' bold={true}>
                {lossesAndMiscName}
              </TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            {lossesAndMisc?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell align='left'>To {item.Account_Name}</TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>₹ {toIndianNumber(getAccountBalance(item))}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell align='left'>Net Profit C/D</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>
                ₹{' '}
                {toIndianNumber(
                  Number(
                    Number(rightSideTotal).toFixed(2) - Number(expensesSum).toFixed(2),
                  ).toFixed(2),
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right' bold={true}>
                Total
              </TableCell>
              <TableCell align='right' bold={true}>
                ₹{' '}
                {toIndianNumber(
                  Number(
                    Number(expensesSum) + Number(Number(rightSideTotal).toFixed(2)) - expensesSum,
                  ).toFixed(2),
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow style={{ borderBottom: '2px solid gray' }}>
              <TableCell align='left'>Particulars</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='left'>By Gross Profit B/D</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>₹ {toIndianNumber(trialBalance.grossProfitCD)}</TableCell>
            </TableRow>
            {revenueAccounts?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell align='left'>By {item.Account_Name}</TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>₹ {toIndianNumber(getAccountBalance(item))}</TableCell>
              </TableRow>
            ))}
            {dummyArray?.map((item, idx) => (
              <TableRow key={item + idx}>
                <TableCell align='left'></TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'></TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right' bold={true}>
                ₹ {toIndianNumber(rightSideTotal)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

PnlAccountLayout.displayName = 'PnlAccountLayout';
export default PnlAccountLayout;
