import checkState from '../../helpers/checkState';
import formatDate from '../../helpers/formatDate';
import { orderConstants } from '../actions/constants';

const initState = {
  orders: [],
  report: [],
  loading: false,
  tracking_data: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderConstants.GET_CUSTOMER_ORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.GET_CUSTOMER_ORDER_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders?.reverse(),
        loading: false,
      };
      break;

    case orderConstants.UPDATE_TRACKING_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_TRACKING_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case orderConstants.UPDATE_TRACKING_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    case orderConstants.UPDATE_CUSTOMER_ORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_CUSTOMER_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case orderConstants.UPDATE_CUSTOMER_ORDER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    case orderConstants.GET_TRACKING_DETAILS_REQUEST:
      state = {
        ...state,
        tracking_data: null,
        loading: true,
      };
      break;
    case orderConstants.GET_TRACKING_DETAILS_SUCCESS:
      state = {
        ...state,
        tracking_data: action.payload,
        loading: false,
      };
      break;
    case orderConstants.GET_TRACKING_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    case orderConstants.GET_REPORT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.GET_REPORT_SUCCESS: {
      let yoyo = 0;
      let rowss = [];
      state.orders.map((order) => {
        const items = order.items;
        const orderDate = new Date(order.createdAt);
        if (orderDate >= action.payload.fromDate && orderDate <= action.payload.toDate) {
          let totalQty = 0;
          let totalGP = 0;
          let totalDisc = 0;
          let totalNP = 0;
          let totalIGST_Amt = 0;
          let totalCGST_Amt = 0;
          let totalSGST_Amt = 0;
          let totalGST = 0;
          let totalNP_GST = 0;
          items.map((item, idx_) => {
            totalQty += item.purchasedQty;
            totalGP += item.grossPrice;
            totalDisc += item.discAmount;
            totalNP += item.netPriceAftDisc;
            totalIGST_Amt += !checkState(order.address.state) ? item.gstAmt : 0;
            totalCGST_Amt += checkState(order.address.state) ? item.gstAmt / 2 : 0;
            totalSGST_Amt += checkState(order.address.state) ? item.gstAmt / 2 : 0;
            totalGST += item.gstAmt;
            totalNP_GST += item.payablePrice;
            rowss.push({
              id: yoyo++,
              item,
              Date: idx_ === 0 ? formatDate(order.createdAt) : null,
              'Order ID': idx_ === 0 ? order.invoiceId : null,
              ProdCode: item.productId.prodCode,
              State: order.address.state,
              HSN_Code: item.hsnCode,
              IGST: !checkState(order.address.state) ? `${item.gst}%` : null,
              CGST: checkState(order.address.state) ? `${item.gst / 2}%` : null,
              SGST: checkState(order.address.state) ? `${item.gst / 2}%` : null,
              Qty: item.purchasedQty,
              GP: item.grossPrice,
              Offer: item.offer,
              DiscAmt: item.discAmount,
              NP: item.netPriceAftDisc,
              IGST_Amt: !checkState(order.address.state) ? `${item.gstAmt}` : null,
              CGST_Amt: checkState(order.address.state) ? `${item.gstAmt / 2}` : null,
              SGST_Amt: checkState(order.address.state) ? `${item.gstAmt / 2}` : null,
              Total_GST: item.gstAmt,
              NP_GST: item.payablePrice,
              Category: item.category,
            });
          });
          rowss.push({
            id: yoyo++,
            Qty: totalQty,
            GP: totalGP,
            DiscAmt: totalDisc,
            NP: totalNP,
            IGST_Amt: totalIGST_Amt,
            CGST_Amt: totalCGST_Amt,
            SGST_Amt: totalSGST_Amt,
            Total_GST: totalGST,
            NP_GST: totalNP_GST,
          });
        }
      });
      state = {
        ...state,
        report: rowss,
        loading: false,
      };
      break;
    }
    case orderConstants.GET_REPORT_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
  }

  return state;
};
