import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { secColour } from '../../utils';

const BackdropLoading = (props) => {
  const { loading } = props;

  return (
    <Backdrop open={loading} style={{ zIndex: 1000 }}>
      <CircularProgress style={{ color: secColour }} />
    </Backdrop>
  );
};

export default BackdropLoading;
