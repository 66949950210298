import { buyerSellerConstants } from '../actions/constants';

const initialState = {
  buyerSellers: [],
  loading: false,
  error: null,
};

const buyerSellerReducers = (state = initialState, action) => {
  switch (action.type) {
    case buyerSellerConstants.GET_ALL_BUYER_SELLERS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case buyerSellerConstants.GET_ALL_BUYER_SELLERS_SUCCESS:
      state = {
        ...state,
        buyerSellers: action.payload.buyerSellers,
        loading: false,
      };
      break;

    case buyerSellerConstants.ADD_NEW_BUYER_SELLER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case buyerSellerConstants.ADD_NEW_BUYER_SELLER_SUCCESS: {
      const buyerSeller = action.payload.buyerSeller;
      state.buyerSellers.push(buyerSeller);
      state = {
        ...state,
        loading: false,
      };
      break;
    }
    case buyerSellerConstants.ADD_NEW_BUYER_SELLER_FAILURE:
      state = {
        ...initialState,
      };
      break;
    case buyerSellerConstants.UPDATE_BUYER_SELLER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case buyerSellerConstants.UPDATE_BUYER_SELLER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case buyerSellerConstants.UPDATE_BUYER_SELLER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case buyerSellerConstants.DELETE_BUYER_SELLER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case buyerSellerConstants.DELETE_BUYER_SELLER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case buyerSellerConstants.DELETE_BUYER_SELLER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default buyerSellerReducers;
