import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from '../../../assets/css/useStyles';
import Layout from '../../../layouts/Layout';
const Transaction = () => {
  const classes = useStyles();
  return (
    <Layout sidebar>
      <Grid
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} sm={2}>
          <Link to='-transactions-cash' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              CASH
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link to='-transactions-journal' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              JOURNAL
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link to='-transactions-ledger' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              LEDGER
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link to='/accounts-transactions-trialbalance' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              TRIAL BALANCE
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={5} sm={2}>
          <Link to='/accounts-transactions-tradingaccount' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              TRADING ACCOUNT
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link to='/accounts-transactions-pnlaccount' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              PNL ACCOUNT
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link to='/accounts-transactions-balancesheet' style={{ textDecoration: 'none' }}>
            <Button fullWidth className={classes.externalLinks} variant='contained'>
              BALANCE SHEET
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Transaction;
