import { globalConstants } from '../actions/constants';

const initialState = {
  year: Number(process.env.REACT_APP_DEFAULT_YEAR),
  app: null,
  loading: false,
  error: null,
};

const globalReducers = (state = initialState, action) => {
  switch (action.type) {
    case globalConstants.SAVE_CURRENT_YEAR_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case globalConstants.SAVE_CURRENT_YEAR_SUCCESS:
      state = {
        ...state,
        year: action.payload.year,
        loading: false,
      };
      break;
    case globalConstants.SAVE_CURRENT_YEAR_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case globalConstants.SAVE_APP_NAME_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case globalConstants.SAVE_APP_NAME_SUCCESS:
      state = {
        ...state,
        app: action.payload.appName,
        loading: false,
      };
      break;
    case globalConstants.SAVE_APP_NAME_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
      break;
  }

  return state;
};

export default globalReducers;
