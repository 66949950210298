import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from '../../assets/css/useStyles';

const ButtonsGroupAccounts = (props) => {
  const classes = useStyles();
  const { btn1, btn2, btn3, link1, link2, link3 } = props;
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      <Grid item sm={3} xs={12}>
        <Link to={link1} style={{ textDecoration: 'none' }}>
          <Button fullWidth className={classes.externalLinks} variant='contained'>
            {btn1}
          </Button>
        </Link>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Link to={link2} style={{ textDecoration: 'none' }}>
          <Button fullWidth className={classes.externalLinks} variant='contained'>
            {btn2}
          </Button>
        </Link>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Link to={link3} style={{ textDecoration: 'none' }}>
          <Button fullWidth className={classes.externalLinks} variant='contained'>
            {btn3}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ButtonsGroupAccounts;
