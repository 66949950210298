import { toast } from 'react-hot-toast';
import { globalConstants } from './constants';

export const changeYear = (year) => {
  return async (dispatch) => {
    dispatch({ type: globalConstants.SAVE_CURRENT_YEAR_REQUEST });

    try {
      setTimeout(() => {
        dispatch({
          type: globalConstants.SAVE_CURRENT_YEAR_SUCCESS,
          payload: { year: year },
        });
        toast.success(`Year ${year} is selected.`);
      }, 500);
    } catch (error) {
      toast.error('Year selection failed!');

      dispatch({
        type: globalConstants.SAVE_CURRENT_YEAR_FAILURE,
        payload: { error: error },
      });
    }
  };
};

export const selectApp = (appName) => {
  return async (dispatch) => {
    dispatch({ type: globalConstants.SAVE_APP_NAME_REQUEST });

    try {
      setTimeout(() => {
        dispatch({
          type: globalConstants.SAVE_APP_NAME_SUCCESS,
          payload: { appName: appName },
        });
        toast.success(`${appName} is selected.`);
      }, 500);
    } catch (error) {
      toast.error('Something went wrong!');

      dispatch({
        type: globalConstants.SAVE_APP_NAME_FAILURE,
        payload: { error: error },
      });
    }
  };
};
