import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import replaceImageUrl, { getSmallImageUrl } from '../../../../helpers/replaceImageUrl';
import { mainColour2, redColour } from '../../../../utils';
import useStyles from './useStyles';
const OrderCard = (props) => {
  const classes = useStyles();
  const { items, paymentStatus, paymentType, createdAt } = props.orderItem;
  const { formatDate } = props;

  return (
    <div>
      {items.map((item, idx) => {
        const photo = replaceImageUrl(item.productId.productPictures[0].img);
        return (
          <Card elevation={2} className={classes.root} key={idx}>
            <a href={photo} target='_blank' rel='noreferrer noopener'>
              <CardMedia
                component='img'
                className={classes.media}
                image={getSmallImageUrl(photo)}
                title={`${item.productId.name} ${item.productId.prodCode}`}
                alt='Image unavailable'
              />
            </a>
            <CardContent>
              <Grid container className={classes.cardContent}>
                <Grid item md={12}>
                  <Typography variant='h5' className={classes.letterSize}>
                    {item.productId.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                  >
                    <Chip
                      label={`Payment Status : ${paymentStatus.toUpperCase()}`}
                      color='primary'
                      style={{ backgroundColor: mainColour2, marginBottom: '5px' }}
                      className={classes.chip}
                      size='small'
                    />

                    <Chip
                      label={`Payment Type : ${paymentType.toUpperCase()}`}
                      style={{ backgroundColor: redColour, color: 'white' }}
                      className={classes.chip}
                      size='small'
                      color='secondary'
                    />
                  </div>
                </Grid>
                <Grid item>
                  <Typography variant='body2' className={classes.letterSize}>
                    {formatDate(createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.button1s}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body1' className={classes.letterSize}>
                  Price{' '}
                </Typography>
                <Typography variant='h6' className={classes.letterSize}>
                  ₹{(item.payablePrice / item.purchasedQty).toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body1' className={classes.letterSize}>
                  Buy Qty
                </Typography>
                <Typography variant='h6' className={classes.letterSize}>
                  {item.purchasedQty}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body1' className={classes.letterSize}>
                  Total
                </Typography>
                <Typography variant='h6' className={classes.letterSize}>
                  ₹{item.payablePrice.toFixed(2)}
                </Typography>
              </div>
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
};

export default OrderCard;
