import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { mainColour1 } from '../../utils';

const useStyles = makeStyles((theme) => ({
  media: {
    border: '3px solid white',
    height: '300px',
    width: '250px',
    margin: '20px 20px',
    transition: '0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: '300px',
      width: '250px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px',
      width: '250px',
      margin: '5px auto',
    },
  },
  nameBackground: {
    width: '100%',
    height: '45px',
    position: 'relative',
    top: '-80px',
    opacity: '0.7',
    backgroundImage: `linear-gradient(to bottom, white , white )`,
    [theme.breakpoints.down('xs')]: {
      top: '-85px',
    },
  },
  cardName: {
    position: 'relative',
    top: '-115px',
    color: mainColour1,
    zIndex: '100',
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      top: '-120px',
    },
  },
}));
const CatCard = (props) => {
  const classes = useStyles();
  const { name, image } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <img src={image} alt={`${name}`} className={classes.media} />
      <div className={classes.nameBackground}></div>
      <Typography variant='body1' color='primary' component='p' className={classes.cardName}>
        {name}
      </Typography>
    </div>
  );
};

export default CatCard;
